import React, { useMemo } from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';
import List from '@mui/material/List';
// import IconButton from '@mui/material/IconButton';
// import ApproveIcon from '@mui/icons-material/CheckCircle';
// import DeclineIcon from '@mui/icons-material/Cancel';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { useFeedback } from '../feedback/Service';
import { LoadingButton } from '@mui/lab';
import { Chip } from '@mui/material';
import { gql, useMutation } from '@apollo/client';
import { Cancel, CheckCircle, Pending } from '@mui/icons-material';
import TalentProfile from '../talents/TalentProfile';
import TalentPositionDetails from '../talents/TalentPositionDetails';
import { parseGraphQLError } from '../../utils';
dayjs.extend(utc);
dayjs.extend(timezone);

const MAKE_DECISION = gql`
  mutation decideClientMatch($matchId: String!, $decision: Boolean!) {
    decideClientMatch(matchId: $matchId, decision: $decision)
  }
`;

const JobItem = ({ job: item, children }) => {
  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography sx={{ flexGrow: "1" }}>{item.position.name}</Typography>
        <Chip label={(item.matches || []).filter(x => x.acceptedByClient === null).length} color="primary" />
      </AccordionSummary>
      <AccordionDetails>
        {children}
      </AccordionDetails>
    </Accordion >
  );
}

const MatchItem = ({ match }) => {
  const [decide, { loading: isPosting }] = useMutation(MAKE_DECISION, {
    refetchQueries: [
      'GetAccount'
    ],
  });
  const { desiredPosition, talent } = match;
  const feedback = useFeedback();

  const makeDecision = async decision => {
    if (!decision && !await feedback.confirm({ title: "Are you sure you want to decline?" }))
      return;
    try {
      await decide(({ variables: { matchId: match._id, decision: decision } }));
      feedback.snackbar({ text: "Match " + (decision ? "accepted" : "declined"), type: "success" });
    } catch (error) {
      console.error("Decide match error", error);
      feedback.snackbar({ text: parseGraphQLError(error), type: "error" });
    }
  }

  const handleApprove = () => makeDecision(true);
  const handleDecline = () => makeDecision(false);

  const handleShowJob = () => {
    console.log({ talent, desiredPosition });
    feedback.alert({
      title: desiredPosition.position.name, component:
        <>
          <TalentProfile talent={talent} />
          {!match.acceptedByClient &&
            <LoadingButton loading={isPosting} edge="end" aria-label="approve" color="success" onClick={handleApprove} variant='contained' sx={{ mt: 1.5 }}>
              Accept
            </LoadingButton>
          }
          {(match.acceptedByClient || match.acceptedByClient === null) &&
            <LoadingButton loading={isPosting} edge="end" aria-label="decline" color="error" onClick={handleDecline} variant='outlined' sx={{ mt: 1.5, ml: 1 }}>
              Decline
            </LoadingButton>
          }
          <TalentPositionDetails position={desiredPosition} showEdit={false} showRerecord={false} introAnswers={talent.interview.answers} />
        </>,
      scroll: "body", maxWidth: "lg", fullWidth: true, clickAwayClose: true, confirmButtonText: "Close"
    });
  }

  const label = match.acceptedByClient === null ? "Pending" : match.acceptedByClient ? "Accepted" : "Declined";
  const labelColor = match.acceptedByClient === null ? "info" : match.acceptedByClient ? "success" : "error";
  const icon = match.acceptedByClient === null ? <Pending /> : match.acceptedByClient ? <CheckCircle /> : <Cancel />;

  return (
    <ListItem
      key={match._id}
      disablePadding
      secondaryAction={<Chip label={label} color={labelColor} icon={icon} />}
    >
      <ListItemButton onClick={handleShowJob}>
        <ListItemText
          primary={`${talent.user.firstName}`}
          secondary={`Created: ${dayjs(match.createdAt * 1).tz(dayjs.tz.guess()).format("LLL")}`}
        />
      </ListItemButton>
    </ListItem>
  );
}

const ClientMatches = ({ client }) => {
  const jobs = useMemo(() => client.jobs.filter(x => x.matches && x.matches.length > 0), [client]);

  console.log({ jobs });

  return (
    <>
      {jobs.length === 0 && <Typography variant="body1">{"We've"} received your requests and are working on finding the best talent matches for you. You will be notified when we have new matches.</Typography>}
      {jobs.map((item, index) => (
        <JobItem key={item._id} job={item}>
          <List sx={{ width: "100%" }}>
            {item.matches.toReversed().map(match => <MatchItem key={match._id} match={match} />)}
          </List>
        </JobItem >
      ))}
    </>
  );
}

export default ClientMatches;