import React, { useState, useEffect, useMemo } from 'react';
import InterviewRecorder from './InterviewRecorder';
import { useQuery, gql } from '@apollo/client';
// import ME from '../../gql/common/me.query';
import Stepper from '../Stepper';
import Container from '@mui/material/Container';
import { CORE_USER_FIELDS } from '../../gql/fragments/user.fragment';
import { useNavigate } from 'react-router-dom';

const ME_WITH_TALENT = gql`
  ${CORE_USER_FIELDS}
  query getMeWithTalent {
    me {
      ...CoreUserFields
      talent {
        interview {
          _id
          answers {
            question {
              _id
            }
          }
        }
        desiredPositions{ 
          _id
          position {
            _id
          }
          interview {
            _id
            answers {
              question {
                _id
              }
            }
          }
        }
      }
    }
  }
`;

const QUESTIONS = gql`
  query GetQuestions {
    questions {
      _id
      title
      # text
      preparationTime
      recordingTime
      canRerecord
    }
  }
`;

const POSITIONS = gql`
  query GetPositions {
    positions {
      _id
      name
      questions {
        _id
        title
        preparationTime
        recordingTime
        canRerecord
      }
    }
  }
`;

const VideoInterview = ({ desiredPositionId, maxWidth = "md", onComplete }) => {
  const [questionIndex, setQuestionIndex] = useState(null);
  const { data: meData } = useQuery(ME_WITH_TALENT);
  const { loading: questionsLoading, data } = useQuery(desiredPositionId ? POSITIONS : QUESTIONS);
  const navigate = useNavigate();

  const questionsData = useMemo(() => {
    if (!data || !meData)
      return;
    if (desiredPositionId) {
      const { me: { talent: { desiredPositions } } } = meData;
      const desiredPosition = desiredPositions.find(x => x._id === desiredPositionId);
      return data.positions.find(x => x._id === desiredPosition.position._id);
    }
    return data;
  }, [data, desiredPositionId, meData]);

  useEffect(() => {
    if (!meData || !questionsData)
      return;
    const { questions } = questionsData;
    console.log(meData.me);
    const { me: { talent: { interview: talentInterview, desiredPositions } } } = meData;
    const desiredPosition = desiredPositions.find(x => x._id === desiredPositionId);
    const interview = desiredPosition ? desiredPosition.interview : talentInterview;
    console.log("questions", questions, interview);
    if (!interview) {
      setQuestionIndex(0);
      return;
    }
    const { answers } = interview;
    const passedQuestions = answers.map(x => x.question._id);
    const index = questions.findIndex(x => !passedQuestions.includes(x._id));
    console.log("passedQuestions", passedQuestions, index);
    setQuestionIndex(index);
    if (index === -1) {
      window.gtag('event', 'talent_interview', {});
      if (onComplete)
        onComplete();
      else
        navigate("/account#interview-completed");
    }
  }, [questionsData, meData, desiredPositionId]);

  useEffect(() => {
    window.scroll(0, 0);
  }, [questionIndex]);

  if (questionsLoading) return null;
  if (questionIndex === null || questionIndex === -1) return null;
  return (
    <>
      {questionsData?.questions?.length > 1 &&
        <Container maxWidth={maxWidth} sx={{ mb: 4 }}>
          <Stepper steps={questionsData.questions.map((x, i) => ({ id: x._id, label: x.title, completed: questionIndex > i }))} activeStep={questionIndex} />
        </Container>
      }
      <Container maxWidth="md" sx={{ mb: 4 }}>
        <InterviewRecorder question={questionsData.questions[questionIndex]} desiredPositionId={desiredPositionId} />
      </Container>
    </>
  );
}

export default VideoInterview;