import React from 'react';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';

const UrlInput = ({ onChange, baseUrl, ...rest }) => {

  const handleChange = ({ target }) => {
    const result = target;
    result.value = result.value.replace(baseUrl, "");
    onChange({ target: result });
  }

  return (
    <TextField
      InputProps={{ startAdornment: <InputAdornment sx={{ margin: 0, marginTop: "2px" }} position="start">{baseUrl}</InputAdornment> }}
      onChange={handleChange}
      {...rest}
    />
  );
}

export default UrlInput;