import React from 'react';
import CustomSelect from './CustomSelect';

export const commissionUnits = [
  { id: "PERCENT", name: "%" },
  { id: "DOLLAR", name: "$" },
];

const CommissionUnitSelect = ({ ...props }) => {
  return (
    <CustomSelect items={commissionUnits} {...props} valueField="id" />
  )
}

export default CommissionUnitSelect;