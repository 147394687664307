import { gql } from '@apollo/client';

export const CORE_USER_FIELDS = gql`
  fragment CoreUserFields on User {
    _id
    email
    firstName
    lastName
    role
    emailConfirmed
    talent {
      _id
    }
    client {
      _id
    }
    createdAt
  }
`;