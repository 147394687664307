import React from 'react';

import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { useQuery, gql } from '@apollo/client';
import { CORE_CLIENT_FIELDS } from '../../gql/fragments/client.fragment';
import ClientForm from './ClientForm';

const ME = gql`
  ${CORE_CLIENT_FIELDS}
  query GetMe {
    me {
      _id
      client {
        ...CoreClientFields
      }
    }
  }
`;

const EditClientProfile = () => {
  const { error, loading, data } = useQuery(ME);
  if (loading)
    return 'Loading...';
  if (error)
    return 'Error ' + error;
  return (
    <Container maxWidth="md">
      <Box
        sx={{
          marginTop: 4,
          marginBottom: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <ClientForm userId={data?.me?._id} data={data?.me?.client} redirect="/account" />
      </Box>
    </Container>
  );
}

export default EditClientProfile;