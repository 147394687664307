import { gql } from '@apollo/client';

export const CORE_CLIENT_FIELDS = gql`
  fragment CoreClientFields on Client {
    _id
    company
    comment
    website
    funding
    revenue
    industry
    culture
    linkedin
    facebook
    twitter
    instagram
    customLink
    employeesNumber
    salesNumber
    createdAt
    updatedAt
  }
`;