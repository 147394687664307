import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

const Password = ({ ...rest }) => {
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword(x => !x);

  const handleMouseDownPassword = event => event.preventDefault();

  return (
    <TextField
      {...rest}
      type={showPassword ? "text" : "password"}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
              edge="end"
            >
              {showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment >
        ),
      }}
    />
    // <FormControl sx={{ m: 1, width: '25ch' }} variant="outlined">
    //   <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
    //   <OutlinedInput
    //     id="outlined-adornment-password"
    //     type={values.showPassword ? 'text' : 'password'}
    //     value={values.password}
    //     onChange={handleChange('password')}
    //     endAdornment={
    //       <InputAdornment position="end">
    //         <IconButton
    //           aria-label="toggle password visibility"
    //           onClick={handleClickShowPassword}
    //           onMouseDown={handleMouseDownPassword}
    //           edge="end"
    //         >
    //           {values.showPassword ? <Visibility /> : <VisibilityOff />}
    //         </IconButton>
    //       </InputAdornment>
    //     }
    //     label="Password"
    //   />
    // </FormControl>
  );
}

export default Password;