import React, { useState, useEffect } from "react";
import LoadingButton from '@mui/lab/LoadingButton';

function msToTime(s) {
  // Pad to 2 or 3 digits, default is 2
  const pad = (n, z = 2) => ('00' + n).slice(-z);
  // return pad(s / 3.6e6 | 0) + ':' + pad((s % 3.6e6) / 6e4 | 0) + ':' + pad((s % 6e4) / 1000 | 0); // + '.' + pad(s%1000, 3);
  return pad((s % 3.6e6) / 6e4 | 0) + ':' + pad((s % 6e4) / 1000 | 0); // + '.' + pad(s%1000, 3);
}

const StartButton = ({ targetTime, children, ...rest }) => {
  const [time, setTime] = useState("");

  useEffect(() => {
    if (!targetTime) {
      setTime("");
      return;
    }
    const update = () => setTime(msToTime(Math.abs(Date.now() - targetTime)))
    const interval = setInterval(update, 200);
    return () => {
      clearInterval(interval);
    }
  }, [targetTime]);
  return <LoadingButton {...rest}>{children} {time}</LoadingButton>
}

export default StartButton;