import React, { useMemo } from 'react';
import { gql, useMutation, useQuery } from '@apollo/client';
import { useFeedback } from '../feedback/Service';
import LoadingButton from '@mui/lab/LoadingButton';
import { CircularProgress, IconButton, List, ListItem, ListItemText, ListSubheader, Typography } from '@mui/material';
import { formatTimestamp, parseGraphQLError } from '../../utils';
import OpenIcon from '@mui/icons-material/OpenInNew';
import DownloadIcon from '@mui/icons-material/Download';

const GET_ACCOUNT = gql`
  query getTalentStripeAccount{
    getTalentStripeAccount
  }
`;

const CONNECT = gql`
  mutation connectStripe {
    connectStripe
  }
`;

const LOGIN = gql`
  mutation loginStripe {
    loginStripe
  }
`;

const CREATE_INVOICE = gql`
  mutation createInvoice($invoiceInput: InvoiceInput!) {
    createInvoice(invoiceInput: $invoiceInput) {
      id
      date
      due
      state
      total
      pdfUrl
      url
    }
  }
`;

const GET_INVOICES = gql`
  query getInvoices {
    getInvoices {
      id
      date
      due
      state
      total
      pdfUrl
      url
    }
  }
`;

const TalentPayments = ({ talent }) => {
  const { loading, error, data } = useQuery(GET_ACCOUNT);

  const account = useMemo(() => {
    if (!data?.getTalentStripeAccount)
      return null;
    return data.getTalentStripeAccount;
  }, [data]);

  const { loading: loadingInvoices, error: errorInvoices, data: invoices } = useQuery(GET_INVOICES);

  // console.log({ loadingInvoices, errorInvoices, invoices });

  const [connectStripe, { loading: connectingStripe }] = useMutation(CONNECT, {
    update(cache, { data: { connectStripe } }) {
      console.log("connectStripe", connectStripe, cache);
    }
  });

  const [createInvoice, { loading: creatingInvoice }] = useMutation(CREATE_INVOICE, {
    update(cache, { data: { createInvoice } }) {
      console.log("createInvoice", createInvoice, cache);
      cache.modify({
        fields: {
          getInvoices: (x) => [createInvoice, ...x],
        }
      });
    }
  });

  const [loginStripe, { loading: loggingInStripe }] = useMutation(LOGIN, {
    update(cache, { data: { loginStripe } }) {
      console.log("loginStripe", loginStripe, cache);
    }
  });

  const feedback = useFeedback();

  const handleConnectStripe = async () => {
    try {
      const { data: { connectStripe: url } } = await connectStripe();
      console.log(url);
      window.location.href = url;
    } catch (error) {
      console.error("Connect stripe error", error);
      feedback.snackbar({ text: parseGraphQLError(error), type: "error" });
    }
  };

  const handleLoginStripe = async () => {
    try {
      const { data: { loginStripe: url } } = await loginStripe();
      console.log(url);
      window.open(url, "_blank");
    } catch (error) {
      console.error("Login stripe error", error);
      feedback.snackbar({ text: parseGraphQLError(error), type: "error" });
    }
  };

  const handleCreateInvoice = async () => {
    const data = await feedback.form({
      title: "Create invoice",
      inputs: [
        { title: "Salary", type: "number", name: "salary", required: true },
        { title: "Commission", type: "number", name: "commission", required: true },
      ]
    });
    console.log({ data });
    if (!data)
      return;
    const invoiceInput = { salary: Number(data.salary), commission: Number(data.commission) };
    try {
      const { data: { createInvoice: result } } = await createInvoice(({ variables: { invoiceInput } }));
      console.log(result);
    } catch (error) {
      console.error("Create invoice error", error);
      feedback.snackbar({ text: parseGraphQLError(error), type: "error" });
    }
  }

  if (error)
    return <Typography variant="body1" color="error">{error.message}</Typography>;
  if (loading)
    return <CircularProgress />;

  console.log({ account });

  const accountText = account || "Not created";
  const buttonText = account ? "Edit Account Details" : "Create Account";

  return (
    <>
      <Typography variant="body1">Stripe account status: {accountText}</Typography>
      {account === "active" &&
        <>
          <LoadingButton variant="contained" loading={loggingInStripe} onClick={handleLoginStripe} sx={{ mt: 1 }}>Stripe Dashboard</LoadingButton>
          <LoadingButton variant="contained" loading={creatingInvoice} onClick={handleCreateInvoice} sx={{ mt: 1, ml: 1 }}>Create Invoice</LoadingButton>
        </>
      }
      <LoadingButton variant="contained" loading={connectingStripe} onClick={handleConnectStripe} sx={{ mt: 1, ml: 1 }}>{buttonText}</LoadingButton>
      {account === "active" &&
        <List
          component="nav"
          sx={{ p: 0, mt: 2 }}
          subheader={
            <ListSubheader>
              Invoices
            </ListSubheader>
          }
        >
          {loadingInvoices && <CircularProgress sx={{ mt: 1 }} />}
          {errorInvoices && <Typography variant="body1" color="error">{errorInvoices.message}</Typography>}
          {invoices?.getInvoices.map(invoice => (
            <ListItem
              key={invoice.id}
              secondaryAction={
                <>
                  <IconButton sx={{ mr: 1 }} edge="end" aria-label="open" component="a" href={invoice.pdfUrl} target="_blank" rel="noreferrer noopener">
                    <DownloadIcon />
                  </IconButton>
                  <IconButton edge="end" aria-label="open" component="a" href={invoice.url} target="_blank" rel="noreferrer noopener">
                    <OpenIcon />
                  </IconButton>
                </>
              }
            >
              <ListItemText
                primary={`${invoice.state.toUpperCase()} $${(invoice.total / 100).toFixed(2)}`}
                secondary={`Due: ${formatTimestamp(invoice.due * 1000)} | Created: ${formatTimestamp(invoice.date * 1000)}`}
              />
            </ListItem>
          ))}
          {!invoices?.getInvoices.length && !loadingInvoices && <Typography sx={{ ml: 2 }} variant="body2">No invoices yet.</Typography>}
        </List>
      }
    </>
  );
}

export default TalentPayments;