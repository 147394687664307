import EventEmitter from "wolfy87-eventemitter";
import { wait } from "../../utils";
const MAX_RETRIES_TIMEOUT = 10;

const getDataSize = data => (data.audioFile?.size || data.videoFile?.size || 0)

class ChunkUploader {
  constructor(uploadFunc) {
    this.queue = [];
    this.isUploading = false;
    this.uploadFunc = uploadFunc;
    this.eventEmitter = new EventEmitter();
    this.uploaded = 0;
    this.uploadTotal = 0;
    this.recordingId = null;
    this.error = null;
  }

  get queueSize() {
    return this.queue.reduce((current, x) => current + getDataSize(x.data), 0);
  }

  get queueLength() {
    return this.queue.length;
  }

  get isQueueEmpty() {
    return this.queue.length === 0;
  }

  add(data) {
    this.queue.push({ data });
    this.uploadTotal += getDataSize(data);
    this.eventEmitter.emitEvent("update", [this.queue.length]);
    this.uploadNext();
  }

  async uploadNext() {
    if (!this.recordingId) {
      setTimeout(() => this.uploadNext(), 3000);
      // console.warn("[CHUNK UPLOADER]", "recordingId not set");
      return;
    }
    if (this.queue.length === 0 || this.isUploading)
      return;
    this.isUploading = true;
    // const { id, data } = this.queue.shift();
    const { data } = this.queue[0];
    // console.log("[CHUNK UPLOADER]", "starting", this.queue.length, this.recordingId);
    await this.upload(data);
    this.queue.shift();
    // console.log("[CHUNK UPLOADER]", "done", this.queue.length);
    this.isUploading = false;
    this.eventEmitter.emitEvent("update", [this.queue.length]);
    if (this.queue.length === 0) {
      this.uploaded = 0;
      this.uploadTotal = 0;
      this.eventEmitter.emitEvent("ready", []);
    }
    this.uploadNext();
  }

  async upload(data, retryCount = 1) {
    const prevUploaded = this.uploaded;
    try {
      // console.log("[CHUNK UPLOADER]", "uploading chunk", this.recordingId, data);
      let isUploading = true;
      await this.uploadFunc(this.recordingId, data, ({ loaded, total }) => {
        this.uploadTotal += total - getDataSize(data);
        if (!isUploading)
          return;
        this.uploaded = prevUploaded + loaded;
        this.eventEmitter.emitEvent("update", [this.queue.length]);
      });
      this.error = null;
      isUploading = false;
      // this.uploaded = 0;
      return true;
    } catch (ex) {
      console.warn("[CHUNK UPLOADER]", "error uploading chunk, retrying in ", retryCount + "s", ex);
      this.error = ex;
      this.eventEmitter.emitEvent("update", [this.queue.length]);
      this.uploaded = prevUploaded
      await wait(() => this.upload(data, retryCount < MAX_RETRIES_TIMEOUT ? retryCount + 1 : retryCount), 1000 * retryCount);
      return;
    }
  }

  on(...args) {
    this.eventEmitter.addListener(...args);
  }

  off(...args) {
    if (args.length === 1)
      this.eventEmitter.removeAllListeners(...args);
    else
      this.eventEmitter.removeListener(...args);
  }
}

export default ChunkUploader;