import EventEmitter from "wolfy87-eventemitter";
import moment from "moment";

function dataURLtoBlob(dataurl) {
  var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new Blob([u8arr], { type: mime });
}

/*function blobToDataURL(blob, callback) {
  var a = new FileReader();
  a.onload = function(e) {callback(e.target.result);}
  a.readAsDataURL(blob);
}*/

const VIDEO_OPTS = {
  width: { ideal: 1280 },
  height: { ideal: 720 }
};

//silent chunk, 500ms
const SILENT_BLOB = dataURLtoBlob("data:audio/webm;base64,GkXfo59ChoEBQveBAULygQRC84EIQoKEd2VibUKHgQRChYECGFOAZwH/////////FUmpZpkq17GDD0JATYCGQ2hyb21lV0GGQ2hyb21lFlSua7+uvdeBAXPFh+nqElmYsaqDgQKGhkFfT1BVU2Oik09wdXNIZWFkAQEAAIC7AAAAAADhjbWERzuAAJ+BAWJkgSAfQ7Z1Af/////////ngQCjjIEAAID7A//+//7//qOMgQA7gPsD//7//v/+o4yBAHeA+wP//v/+//6jjIEAs4D7A//+//7//qOMgQDvgPsD//7//v/+o4yBASyA+wP//v/+//6jjIEBZ4D7A//+//7//qOMgQGjgPsD//7//v/+");

class Recorder {
  constructor(withVideo = false, disableVideoOnStart = false) {
    this.eventEmitter = new EventEmitter();
    this.streamMimeType = ["video/webm; codecs=vp8", "video/webm", "video/mp4"].find((type) => MediaRecorder.isTypeSupported(type));
    this.isStarted = false;
    this.micDisconnectTime = null;
    this.cameraDisconnectTime = null;
    this.trackIds = [];
    this.withVideo = withVideo;
    this.disableVideoOnStart = disableVideoOnStart;
  }

  destroy() {
    this.eventEmitter.removeAllListeners();
  }

  get hasStream() {
    return !!this.stream;
  }

  get streamActive() {
    return !!this.stream?.active;
  }

  updateTrackIds() {
    this.trackIds = this.stream.getTracks().map(x => x.id);
    this.eventEmitter.emitEvent("tracksChange", [this.trackIds]);
  }

  mute() {
    if (!this.stream) return;
    this.stream.getAudioTracks().forEach(x => x.enabled = false);
  }

  unmute() {
    if (!this.stream) return;
    this.stream.getAudioTracks().forEach(x => x.enabled = true);
  }

  disableVideo() {
    this.stream.getVideoTracks().forEach(x => x.enabled = false);
  }

  async enableVideo() {
    this.stream.getVideoTracks().forEach(x => x.enabled = true);
    this.disableVideoOnStart = false;
    if (!this.videoRecorder && this.isStarted) {
      await this.startVideoRecorder();
      this.eventEmitter.emitEvent("startedChange", [this.isStarted, this.mediaRecorder?.startTime, this.videoRecorder ? this.videoRecorder.startTime - this.mediaRecorder?.startTime : 0]);
    }
  }

  getMicLabel() {
    if (!this.stream) {
      return null;
    }
    const mediaTracks = this.stream.getAudioTracks();
    return mediaTracks.length ? mediaTracks[0].label : null;
  }

  getInputStream(opts) {
    return new Promise((resolve, reject) => {
      if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
        navigator.mediaDevices.getUserMedia(opts).then(resolve, reject);
      } else {
        (navigator.getUserMedia || navigator.webkitGetUserMedia || navigator.mozGetUserMedia || navigator.msGetUserMedia)(opts, resolve, reject);
      }
    });
  }

  async getVideoOpts() {
    let opts = { ...VIDEO_OPTS };
    if (!navigator.mediaDevices || !navigator.mediaDevices.enumerateDevices) {
      console.warn("[RECORDER]", "enumerateDevices() not supported.");
      return opts;
    }
    const devices = await navigator.mediaDevices.enumerateDevices();
    if (!devices.some(x => x.kind === "videoinput"))
      return false;
    return opts;
  }

  async getInputOpts(micId, cameraId, audioSettings) {
    let videoConstraints = false;
    if (this.withVideo) {
      videoConstraints = await this.getVideoOpts();
    }
    if (videoConstraints && cameraId) {
      videoConstraints.deviceId = { exact: cameraId };
    }
    let audioConstraints = true;
    if (micId && audioSettings) {
      audioConstraints = { ...audioSettings, deviceId: { exact: micId } };
    } else {
      if (micId)
        audioConstraints = { deviceId: { exact: micId } };
      if (audioSettings)
        audioConstraints = audioSettings;
    }
    console.log("[RECORDER]", "got constraints", "audio", audioConstraints, "video", videoConstraints);
    return { audio: audioConstraints, video: videoConstraints };
  }

  async configureUserInput(micId, cameraId, audioSettings) {
    this.micId = micId;
    this.cameraId = cameraId;
    const inputOpts = await this.getInputOpts(micId, cameraId, audioSettings);
    return new Promise((resolve, reject) => {
      if (!this.stream) {
        this.getInputStream(inputOpts).then((stream) => {
          this.stream = stream;
          if (this.disableVideoOnStart)
            this.stream.getVideoTracks().forEach(x => x.enabled = false);
          this.updateTrackIds();
          this.micDisconnectTime = null;
          const audioTracks = stream.getAudioTracks();
          if (audioTracks.length > 0) {
            this.activeTrack = audioTracks[0];
            this.activeTrack.onended = this.onMicDisconnected;
          }
          const videoTracks = stream.getVideoTracks();
          if (videoTracks.length > 0) {
            this.activeVideoTrack = videoTracks[0];
            this.activeVideoTrack.onended = this.onCameraDisconnected;
          }
          this.eventEmitter.emitEvent("micStatusChange", [true]);
          resolve(stream);
          this.initMicCalculator(stream);
        }, reject);
      } else {
        resolve(this.stream);
      }
    });
  }

  onMicDisconnected = () => {
    console.error("[RECORDER]", "MIC DISCONNECTED");
    this.eventEmitter.emitEvent("micStatusChange", [false]);
    this.micDisconnectTime = Date.now();
    if (this.isStarted) {
      // this.mediaRecorder.requestData();
      if (this.videoRecorder) {
        this.videoRecorder.requestData();
      }
      this.interval = setInterval(() => {
        if (this.streamActive) {
          clearInterval(this.interval);
          return;
        }
        this.eventEmitter.emitEvent("audiochunk", [SILENT_BLOB]);
      }, 500);
    }
    navigator.mediaDevices.ondevicechange = this.reconnectDevice;
  }

  onCameraDisconnected = () => {
    console.error("[RECORDER]", "CAMERA DISCONNECTED");
    this.eventEmitter.emitEvent("cameraStatusChange", [false]);
    this.cameraDisconnectTime = Date.now();
    if (this.isStarted && this.videoRecorder) {
      this.videoRecorder.requestData();
      // this.interval = setInterval(() => {
      //   if (this.streamActive) {
      //     clearInterval(this.interval);
      //     return;
      //   }
      //   this.eventEmitter.emitEvent("audiochunk", [SILENT_BLOB]);
      // }, 500);
    }
    navigator.mediaDevices.ondevicechange = this.reconnectDevice;
  }

  reconnectDevice = () => {
    if (this.micDisconnectTime)
      this.reconnectMic();
    if (this.cameraDisconnectTime)
      this.reconnectCamera();
  }

  reconnectMic = async () => {
    console.log("[RECORDER]", "reconnecting mic");
    let stream;
    try {
      const inputOpts = await this.getInputOpts(this.micId, this.cameraId);
      stream = await this.getInputStream(inputOpts);
    }
    catch (ex) {
      console.error("[RECORDER]", "reconnecting mic error", ex);
      // return;
    }
    if (!stream) {
      try {
        const inputOpts = await this.getInputOpts(this.micId, this.cameraId);
        inputOpts.video = false;
        stream = await this.getInputStream(inputOpts);
      }
      catch (ex) {
        console.error("[RECORDER]", "reconnecting mic error", ex);
        // return;
      }
    }
    if (!stream)
      return;
    const tracks = stream.getAudioTracks();
    if (tracks.length === 0) {
      return console.error("[RECORDER]", "reconnecting mic error", "no tracks");
    }
    let track = tracks.find(track => track.label === this.activeTrack.label);
    if (!track) {
      console.warn("[RECORDER]", "reconnecting mic error", 'mic label not found, needed:', this.activeTrack.label, tracks);
      // for (let t of stream.getTracks()) {
      //   t.stop();
      // }
      return;
    }
    this.stream.removeTrack(this.activeTrack);
    this.stream.addTrack(track);
    this.activeTrack = track;
    this.initMicCalculator(this.stream);
    this.updateTrackIds();
    if (this.isStarted) {
      const duration = Date.now() - this.micDisconnectTime;
      //const numberOfChunks = Math.round(duration / 500);
      console.log("[RECORDER]", 'mic disconnect duration', duration / 1000);
      //console.log('number of chunks', numberOfChunks);
      // await this.stop();
      // this.start();
      // if (this.cameraDisconnectTime)
      //   this.restartAudioRecorder();
      // else
      this.restartRecorders();
    }
    this.micDisconnectTime = null;
    this.eventEmitter.emitEvent("micStatusChange", [true]);
    console.log("[RECORDER]", "mic reconnected");
    if (!this.cameraDisconnectTime)
      navigator.mediaDevices.ondevicechange = null;
    this.activeTrack.onended = this.onMicDisconnected;
  }

  reconnectCamera = async () => {
    console.log("[RECORDER]", "reconnecting camera");
    let stream;
    try {
      const inputOpts = await this.getInputOpts(this.micId, this.cameraId);
      stream = await this.getInputStream(inputOpts);
    }
    catch (ex) {
      console.error("[RECORDER]", "reconnecting camera error", ex);
      // return;
    }
    if (!stream) {
      try {
        const inputOpts = await this.getInputOpts(this.micId, this.cameraId);
        inputOpts.audio = false;
        stream = await this.getInputStream(inputOpts);
      }
      catch (ex) {
        console.error("[RECORDER]", "reconnecting mic error", ex);
        // return;
      }
    }
    if (!stream)
      return;
    const tracks = stream.getVideoTracks();
    if (tracks.length === 0) {
      return console.error("[RECORDER]", "reconnecting camera error", "no tracks");
    }
    let track = tracks.find(track => track.label === this.activeVideoTrack.label);
    if (!track) {
      console.warn("[RECORDER]", "reconnecting camera error", 'camera label not found, needed:', this.activeVideoTrack.label);
      // for (let t of stream.getTracks()) {
      //   t.stop();
      // }
      return;
    }
    this.stream.removeTrack(this.activeVideoTrack);
    this.stream.addTrack(track);
    this.activeVideoTrack = track;
    this.updateTrackIds();
    if (this.isStarted) {
      const duration = Date.now() - this.cameraDisconnectTime;
      //const numberOfChunks = Math.round(duration / 500);
      console.log("[RECORDER]", 'camera disconnect duration', duration / 1000);
      //console.log('number of chunks', numberOfChunks);
      // if (!this.micDisconnectTime) {
      //   await this.stop();
      //   this.start();
      // }
      this.restartVideoRecorder();
    }
    this.cameraDisconnectTime = null;
    this.eventEmitter.emitEvent("cameraStatusChange", [true]);
    console.log("[RECORDER]", "camera reconnected");
    if (!this.micDisconnectTime)
      navigator.mediaDevices.ondevicechange = null;
    this.activeVideoTrack.onended = this.onCameraDisconnected;
  }

  initMicCalculator(stream) {
    const audioContext = new AudioContext(),
      analyser = audioContext.createAnalyser(),
      source = audioContext.createMediaStreamSource(stream);

    analyser.smoothingTimeConstant = 0.3;
    analyser.fftSize = 1024;

    source.connect(analyser);

    this.analyser = analyser;
    this.analyserBuffer = new Uint8Array(analyser.frequencyBinCount);

    cancelAnimationFrame(this.nextCalcMicVolumeReqId);
    this.calculateMicVolume();
  }

  calculateMicVolume = () => {
    let micVol = 0;

    this.analyser.getByteFrequencyData(this.analyserBuffer);

    for (let i = 0; i < this.analyserBuffer.length; i++) {
      micVol += this.analyserBuffer[i] * this.analyserBuffer[i];
    }

    micVol /= this.analyserBuffer.length;
    micVol = Math.sqrt(micVol);

    if (micVol !== this.oldMicVolume) {
      this.oldMicVolume = micVol;
      this.eventEmitter.emitEvent("micvolume", [micVol * 0.8]);
    }

    this.nextCalcMicVolumeReqId = requestAnimationFrame(this.calculateMicVolume);
  }

  // restartAudioRecorder() {
  //   this.mediaRecorder.onstop = () => {
  //     this.startAudioRecorder();
  //   };
  //   this.mediaRecorder.stop();
  // }

  restartVideoRecorder() {
    this.videoRecorder.onstop = () => {
      this.startVideoRecorder();
    };
    this.videoRecorder.stop();
  }

  async restartRecorders() {
    const videoPromise = new Promise(resolve => {
      this.videoRecorder.onstop = () => {
        resolve();
      };
      this.videoRecorder.stop();
    });
    // const audioPromise = new Promise(resolve => {
    //   this.mediaRecorder.onstop = () => {
    //     resolve();
    //   };
    //   this.mediaRecorder.stop();
    // });
    // await Promise.all([audioPromise, videoPromise]);
    await videoPromise;
    // this.startAudioRecorder();
    this.startVideoRecorder();
  }

  startVideoRecorder() {
    console.log("[RECORDER]", "video recorder starting");
    // this.videoStream = new MediaStream(this.stream.getVideoTracks());
    this.videoRecorder = new MediaRecorder(this.stream, { mimeType: "video/webm", videoBitsPerSecond: 3 * 1000 * 1000, audioBitsPerSecond: 32000 });
    const startVideoPromise = new Promise(resolve => {
      this.videoRecorder.onstart = function () {
        // console.log(Date.now());
        this.startTime = moment().utc();
        console.log("[RECORDER]", "video recorder started");
        resolve();
      }
    });
    this.videoRecorder.ondataavailable = e => this.eventEmitter.emitEvent("videochunk", [e.data]);
    this.videoRecorder.onerror = e => console.error("[RECORDER]", "video recorder err", e);
    this.videoRecorder.start(10 * 1000);
    return startVideoPromise;
  }

  async start() {
    console.log("[RECORDER]", "starting");
    if (!this.streamActive || this.videoRecorder) {
      console.error("[RECORDER]", "start err", !this.streamActive, !!this.videoRecorder);
      if (!this.streamActive)
        alert("Can't start recording, mic is disconnected");
      return null;
    }

    const promises = [];

    if (this.withVideo && !this.disableVideoOnStart) {
      const startVideoPromise = this.startVideoRecorder();
      promises.push(startVideoPromise);
    }

    this.isStarted = true;

    const startPromise = Promise.all(promises);
    startPromise.then(() => {
      console.log("[RECORDER]", "started");
      this.eventEmitter.emitEvent("startedChange", [this.isStarted, this.mediaRecorder?.startTime, this.videoRecorder ? this.videoRecorder.startTime - this.mediaRecorder?.startTime : 0]);
    });
    return startPromise;
  }

  stop(stopTracks = false) {
    console.log("[RECORDER]", "stopping");

    if (!this.videoRecorder) {
      this.isStarted = false;
      this.eventEmitter.emitEvent("startedChange", [this.isStarted]);
      return Promise.reject("media recorder is not initialized");
    }

    return new Promise((resolve) => {
      const videoPromise = new Promise(resolve => {
        if (!this.videoRecorder)
          resolve();
        this.videoRecorder.onstop = () => {
          this.videoRecorder = null;
          console.log("[RECORDER]", "video stopped");
          resolve();
        };
        this.videoRecorder.stop();
      });
      // const audioPromise = new Promise(resolve => {
      //   this.mediaRecorder.onstop = () => {
      //     this.mediaRecorder = null;
      //     this.isStarted = false;
      //     console.log("[RECORDER]", "stopped");
      //     this.eventEmitter.emitEvent("startedChange", [this.isStarted]);
      //     resolve();
      //   };
      //   this.mediaRecorder.stop();
      // });
      Promise.all([videoPromise/* , audioPromise */]).then(resolve).then(() => {
        if (stopTracks)
          this.stopTracks();
      });
    });
  }

  stopTracks() {
    if (!this.stream)
      return;
    this.stream.getTracks().forEach(x => x.stop());
  }

  on(...args) {
    this.eventEmitter.addListener(...args);
  }

  off(...args) {
    if (args.length === 1)
      this.eventEmitter.removeAllListeners(...args);
    else
      this.eventEmitter.removeListener(...args);
  }
}

export default Recorder;
