import React, { useState, useMemo } from 'react';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
// import { useFeedback } from '../feedback/Service';
import JobDetails from './JobDetails';
import { socialMediaDictionary } from '../../dictionaries/social';
import { Link } from 'react-router-dom';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import SimpleDialog from '../SimpleDialog';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { Badge } from '@mui/material';
import ClientMatches from './ClientMatches';
import FieldValue from '../FieldValue';
import Support from '../Support';

export const ClientFields = ({ client }) => (
  <>
    <FieldValue label="Company" value={client.company} />
    <FieldValue label="Website" url={"https://" + client.website} />
    {client.linkedin && <FieldValue label="LinkedIn" url={socialMediaDictionary.linkedin.baseUrl + client.linkedin} />}
    <FieldValue label="Industry" value={client.industry} />
    <FieldValue label="Funding" value={client.funding} />
    <FieldValue label="Revenue" value={client.revenue} />
    <FieldValue label="Number of employees" value={client.employeesNumber} />
    <FieldValue label="Number of employees in sales team" value={client.salesNumber} />
    {/* {client.twitter && <FieldValue label="Twitter" url={socialMediaDictionary.twitter.baseUrl + client.twitter} />}
          {client.facebook && <FieldValue label="Facebook" url={socialMediaDictionary.facebook.baseUrl + client.facebook} />}
          {client.instagram && <FieldValue label="Instagram" url={socialMediaDictionary.instagram.baseUrl + client.instagram} />}
          {client.customLink && <FieldValue label="Custom Link" url={"https://" + client.customLink} />} */}
    {/* <FieldValue label="Culture" value={client.culture} /> */}
    <FieldValue label="Additional Info" value={client.comment || "(not provided)"} />
  </>
)

const ClientProfile = ({ client, showJobs, showEdit, showUserFields }) => {
  // const feedback = useFeedback();
  const [tab, setTab] = useState("info");
  const handleChange = (event, newValue) => setTab(newValue);
  const [selectedJobIndex, setSelectedJobIndex] = useState(null);
  const selectedJob = useMemo(() => {
    if (!client || selectedJobIndex === null)
      return null;
    return client.jobs[selectedJobIndex];
  }, [client, selectedJobIndex]);

  const handleShowJob = index => () => setSelectedJobIndex(index);

  if (!client)
    return null;

  const matchesCount = client.jobs.reduce((acc, item) => acc + (item.matches || []).filter(x => x.acceptedByClient === null).length, 0);

  return (
    <Box sx={{ width: '100%', typography: 'body1' }}>
      <SimpleDialog title={selectedJob?.position.name} open={!!selectedJob} onClose={() => setSelectedJobIndex(null)} maxWidth="lg" fullWidth scroll="body">
        <JobDetails showEdit job={selectedJob} />
      </SimpleDialog>
      <TabContext value={tab}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList onChange={handleChange} aria-label="tabs">
            <Tab label="Profile" value={"info"} />
            {showJobs && <Tab label="Requests" value={"requests"} />}
            {/* <Tab label="Talent Matches" value={"matches"} /> */}
            {client.jobs.length > 0 &&
              <Tab label={<Badge badgeContent={matchesCount} color="primary" sx={{ "& > span": { right: -6 } }}>Request Matches</Badge>} value={"matches"} />
            }
            <Tab label="Support" value={"support"} />
          </TabList>
        </Box>
        <TabPanel value="info">
          {showUserFields &&
            <>
              <FieldValue label="Name" value={client.user.firstName + " " + client.user.lastName} />
              <FieldValue label="Email" value={client.user.email} />
            </>
          }
          <ClientFields client={client} />
          {showEdit && <Button variant="outlined" sx={{ mt: 1 }} component={Link} to="/account/client/edit-profile">Edit</Button>}
        </TabPanel>
        <TabPanel value="requests">
          <List
            component="nav"
          >
            {client.jobs.map((job, index) => (
              <ListItemButton key={job._id} onClick={handleShowJob(index)}>
                <ListItemText primary={job.position.name} />
              </ListItemButton>
            ))}
          </List>
          <Fab color="primary" aria-label="add" sx={{ position: "relative", right: 8, bottom: 8, float: "right" }} component={Link} to="/account/client/request/new">
            <AddIcon />
          </Fab>
        </TabPanel>
        <TabPanel value="matches">
          <ClientMatches client={client} />
        </TabPanel>
        <TabPanel value="support">
          <Support />
        </TabPanel>
      </TabContext>
    </Box>
  );
}

export default ClientProfile;