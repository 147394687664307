import React, { useEffect } from 'react';
import { useQuery } from '@apollo/client';
import POSITIONS from '../../gql/common/positions.query';
// import MultipleSelectChip from './MultipleSelectChip';
import CircularProgress from '@mui/material/CircularProgress';
import CustomSelect from './CustomSelect';

const PositionSelect = ({ setDefaultPosition, ...props }) => {
  const { loading, data, error } = useQuery(POSITIONS);

  useEffect(() => {
    if (!data)
      return;
    const defaultPosition = data.positions.find(p => p.name === "Account Executive");
    if (defaultPosition)
      setDefaultPosition(defaultPosition._id);
  }, [data]);

  if (error) return "Error getting positions";
  if (loading) return <CircularProgress />;
  // return (<MultipleSelectChip items={data.positions} {...props} />);
  return (<CustomSelect items={data.positions} {...props} />);
}

export default PositionSelect;