import React from 'react';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { Typography, Grid, FormHelperText } from '@mui/material';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
dayjs.extend(utc);
dayjs.extend(timezone);

const WorkingHours = ({ startTime, endTime, onChange, label, helperText }) => {

  const handleChange = name => (newValue) => {
    onChange({ target: { name, value: newValue } });
  }

  return (
    <>
      <Grid item xs={12} md={4}>
        <Typography variant="body2" sx={{ mb: 1 }}>
          {label}<br />
          Your timezone is <strong>{dayjs.tz.guess()}</strong>.
        </Typography>
      </Grid>
      <Grid item xs={12} md={4}>
        <TimePicker
          label="Start time"
          value={startTime}
          onChange={handleChange('startTime')}
          // timezone='system'
          slotProps={{
            textField: {
              required: true,
            },
          }}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <TimePicker
          required
          label="End time"
          value={endTime}
          onChange={handleChange('endTime')}
          // timezone='system'
          slotProps={{
            textField: {
              required: true,
            },
          }}
        />
      </Grid>
      {helperText &&
        <Grid item xs={12}>
          <FormHelperText variant="body2" sx={{ mt: -2, mb: 0.5 }}>
            {helperText}
          </FormHelperText>
        </Grid>
      }
    </>
  );
}

export default WorkingHours;