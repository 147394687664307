import React from 'react';
import CustomSelect from './CustomSelect';

const data = [
  { name: "Angel" },
  { name: "Pre-seed & Seed" },
  { name: "Series A" },
  { name: "Series B" },
]

const FundingSelect = ({ ...props }) => {
  return (
    <CustomSelect items={data} {...props} valueField="name" otherOption />
  )
}

export default FundingSelect;