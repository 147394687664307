import React from 'react';
import CustomSelect from './CustomSelect';

export const data = [
  { name: "Not applicable", value: { from: 0, to: 0 } },
  { name: "Less than $5,000", value: { from: 1, to: 5000 } },
  { name: "$5,000 - $10,000", value: { from: 5000, to: 10_000 } },
  { name: "$10,000 - $30,000", value: { from: 10_000, to: 30_000 } },
  { name: "$30,000 - $50,000", value: { from: 30_000, to: 50_000 } },
  { name: "$50,000 - $100,000", value: { from: 50_000, to: 100_000 } },
  { name: "$100,000 - $300,000", value: { from: 100_000, to: 300_000 } },
  { name: "$300,000 - $500,000", value: { from: 300_000, to: 500_000 } },
  { name: "$500,000 - $1M", value: { from: 500_000, to: 1_000_000 } },
  { name: "Over $1M", value: { from: 1_000_000, to: 999_999_999_999 } },
]

const DealSizeSelect = ({ ...props }) => {
  return (
    <CustomSelect items={data} {...props} valueField="value" />
  )
}

export default DealSizeSelect;