import React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import ME from '../../gql/common/me.query';
import useLoginRedirect from '../../hooks/useLoginRedirect';
import { useQuery } from '@apollo/client';
import SignUpForm from './SignUpForm';

const SignUpPage = () => {
  const { data: user } = useQuery(ME);
  useLoginRedirect(user?.me);

  return (
    <Container maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          marginBottom: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <SignUpForm isTalent />
      </Box>
    </Container>
  );
}

export default SignUpPage;