import React, { useState, useEffect, useMemo } from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { useQuery, gql } from '@apollo/client';
// import ME from '../../gql/common/me.query';
import Stepper from '../Stepper';
import SignUpForm from '../auth/SignUpForm';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { CORE_USER_FIELDS } from '../../gql/fragments/user.fragment';
import ClientForm from './ClientForm';
import JobForm from './JobForm';
import ConfirmEmail from '../auth/ConfirmEmail';
import { CORE_CLIENT_FIELDS } from '../../gql/fragments/client.fragment';
import { Link } from 'react-router-dom';

const ME = gql`
  ${CORE_USER_FIELDS}
  ${CORE_CLIENT_FIELDS}
  query GetMe {
    me {
      ...CoreUserFields
      client {
        ...CoreClientFields
        jobs {
          _id
        }
      }
    }
  }
`;

const initialSteps = [
  { id: 0, label: "Registration" },
  { id: 1, label: "Confirm email" },
  { id: 2, label: "Fill Your Profile", clickable: true, },
  { id: 3, label: "Post a Talent Request", optional: true, clickable: true },
];

const ClientSignUp = () => {
  const { loading: meLoading, data: meData } = useQuery(ME);
  const [step, setStep] = useState(0);

  const steps = useMemo(() => {
    const result = [...initialSteps];
    if (!meData)
      return result;
    const { me: user } = meData;
    if (user) {
      result[0].completed = true;
      result[0].disabled = false;
    }
    if (user.emailConfirmed) {
      result[1].completed = true;
      result[1].disabled = false;
    }
    if (user?.client) {
      result[2].completed = true;
      result[2].disabled = false;
      result[3].disabled = false;
    }
    if (user?.client?.jobs?.length > 0)
      result[3].completed = true;
    return result;
  }, [meData]);

  useEffect(() => {
    if (!meData) return;
    const { me: user } = meData;
    if (!user.emailConfirmed) {
      setStep(1);
      return;
    }
    setStep(user.client ? (user.client.jobs?.length > 0 ? 4 : 3) : 2);
  }, [meData]);

  useEffect(() => {
    window.scroll(0, 0);
  }, [step]);

  if (meLoading) return null;
  // console.log("me", meData);
  return (
    <Box
      sx={{
        marginTop: 3,
        // display: 'flex',
        // flexDirection: 'column',
        // alignItems: 'center',
      }}
    >
      <Container maxWidth="md">
        <Stepper steps={steps} activeStep={step} setStep={setStep} />
      </Container>
      {step === 0 &&
        <Container maxWidth="xs">
          <Box
            sx={{
              marginTop: 4,
              marginBottom: 8,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <SignUpForm />
          </Box>
        </Container>}
      {step === 1 &&
        <Container maxWidth="xs">
          <Box
            sx={{
              marginTop: 4,
              marginBottom: 8,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <ConfirmEmail userId={meData?.me?._id} />
          </Box>
        </Container>}
      {step === 2 &&
        <Container maxWidth="md">
          <Box
            sx={{
              marginTop: 4,
              marginBottom: 8,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <ClientForm userId={meData?.me?._id} data={meData?.me?.client} />
          </Box>
        </Container>
      }
      {step === 3 &&
        <Container maxWidth="md">
          <Box
            sx={{
              marginTop: 4,
              marginBottom: 8,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Button onClick={() => setStep(4)} variant="outlined">Skip this step</Button>
            <Typography variant="caption" sx={{ marginBottom: 1 }}>You{"'"}ll be able to add any number of job offers later.</Typography>
            <JobForm clientId={meData?.me?.client?._id} />
          </Box>
        </Container>
      }
      {step === 4 &&
        <Container maxWidth="sm">
          <Box
            sx={{
              marginTop: 8,
              marginBottom: 8,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Typography variant="h6" align='center'>Thank you for completing the registration process! You{"'"}ll receive further instructions from our team by email.</Typography>
            <div>
              <Button onClick={() => setStep(3)} variant="contained" sx={{ m: 2 }} >Add another talent request</Button>
              <Button component={Link} to="/account" variant="contained" sx={{ m: 2 }} >Go to Account Page</Button>
            </div>
          </Box>
        </Container>
      }
    </Box>
  );
}

export default ClientSignUp;