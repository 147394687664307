import React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Grid from '@mui/material/Grid';
import { Box, Divider, FormControlLabel, IconButton, TextField, Checkbox, Button } from '@mui/material';
import { Add } from '@mui/icons-material';

const defaultItem = {
  company: '',
  position: '',
  startMonth: null,
  startYear: null,
  endMonth: null,
  endYear: null,
  isCurrent: false,
  description: '',
}

const numericFields = ['startMonth', 'startYear', 'endMonth', 'endYear'];

const WorkExperienceItem = ({ item, onItemChange, index }) => {
  const handleChange = ({ target: { value, type, checked, name } }) => {
    console.log(name, value, index);
    if (numericFields.includes(name))
      value = value ? parseInt(value) : null;
    onItemChange(index)({ ...item, [name]: type === "checkbox" ? checked : value });
  }
  return (
    <Accordion defaultExpanded={!item.company}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
      // aria-controls="panel1a-content"
      // id="panel1a-header"
      >
        <Typography>{item.position} at {item.company} {item.startMonth}/{item.startYear} - {item.isCurrent ? 'Present' : `${item.endMonth || ""}/${item.endYear || ""}`}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              id="position"
              name="position"
              label="Title"
              fullWidth
              value={item.position}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              id="company"
              name="company"
              label="Company"
              fullWidth
              value={item.company}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={<Checkbox color="primary" checked={item.isCurrent} onChange={handleChange} name="isCurrent" />}
              label="I currently work here"
              name="isCurrent"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              type='number'
              inputProps={{ min: 1, max: 12 }}
              id="startMonth"
              name="startMonth"
              label="Start Month"
              fullWidth
              value={item.startMonth ?? ''}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              type='number'
              id="startYear"
              name="startYear"
              label="Start Year"
              fullWidth
              value={item.startYear ?? ''}
              onChange={handleChange}
            />
          </Grid>
          {!item.isCurrent && (
            <>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  type='number'
                  id="endMonth"
                  name="endMonth"
                  label="End Month"
                  fullWidth
                  value={item.endMonth ?? ''}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  type='number'
                  id="endYear"
                  name="endYear"
                  label="End Year"
                  fullWidth
                  value={item.endYear ?? ''}
                  onChange={handleChange}
                />
              </Grid>
            </>
          )}
          <Grid item xs={12}>
            <TextField
              id="description"
              name="description"
              label="Describe your accomplishments and responsibilities, minimum 70 characters"
              multiline
              minRows={5}
              fullWidth
              value={item.description}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <Button variant="contained" color="error" onClick={() => onItemChange(index)(null)}>Remove</Button>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
}

const WorkExperience = ({ items, onChange }) => {
  console.log(items);

  const handleItemChange = index => item => {
    console.log(index, item);
    const newItems = [...items];
    newItems[index] = item;
    onChange(newItems.filter(Boolean));
  }

  return (
    <div>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <Typography variant="h6" gutterBottom>Work experience</Typography>
        <IconButton aria-label="add" size="small" onClick={() => onChange([...items, { ...defaultItem }])}>
          <Add />
        </IconButton>
      </Box>
      <br />
      {items.map((item, index) => <WorkExperienceItem key={index} item={item} onItemChange={handleItemChange} index={index} />)}
      {items.length === 0 && <Typography variant="body2" gutterBottom>No work experience added yet</Typography>}
      <br />
      <Divider />
      <br />
    </div>

  );
}

export default WorkExperience;