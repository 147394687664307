import React, { useState, useMemo } from 'react';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import { useFeedback } from '../feedback/Service';

const CustomSelect = ({ otherOption, items: initialItems = [], value, valueField = "_id", labelField = "name", onChange, ...props }) => {
  const [options, setOptions] = useState([]);
  const feedback = useFeedback();

  let items = useMemo(() => {
    let result = [...initialItems, ...options];
    if (otherOption)
      result.push({
        [valueField]: "Other",
        [labelField]: "Other"
      });
    return result;
  }, [initialItems, otherOption, valueField, labelField, options]);

  const handleChange = async e => {
    const { target: { value: newValue } } = e;
    const otherSelected = value !== "Other" && newValue === "Other";
    console.log(newValue);
    if (!otherOption || !otherSelected)
      return onChange(e);
    const newTarget = { ...e.target };
    const option = await feedback.form({ title: "Other Option", input: { title: "Value" } });
    if (!option)
      return;
    // console.log(option);
    setOptions(x => [...x, { [valueField]: option, [labelField]: option }]);
    newTarget.value = option;
    onChange({ target: newTarget });
  };

  return(
    <FormControl fullWidth required={props.required}>
      <InputLabel id="demo-simple-select-label">{props.label}</InputLabel>
      <Select {...props} onChange={handleChange} value={value}>
        {items.map(industry => <MenuItem key={typeof industry[valueField] === "object" ? JSON.stringify(industry[valueField]) : industry[valueField]} value={industry[valueField]}>{industry[labelField]}</MenuItem>)}
      </Select>
    </FormControl>
  );
}

export default CustomSelect;