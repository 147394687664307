import * as React from 'react';
import ListItem from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import HomeIcon from '@mui/icons-material/Home';
// import DashboardIcon from '@mui/icons-material/Dashboard';
import PeopleIcon from '@mui/icons-material/People';
import MatchingIcon from '@mui/icons-material/JoinLeft';
import UsersIcon from '@mui/icons-material/GroupsOutlined';
import TalentIcon from '@mui/icons-material/Surfing';
import PositionsIcon from '@mui/icons-material/LibraryBooks';
import CommissionIcon from '@mui/icons-material/Money';
import AssignmentIcon from '@mui/icons-material/Assignment';
import { Link, useLocation } from 'react-router-dom';

const mainItems = [
  { title: "Home", url: "/", icon: <HomeIcon /> },
  // { title: "Dashboard", url: "/admin/dashboard", icon: <DashboardIcon /> },
  { title: "Matching", url: "/admin/matching", icon: <MatchingIcon /> },
  { title: "Clients", url: "/admin/clients", icon: <PeopleIcon /> },
  { title: "Talents", url: "/admin/talents", icon: <TalentIcon /> },
  { title: "Users", url: "/admin/users", icon: <UsersIcon /> },
  { title: "Positions", url: "/admin/positions", icon: <PositionsIcon /> },
  { title: "Commissions", url: "/admin/commission-types", icon: <CommissionIcon /> },
];

const Item = ({ url, title, icon }) => {
  const { pathname } = useLocation();
  return (
    <ListItem component={Link} to={url} selected={pathname.startsWith(url) && url !== "/"}>
      <ListItemIcon>
        {icon}
      </ListItemIcon>
      <ListItemText primary={title} />
    </ListItem>
  )
};

export const mainListItems = (
  <>
    {mainItems.map(({ title, url, icon }) => <Item key={title} title={title} icon={icon} url={url} />)}
  </>
);

export const secondaryListItems = (
  <div>
    <ListSubheader inset>Saved reports</ListSubheader>
    <ListItem button>
      <ListItemIcon>
        <AssignmentIcon />
      </ListItemIcon>
      <ListItemText primary="Current month" />
    </ListItem>
    <ListItem button>
      <ListItemIcon>
        <AssignmentIcon />
      </ListItemIcon>
      <ListItemText primary="Last quarter" />
    </ListItem>
    <ListItem button>
      <ListItemIcon>
        <AssignmentIcon />
      </ListItemIcon>
      <ListItemText primary="Year-end sale" />
    </ListItem>
  </div>
);