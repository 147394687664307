import React from 'react';
import { useQuery } from '@apollo/client';
// import MultipleSelectChip from './MultipleSelectChip';
import CircularProgress from '@mui/material/CircularProgress';
import CustomSelect from './CustomSelect';
import COMMISSION_TYPES from '../../gql/common/commissionTypes.query';

const CommissionTypeSelect = ({ ...props }) => {
  const { loading, data, error } = useQuery(COMMISSION_TYPES);
  if (error) return "Error getting types";
  if (loading) return <CircularProgress />;
  return (<CustomSelect items={data.commissionTypes} {...props} />);
}

export default CommissionTypeSelect;