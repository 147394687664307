import React, { useMemo, useState } from 'react';
import Box from '@mui/material/Box';
import DataGrid from '../../DataGrid';
import JobsGrid from './JobsGrid';
import { useFeedback } from '../../feedback/Service';
import JobDetails from '../../clients/JobDetails';
import { ClientFields } from '../../clients/ClientProfile';
import { CircularProgress, Divider, Grid, IconButton, List, ListItem, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { CORE_CLIENT_FIELDS } from '../../../gql/fragments/client.fragment';
import { CORE_JOB_FIELDS } from '../../../gql/fragments/job.fragment';
import { gql, useMutation } from '@apollo/client';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import DeleteIcon from '@mui/icons-material/DeleteForever';
// import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
// import { GET_MATCHING_CLIENTS } from './Matching';
import SimpleDialog from '../../SimpleDialog';
import { CORE_DESIRED_POSITION_FIELDS } from '../../../gql/fragments/desiredPosition.fragment';
import { CORE_TALENT_FIELDS } from '../../../gql/fragments/talent.fragment';
import { formatTimestamp, parseGraphQLError } from '../../../utils';
dayjs.extend(utc);
dayjs.extend(timezone);

const ADD_MATCH = gql`
  ${CORE_CLIENT_FIELDS}
  ${CORE_JOB_FIELDS}
  ${CORE_DESIRED_POSITION_FIELDS}
  ${CORE_TALENT_FIELDS}
  mutation AddMatch($match: MatchInput!) {
    createMatch(match: $match) {
      ...CoreClientFields
      user {
        _id
        email
        firstName
        lastName
      }
      jobs {
        ...CoreJobFields
        matches {
          _id
          desiredPosition {
            _id
            ...CoreDesiredPositionFields
          }
          talent {
            ...CoreTalentFields
            _id
            user {
              _id
              firstName
            }
          }
          acceptedByClient
          acceptedByTalent
          interviewScheduled
          createdAt
        }
      }
    }
  }
`;

const DELETE_MATCH = gql`
  ${CORE_CLIENT_FIELDS}
  ${CORE_JOB_FIELDS}
  mutation DeleteMatch($_id: String!) {
    deleteMatch(_id: $_id) {
      ...CoreClientFields
      user {
        _id
        email
        firstName
        lastName
      }
      jobs {
        ...CoreJobFields
      }
    }
  }
`;

const cols = [
  { accessorKey: 'company', header: 'Company', size: 150, pin: true, id: "company"},
  { accessorFn: ({ user }) => user.firstName + " " + user.lastName, header: 'Name', size: 150, id: "name" },
  { accessorFn: ({ user }) => user.email, header: 'E-mail', size: 150, id: "email" },
  { accessorKey: '_id', header: 'ID', width: 20, hide: true, enableClickToCopy: true },
  { accessorKey: 'industry', header: 'Industry', width: 17, filterVariant: 'multi-select' },
  { accessorKey: 'funding', header: 'Funding', width: 17, filterVariant: 'multi-select' },
  { accessorKey: 'revenue', header: 'Revenue', width: 100, filterVariant: 'multi-select' },
  { accessorKey: 'employeesNumber', header: 'Number of employees', width: 100, filterVariant: 'multi-select' },
  { accessorKey: 'salesNumber', header: 'Number of employees in sales team', width: 100, filterVariant: 'multi-select' },
  { accessorFn: ({ createdAt }) => formatTimestamp(createdAt), header: 'Created', size: 150 },
  { accessorFn: ({ updatedAt }) => formatTimestamp(updatedAt), header: 'Updated', size: 150 },
];

const AddMatch = ({ jobId }) => {
  const [desiredPositionId, setDesiredPositionId] = useState("");
  const [addMatch, { loading: isPosting }] = useMutation(ADD_MATCH, {
    refetchQueries: [
      'GetMatchingClients'
    ],
  });

  const feedback = useFeedback();

  const handleAddMatch = async () => {
    try {
      const res = await addMatch(({ variables: { match: { desiredPositionId, jobId } } }));
      console.log("match added", res);
      feedback.snackbar({ text: "Match added", type: "success" });
      setDesiredPositionId("");
    } catch (error) {
      console.error("Add match error", error);
      feedback.snackbar({ text: parseGraphQLError(error), type: "error" });
    }
  }

  return (
    <Box sx={{ mt: 2 }}>
      <TextField
        value={desiredPositionId}
        onChange={e => setDesiredPositionId(e.target.value)}
        label="Desired Position ID"
        margin='none'
        size='small'
      />
      <LoadingButton disabled={!desiredPositionId} loading={isPosting} onClick={handleAddMatch} variant='contained' sx={{ mt: "1px", ml: 1 }}>Add Match</LoadingButton>
    </Box>
  );
}

const MatchListItem = ({ match }) => {
  const [deleteMatch, { loading: isDeleting }] = useMutation(DELETE_MATCH, {
    refetchQueries: [
      // GET_MATCHING_CLIENTS, // DocumentNode object parsed with gql
      'GetMatchingClients' // Query name
    ],
  });
  const feedback = useFeedback();

  const handleDeleteClick = async () => {
    if (!await feedback.confirm({ title: "Delete match?" }))
      return;
    try {
      const res = await deleteMatch(({ variables: { _id: match._id } }));
      console.log("match deleted", res);
      feedback.snackbar({ text: "Match deleted", type: "info" });
    } catch (error) {
      console.error("Delete match error", error);
      feedback.snackbar({ text: parseGraphQLError(error), type: "error" });
    }
  }

  const createdAt = dayjs(match.createdAt * 1).tz(dayjs.tz.guess()).format("lll");
  const acceptedByClient = match.acceptedByClient === null ? "" : (match.acceptedByClient ? "Accepted by client; " : "Rejected by client; ");
  const acceptedByTalent = match.acceptedByTalent === null ? "" : (match.acceptedByTalent ? "Accepted by talent; " : "Rejected by talent; ");
  const interviewScheduled = match.interviewScheduled ? "Interview scheduled; " : "";

  return (
    <ListItem
      secondaryAction={isDeleting ? <CircularProgress /> :
        <IconButton edge="end" aria-label="delete" color="error" onClick={handleDeleteClick}>
          <DeleteIcon />
        </IconButton>
      }
    >
      <ListItemButton /* onClick={handleShowJob(index)} */ component="a" target='_blank' href={"/admin/talents/" + match.talent._id}>
        <ListItemText
          primary={`${match.talent.user.firstName} ${match.talent.user.lastName} | ${match.talent.user.email}`}
          secondary={`Created: ${createdAt}; ${acceptedByClient}${acceptedByTalent}${interviewScheduled}`}
        />
      </ListItemButton>
    </ListItem>
  );
}


const MatchesList = ({ matches }) => {
  return (
    <List
      component="nav"
      sx={{ padding: 0 }}
      subheader={
        <ListSubheader component="div" id="nested-list-subheader">
          Matches
        </ListSubheader>
      }
    >
      {matches.map((match, index) => <MatchListItem key={match._id} match={match} />)}
    </List>
  );
}

const MatchingGrid = ({ data, handleShowDetails }) => {
  // const [data, setData] = useState([]);
  // const feedback = useFeedback();
  const [selectedData, setSelectedData] = useState({});

  // const handleRowDoubleClick = ({ _id }) => handleShowDetails(id);

  const selectedClient = useMemo(() => {
    if (!selectedData.clientId)
      return null;
    return data.find(x => x._id === selectedData.clientId);
  }, [selectedData, data]);

  const selectedJob = useMemo(() => {
    if (!selectedData.jobId)
      return null;
    return selectedClient.jobs.find(x => x._id === selectedData.jobId);
  }, [selectedData, selectedClient]);

  const handleShowJobDetails = clientId => jobId => {
    console.log("show job details", clientId, jobId);
    setSelectedData({ clientId, jobId });
  }

  console.log({ selectedData, selectedClient, selectedJob });
  return (
    <Box sx={{ height: 'calc(100vh - 128px)', width: '100%' }}>
      {selectedJob &&
        <SimpleDialog title={selectedJob?.position.name} open={!!selectedJob} onClose={() => setSelectedData({})} maxWidth="lg" fullWidth scroll="body">
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <ClientFields client={selectedClient} />
              <AddMatch jobId={selectedJob?._id} />
            </Grid>
            <Grid item xs={12} md={6}><JobDetails showEdit={false} job={selectedJob} /></Grid>
            <Grid item xs={12} md={12}>
              <Divider />
              <MatchesList matches={selectedJob?.matches || []} />
            </Grid>
          </Grid>
        </SimpleDialog>
      }
      <DataGrid
        data={data}
        columns={cols}
        // onRowDoubleClick={handleRowDoubleClick}
        renderDetailPanel={({ row }) => (
          <JobsGrid
            data={row.original}
            sx={{ width: 'calc(100vw - 160px)', height: "auto" }}
            enableBottomToolbar={false}
            enableTopToolbar={false}
            handleShowDetails={handleShowJobDetails(row.original._id)}
          />)
        }
      />
    </Box>
  );
}

export default MatchingGrid;