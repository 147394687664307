import { gql } from '@apollo/client';

const POSITIONS = gql`
  query GetPositions {
    positions {
      _id
      name
      helperText
      minBudget
      questions {
        _id
        title
        text
        preparationTime
        recordingTime
        canRerecord
      }
      commissions {
        minValue
        helperText
        commissionType {
          _id
          name
          hasQuantity
        }
        unit
      }
    }
  }
`;

export default POSITIONS;