import React from 'react';

import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { useQuery, gql } from '@apollo/client';
import ApplicationForm from './TalentForm';
import { CORE_TALENT_FIELDS } from '../../gql/fragments/talent.fragment';

const ME = gql`
  ${CORE_TALENT_FIELDS}
  query GetMe {
    me {
      _id
      talent {
        ...CoreTalentFields
      }
    }
  }
`;

const EditTalentProfile = () => {
  const { error, loading, data } = useQuery(ME);
  if (loading)
    return 'Loading...';
  if (error)
    return 'Error ' + error;
  return (
    <Container maxWidth="md">
      <Box
        sx={{
          marginTop: 4,
          marginBottom: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <ApplicationForm userId={data?.me?._id} data={data?.me?.talent} redirect="/account" />
      </Box>
    </Container>
  );
}

export default EditTalentProfile;