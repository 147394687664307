import React, { useMemo, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Chip from '@mui/material/Chip';
import { useFeedback } from '../feedback/Service';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

// const names = [
//   'Oliver Hansen',
//   'Van Henry',
//   'April Tucker',
//   'Ralph Hubbard',
//   'Omar Alexander',
//   'Carlos Abbott',
//   'Miriam Wagner',
//   'Bradley Wilkerson',
//   'Virginia Andrews',
//   'Kelly Snyder',
// ];

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

export default function MultipleSelectChip({ items: initialItems = [], label, name, value = [], onChange, valueField = "_id", labelField = "name", fullWidth = false, required = false, otherOption }) {
  const [options, setOptions] = useState([]);
  const theme = useTheme();
  const feedback = useFeedback();
  let items = useMemo(() => {
    const notInInitialItems = value.filter(x => !initialItems.find(y => y[valueField] === x) && !options.find(y => y[valueField] === x)).map(x => ({ [valueField]: x, [labelField]: x }));
    // console.log({ initialItems, notInInitialItems, options });
    let result = [...initialItems, ...notInInitialItems, ...options];
    if (otherOption)
      result.push({
        [valueField]: "Other",
        [labelField]: "Other"
      });
    return result;
  }, [initialItems, otherOption, valueField, labelField, options, value]);

  const handleChange = async e => {
    const { target: { value: newValue } } = e;
    const otherSelected = !value.includes("Other") && newValue.includes("Other");
    // console.log(newValue);
    if (!otherOption || !otherSelected)
      return onChange(e);
    const newTarget = { ...e.target };
    const option = await feedback.form({ title: "Other Option", input: { title: "Value" } });
    if (!option)
      return;
    // console.log({ option });
    setOptions(x => [...x, { [valueField]: option, [labelField]: option }]);
    const index = newValue.findIndex(x => x === "Other");
    // console.log({ newTarget });
    newTarget.value[index] = option;
    onChange({ target: newTarget });
  };

  return (
    <div>
      <FormControl fullWidth={fullWidth} required={required}>
        <InputLabel id={"multiple-chip-label" + label}>{label}</InputLabel>
        <Select
          labelId={"multiple-chip-label" + label}
          // id="demo-multiple-chip"
          multiple
          value={value}
          name={name}
          onChange={handleChange}
          input={<OutlinedInput id="select-multiple-chip" label={label} />}
          renderValue={(selected) => (
            <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
              {selected.map(id => (
                <Chip key={id} label={items.find(x => x[valueField] === id)[labelField]} sx={{ m: '2px' }} />
              ))}
            </Box>
          )}
          MenuProps={MenuProps}
        >
          {items.map(item => (
            <MenuItem
              key={item[valueField]}
              value={item[valueField]}
              style={getStyles(item[valueField], value, theme)}
            >
              {item[labelField]}
            </MenuItem>
          ))}
          {/* {otherOption &&
            <MenuItem
              value={"Other"}
              style={getStyles("Other", value, theme)}
            >
              {"Other"}
            </MenuItem>
          } */}
        </Select>
      </FormControl>
    </div>
  );
}