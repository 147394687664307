import React, { useState } from 'react';
import Paper from '@mui/material/Paper';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import CircularProgress from '@mui/material/CircularProgress';
import { useQuery, useMutation, gql } from '@apollo/client';
import POSITIONS from '../../../gql/common/positions.query';
import EditPositionDialog from './EditPositionDialog';
import IconButton from '@mui/material/IconButton';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/DeleteForever';
import { useFeedback } from '../../feedback/Service';
import { parseGraphQLError } from '../../../utils';

const DELETE_POSITION = gql`
  mutation deletePosition($_id: String!) {
    deletePosition(_id: $_id)
  }
`;

const Positions = () => {
  const { loading, data, error } = useQuery(POSITIONS);
  const [editItem, setEditItem] = useState(null);
  const feedback = useFeedback();
  const [remove, { loading: deleting }] = useMutation(DELETE_POSITION, {
    update(cache, { data: { deletePosition } }) {
      console.log("deletePosition", deletePosition, cache);
      cache.modify({
        fields: {
          positions(current, { readField }) {
            console.log("current", current, deletePosition)
            return current.filter(x => deletePosition !== readField('_id', x));
          },
        },
      });
    }
  });

  const handleItemClick = position => () => setEditItem(position);

  const handleDeleteClick = id => async e => {
    e.stopPropagation();
    const confirm = await feedback.confirm({ title: "Remove position?" });
    if (!confirm)
      return;
    try {
      await remove(({ variables: { _id: id } }));
      feedback.snackbar({ text: "Position removed!", type: "success" });
    } catch (error) {
      console.error("Delete position error", error);
      feedback.snackbar({ text: parseGraphQLError(error), type: "error" });
    }
  }

  if (error) return "Error getting positions";
  if (loading) return <CircularProgress />;
  const { positions } = data;
  return (
    <Paper sx={{ p: 0, display: 'flex', flexDirection: 'column', mt: 4, mb: 4 }}>
      <EditPositionDialog open={!!editItem} onClose={() => setEditItem(null)} data={editItem} />
      <Fab color="primary" aria-label="add" sx={{ position: "fixed", right: 8, bottom: 8 }} onClick={() => setEditItem({})}>
        <AddIcon />
      </Fab>
      <List>
        {positions.map(position => (
          <ListItem
            disablePadding
            key={position._id}
            onClick={handleItemClick(position)}
            secondaryAction={deleting ? <CircularProgress /> :
              <IconButton edge="end" aria-label="delete" color="error" onClick={handleDeleteClick(position._id)}>
                <DeleteIcon />
              </IconButton>
            }
          >
            <ListItemButton>
              <ListItemText primary={position.name} secondary={position.helperText + ` (Min budget: $${position.minBudget})`} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Paper>
  );
}

export default Positions;