import { gql } from '@apollo/client';

export const CORE_JOB_FIELDS = gql`
  fragment CoreJobFields on Job {
    _id
    position {
      _id
      name
    }
    experience
    jobDescription
    salesCount
    industries
    businesses
    products
    budget {
      from
      to
    }
    commissions {
      value
      unit
      type
      quantity
    }
    dealSize { from to }
    contractCycle
    comment
    level
    skills
    engagement
    startTime
    endTime
    closed
    createdAt
  }
`;