import React, { useReducer, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import LoadingButton from '@mui/lab/LoadingButton';
import { useMutation, gql } from '@apollo/client';
import { useFeedback } from '../feedback/Service';
import UrlInput from '../inputs/UrlInput';
import { socialMediaDictionary } from '../../dictionaries/social';
import IndustrySelect from '../inputs/IndustrySelect';
import FundingSelect from '../inputs/FundingSelect';
import RevenueSelect from '../inputs/RevenueSelect';
import EmployeesNumberSelect from '../inputs/EmployeesNumberSelect';
import { useNavigate } from 'react-router-dom';
import config from '../../config';
import { parseGraphQLError } from '../../utils';

const CREATE_CLIENT = gql`
  mutation CreateClient($client: ClientInput!) {
    createClient(client: $client) {
      _id
    }
  }
`;

let initialState = {
  company: "",
  comment: "",
  website: "",
  funding: "",
  revenue: "",
  industry: "",
  culture: "",
  linkedin: "",
  facebook: "",
  twitter: "",
  instagram: "",
  customLink: "",
  employeesNumber: "",
  salesNumber: "",
}

if (config.useTestFormData) {
  initialState = {
    ...initialState,
    company: "Cavalry",
    comment: "",
    website: "www.hirecavalry.com",
    funding: "Series A",
    revenue: "$100,000 - $300,000",
    industry: "IT",
    culture: "punk",
    linkedin: "",
    facebook: "",
    twitter: "",
    instagram: "cavalry",
    customLink: "www.hirecavalry.com",
    employeesNumber: "",
    salesNumber: "",
  }
}

const numericFields = [];

const reducer = (state, newState) => ({
  ...state,
  ...newState
})


const ClientForm = ({ userId, data: initialData, redirect }) => {
  const [state, setState] = useReducer(reducer, initialState);
  const feedback = useFeedback();
  const navigate = useNavigate();
  const [submit, { loading }] = useMutation(CREATE_CLIENT, {
    update(cache, { data: { createClient } }) {
      cache.modify({
        id: cache.identify({ _id: userId, __typename: "User" }),
        fields: {
          client: () => createClient,
        }
      });
    }
  });

  const handleChange = ({ target: { name, value, checked, type } }) => {
    setState({ [name]: type === "checkbox" ? checked : value /* (type === "number" ? parseFloat(value) : value) */ });
  }

  const handleSubmit = async e => {
    e.preventDefault();
    try {
      const data = { ...state };
      for (let field of numericFields) {
        if (typeof data[field] === "object") {
          data[field] = { from: parseFloat(data[field].from), to: parseFloat(data[field].to) };
          if (data[field].from > data[field].to)
            return;
        } else
          data[field] = parseFloat(data[field]);
      }
      console.log(data);
      await submit(({ variables: { client: data } }));
      if (!initialData?._id) {
        window.gtag('event', 'client_profile', {});
      }
      feedback.snackbar({ text: "Form submitted!", type: "success" });
      if (redirect)
        navigate(redirect);
    } catch (error) {
      console.error("ClientForm error", error);
      feedback.snackbar({ text: parseGraphQLError(error), type: "error" });
    }
    // console.log(talent);
  }

  useEffect(() => {
    if (!initialData)
      return;
    // const dealSize = dealSizeData.find(({ value }) => value.from === initialData.dealSize.from && value.to === initialData.dealSize.to).value;
    setState({ ...initialData, __typename: undefined, jobs: undefined });
  }, [initialData]);


  return (
    <Box component="form" onSubmit={handleSubmit} sx={{ width: '100%' }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField fullWidth required value={state.company} onChange={handleChange} name="company" label="What's your company name?" />
        </Grid>
        <Grid item xs={12} md={6}>
          <UrlInput fullWidth required value={state.website} onChange={handleChange} name="website" label="Website URL" baseUrl="https://" />
        </Grid>
        <Grid item xs={12} md={6}>
          <UrlInput fullWidth value={state.linkedin} onChange={handleChange} name="linkedin" label="LinkedIn" baseUrl={socialMediaDictionary.linkedin.baseUrl} />
        </Grid>
        <Grid item xs={12} md={6}>
          <IndustrySelect fullWidth required value={state.industry} onChange={handleChange} name="industry" label="Industry" />
        </Grid>
        <Grid item xs={12} md={6}>
          <FundingSelect fullWidth required value={state.funding} onChange={handleChange} name="funding" label="Funding" />
        </Grid>
        <Grid item xs={12} md={6}>
          <RevenueSelect fullWidth required value={state.revenue} onChange={handleChange} name="revenue" label="Revenue (per month)" />
        </Grid>
        <Grid item xs={12} md={6}>
          <EmployeesNumberSelect fullWidth required value={state.employeesNumber} onChange={handleChange} name="employeesNumber" label="Number of employees" />
        </Grid>
        <Grid item xs={12} md={6}>
          <EmployeesNumberSelect fullWidth required value={state.salesNumber} onChange={handleChange} name="salesNumber" label="Number of employees in sales team" />
        </Grid>
        {/* <Grid item xs={12}>
          <TextField fullWidth multiline rows={5} value={state.culture} onChange={handleChange} name="culture" label="Culture (explain your company principles and mission)" />
        </Grid> */}
        {/* <Grid item xs={12} md={6}>
          <UrlInput fullWidth value={state.twitter} onChange={handleChange} name="twitter" label="Twitter" baseUrl={socialMediaDictionary.twitter.baseUrl} />
        </Grid>
        <Grid item xs={12} md={6}>
          <UrlInput fullWidth value={state.facebook} onChange={handleChange} name="facebook" label="Facebook" baseUrl={socialMediaDictionary.facebook.baseUrl} />
        </Grid>
        <Grid item xs={12} md={6}>
          <UrlInput fullWidth value={state.instagram} onChange={handleChange} name="instagram" label="Instagram" baseUrl={socialMediaDictionary.instagram.baseUrl} />
        </Grid>
        <Grid item xs={12} md={12}>
          <UrlInput fullWidth value={state.customLink} onChange={handleChange} name="customLink" label="Additional Link" baseUrl={"https://"} helperText="Any other link that helps to get more information about your company (blog, article, media, etc.)" />
        </Grid> */}
        <Grid item xs={12}>
          <TextField fullWidth multiline rows={5} value={state.comment} onChange={handleChange} name="comment" label="Company description" />
        </Grid>
      </Grid>
      <LoadingButton type="submit" size="large" variant="contained" sx={{ mt: 2, mb: 2 }} loading={loading}>
        Submit
      </LoadingButton>
    </Box>
  );
}

export default ClientForm;