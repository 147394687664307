import React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Grid from '@mui/material/Grid';
import { Box, Divider, IconButton, TextField, Button, FormControlLabel, Checkbox } from '@mui/material';
import { Add } from '@mui/icons-material';

const defaultItem = {
  title: '',
  text: '',
  canRerecord: false,
  preparationTime: 60,
  recordingTime: 60,
}

const numericFields = ['preparationTime', 'recordingTime'];

const QuestionItem = ({ item, onItemChange, index }) => {
  const handleChange = ({ target: { value, type, checked, name } }) => {
    console.log(name, value, index);
    if (numericFields.includes(name))
      value = value ? parseInt(value) : null;
    onItemChange(index)({ ...item, [name]: type === "checkbox" ? checked : value });
  }
  return (
    <Accordion defaultExpanded={!item.title}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography>{item.title}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} lg={4}>
            <TextField
              required
              name="title"
              label="Title"
              fullWidth
              value={item.title}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={2}>
            <TextField
              required
              type='number'
              id="preparationTime"
              name="preparationTime"
              label="Preparation time, sec"
              fullWidth
              value={item.preparationTime ?? ''}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={2}>
            <TextField
              required
              type='number'
              id="recordingTime"
              name="recordingTime"
              label="Recording time, sec"
              fullWidth
              value={item.recordingTime ?? ''}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} lg={3}>
            <FormControlLabel
              control={<Checkbox color="primary" checked={item.canRerecord} onChange={handleChange} name="canRerecord" />}
              label="Can be re-recorded later"
              name="canRerecord"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              required
              name="text"
              label="Text"
              fullWidth
              value={item.text}
              onChange={handleChange}
              multiline
              rows={4}
            />
          </Grid>
          <Grid item xs={12}>
            <Button variant="contained" color="error" onClick={() => onItemChange(index)(null)}>Remove</Button>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion >
  );
}

const PositionQuestions = ({ data: items, onChange }) => {
  console.log(items);

  const handleItemChange = index => item => {
    console.log(index, item);
    const newItems = [...items];
    newItems[index] = item;
    onChange(newItems.filter(Boolean));
  }

  return (
    <div>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <Typography variant="h6" gutterBottom>Video Interview Questions </Typography>
        <IconButton aria-label="add" size="small" onClick={() => onChange([...items, { ...defaultItem }])}>
          <Add />
        </IconButton>
      </Box>
      <br />
      {items.map((item, index) => <QuestionItem key={index} item={item} onItemChange={handleItemChange} index={index} />)}
      {items.length === 0 && <Typography variant="body2" gutterBottom>No questions added yet</Typography>}
      <br />
      <Divider />
      <br />
    </div>

  );
}

export default PositionQuestions;