import React, { useState } from 'react';
import Avatar from '@mui/material/Avatar';
import LoadingButton from '@mui/lab/LoadingButton';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import MuiLink from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';
import ME from '../../gql/common/me.query';
import { useFeedback } from '../feedback/Service';
import { useMutation, gql, useQuery } from '@apollo/client';
import { AUTH_TOKEN_NAME } from '../../constants';
import Password from './Password';
import { parseGraphQLError } from '../../utils';
import GoogleLoginButton from './GoogleLoginButton';
import config from '../../config';

const REGISTER = gql`
  mutation Register($user: NewUser!) {
    register(user: $user)
  }
`;

export default function SignUpForm({ isTalent = false }) {
  const [register, { loading }] = useMutation(REGISTER);
  const { data: currentUser, refetch: getUser } = useQuery(ME);
  const [googleCredentials, setGoogleCredentials] = useState(null);
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [terms, setTerms] = useState(false);
  const [isUsBased, setIsUsBased] = useState(!isTalent);
  const feedback = useFeedback();

  const handleSubmit = async (event) => {
    event.preventDefault();
    const leadType = new URLSearchParams(window.location.search).get('lt');
    const data = new FormData(event.currentTarget);
    const user = {
      email,
      password: data.get('password'),
      firstName,
      lastName,
      newsletter: !!data.get('allowExtraEmails'),
      googleCredentials,
      leadType
    };
    try {
      const { data: { register: token } } = await register(({ variables: { user } }));
      localStorage.setItem(AUTH_TOKEN_NAME, token);
      console.log(token);
      getUser({ fetchPolicy: 'network-only' });
    } catch (error) {
      console.warn("Register error", error);
      feedback.snackbar({ text: parseGraphQLError(error), type: "error" });
    }
  };

  const handleGoogleLogin = async (credentials) => {
    if (!credentials.clientId)
      credentials.clientId = config.googleClientId;
    setGoogleCredentials(credentials);
    const { email, family_name, given_name } = await fetch(`https://www.googleapis.com/oauth2/v3/tokeninfo?id_token=${credentials.credential}`).then(res => res.json());
    // console.log({ userData });
    setEmail(email);
    setFirstName(given_name);
    setLastName(family_name);
  }

  // console.log({ googleCredentials, currentUser, config })

  return (
    <>
      <Avatar sx={{ m: 1, bgcolor: 'primary.main' }}>
        <LockOutlinedIcon />
      </Avatar>
      <Typography component="h1" variant="h5">
        Sign up
      </Typography>
      <Box component="form" onSubmit={handleSubmit} sx={{ mt: 3 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              autoComplete="fname"
              name="firstName"
              required
              fullWidth
              id="firstName"
              label="First Name"
              autoFocus
              value={firstName}
              onChange={({ target: { value } }) => setFirstName(value)}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              fullWidth
              id="lastName"
              label="Last Name"
              name="lastName"
              autoComplete="lname"
              value={lastName}
              onChange={({ target: { value } }) => setLastName(value)}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              disabled={!!googleCredentials}
              value={email}
              onChange={({ target: { value } }) => setEmail(value)}
            />
          </Grid>
          <Grid item xs={12}>
            <Password
              required
              fullWidth
              name="password"
              label="Password"
              id="password"
              autoComplete="current-password"
              helperText="Password must be at least 6 characters long"
            />
          </Grid>
          {isTalent &&
            <Grid item xs={12}>
              <FormControlLabel
                control={<Checkbox color="primary" checked={isUsBased} onChange={({ target: { checked } }) => setIsUsBased(checked)} />}
                label={"Are you legally authorized to work in the United States?"}
                name="isUsBased"
              />
            </Grid>
          }
          <Grid item xs={12}>
            <FormControlLabel
              control={<Checkbox color="primary" checked={terms} onChange={({ target: { checked } }) => setTerms(checked)} />}
              label={<>
                By signing up you agree to our <MuiLink href="https://www.hirecavalry.com/terms-of-service" target="_blank">Terms of Service</MuiLink> and <MuiLink href="https://www.hirecavalry.com/privacy-policy" target="_blank">Privacy Policy</MuiLink>
              </>}
              name="terms"
            />
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={<Checkbox color="primary" value={true} />}
              label="I want to receive inspiration, marketing promotions and updates via email."
              name="allowExtraEmails"
            />
          </Grid>
        </Grid>
        {(!googleCredentials && !currentUser && config.enableGoogleLogin) &&
          <Box sx={{ mt: 2, mb: 0 }}>
            <GoogleLoginButton onSuccess={handleGoogleLogin} />
          </Box>
        }
        <LoadingButton type="submit" fullWidth variant="contained" sx={{ mt: 2, mb: 2 }} loading={loading} disabled={!terms || !isUsBased}>
          Sign Up
        </LoadingButton>
        <Grid container justifyContent="flex-end">
          <Grid item>
            <MuiLink variant="body2" to="/sign-in" component={Link}>
              Already have an account? Sign in
            </MuiLink>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}