import React from 'react';
import MultipleSelectChip from './MultipleSelectChip';

export const data = [
  { name: "Executive" },
  { name: "Director" },
  { name: "Manager" },
  { name: "Individual Contributor" },
  { name: "Consumer" },
];

const LevelSelect = ({ ...props }) => {
  return (<MultipleSelectChip items={data} {...props} valueField="name" otherOption />);
}

export default LevelSelect;