import React, { useMemo } from 'react';
import { gql, useMutation, useQuery } from '@apollo/client';
import { useEffect } from 'react';
import { loaderVar } from '../../../vars';
import TalentsGrid from './TalentsGrid';
import { CORE_TALENT_FIELDS } from '../../../gql/fragments/talent.fragment';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { useFeedback } from '../../feedback/Service';
import TalentProfile from '../../talents/TalentProfile';
import { CORE_DESIRED_POSITION_FIELDS } from '../../../gql/fragments/desiredPosition.fragment';
import { useParams } from 'react-router';
import TalentPositionsGrid from './TalentPositionsGrid';
import Typography from '@mui/material/Typography';
import { RECORDING_STATES } from '../../../constants';
import VideoJS from '../../VideoJs';
import { getRecordingUrl, parseGraphQLError } from '../../../utils';
import TalentPositionDetails from '../../talents/TalentPositionDetails';
import LoadingButton from '@mui/lab/LoadingButton';

const getVideoJsOptions = id => ({
  autoplay: false,
  controls: true,
  responsive: true,
  fluid: true,
  sources: [{
    src: getRecordingUrl(id),
    type: 'video/mp4'
  }]
});

const TALENTS = gql`
  ${CORE_TALENT_FIELDS}
  ${CORE_DESIRED_POSITION_FIELDS}
  query GetTalents {
    talents {
      ...CoreTalentFields
      desiredPositions {
        ...CoreDesiredPositionFields
      }
      user {
        _id
        email
        firstName
        lastName
        leadType
      }
      interview {
        answers {
          recording {
            _id
            state
          }
          question {
            _id
            text
            title
          }
        }
      }
    }
  }
`;

const APPROVE = gql`
  mutation approveTalent($_id: String!) {
    approveTalent(_id: $_id) {
      _id
      approved
    }
  }
`;

const DISAPPROVE = gql`
  mutation disapproveTalent($_id: String!) {
    disapproveTalent(_id: $_id) {
      _id
      approved
    }
  }
`;

const Talents = () => {
  const { loading, error, data } = useQuery(TALENTS, { pollInterval: 5000 });
  const feedback = useFeedback();
  // const navigate = useNavigate();
  const { id } = useParams();

  const selectedTalent = useMemo(() => {
    if (!data || !id || error)
      return null;
    const { talents } = data;
    return talents.find(x => x._id === id);
  }, [data, error, id]);

  const [toggleApprove, { loading: approving }] = useMutation(selectedTalent?.approved ? DISAPPROVE : APPROVE, {
    update(cache, { data: { approveTalent, disapproveTalent } }) {
      console.log("approveTalent", approveTalent, disapproveTalent, cache);
      // cache.modify({
      //   fields: {
      //     positions(current, { readField }) {
      //       console.log("current", current, deletePosition)
      //       return current.filter(x => deletePosition !== readField('_id', x));
      //     },
      //   },
      // });
    }
  });

  const answers = useMemo(() => {
    if (!selectedTalent)
      return [];
    return selectedTalent.interview?.answers || [];
  }, [selectedTalent]);

  const handleToggleApproveClick = async e => {
    e.stopPropagation();
    // const confirm = await feedback.confirm({ title: "Remove position?" });
    // if (!confirm)
    //   return;
    try {
      await toggleApprove(({ variables: { _id: selectedTalent._id } }));
      feedback.snackbar({ text: !selectedTalent.approved ? "Approved" : "Disapproved", type: "success" });
    } catch (error) {
      console.error("Toggle approve error", error);
      feedback.snackbar({ text: parseGraphQLError(error), type: "error" });
    }
  }

  const handleShowTalent = id => {
    // navigate("/admin/talents/" + id);
    const win = window.open("/admin/talents/" + id, '_blank');
    win.focus();
  }

  const handleShowTalentPosition = positionId => {
    const talent = data.talents.find(x => x._id === id);
    const position = talent.desiredPositions.find(x => x._id === positionId);
    console.log(position);
    feedback.alert({ title: "Desired position: " + position.position.name, component: <TalentPositionDetails position={position} />, maxWidth: "md", fullWidth: true, clickAwayClose: true });
  }

  useEffect(() => {
    loaderVar(loading ? "query" : null);
  }, [loading]);

  if (error)
    return <div>{error.message}</div>

  if (!data)
    return null;

  if (id && !selectedTalent) 
    return (<Typography sx={{ mt: 6 }} variant="h6">Talent not found.</Typography>);

  return (
    <Paper sx={{ p: 0, display: 'flex', flexDirection: 'column', mt: 4, mb: 4 }}>
      {selectedTalent ?
        <>
          <Box sx={{ p: 2 }}>
            <Grid container>
              <Grid item xs={12} md={6}>
                <Typography variant="h5">{selectedTalent.user.firstName} {selectedTalent.user.lastName}</Typography>
                <Typography variant="h6" gutterBottom>{selectedTalent.user.email}</Typography>
                <TalentProfile talent={selectedTalent} />
                <LoadingButton sx={{ mt: 1 }} loading={approving} onClick={handleToggleApproveClick} variant="contained" color={selectedTalent.approved ? "error" : "success"}>{selectedTalent.approved ? "Disapprove" : "Approve"}</LoadingButton>
              </Grid>
              <Grid item xs={12} md={6}>
                {answers.map(({ question, recording }) => (
                  <React.Fragment key={recording._id}>
                    {recording.state === RECORDING_STATES.READY ?
                      <VideoJS options={getVideoJsOptions(recording._id)} /> :
                      <Typography variant="h6">Recording state: {recording.state}</Typography>
                    }
                    <Typography sx={{ whiteSpace: 'pre-line' }}>{question.text}</Typography>
                  </React.Fragment>
                ))}
              </Grid>
            </Grid>
          </Box>
          <TalentPositionsGrid data={selectedTalent?.desiredPositions} handleShowDetails={handleShowTalentPosition} />
        </> :
        <TalentsGrid data={data} handleShowDetails={handleShowTalent} />
      }
    </Paper>
  )
}

export default Talents;