import React from 'react';

import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { useParams } from 'react-router';
import VideoInterview from './VideoInterview';

const VideoInterviewPage = () => {
  const { id } = useParams();

  return (
    <Container maxWidth={false}>
      <Box
        sx={{
          marginTop: 4,
          marginBottom: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <VideoInterview desiredPositionId={id} maxWidth={false} />
      </Box>
    </Container>
  );
}

export default VideoInterviewPage;