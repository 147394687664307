import React from 'react';
import MuiLink from '@mui/material/Link';
import Typography from '@mui/material/Typography';

const Support = () => {

  const handleOpenChat = e => {
    e.preventDefault();
    window.Intercom('show');
  }

  return (
    <>
      <Typography>
        If you have any questions or need help, please contact us via the <MuiLink href="#" onClick={handleOpenChat}>live chat</MuiLink> or at <MuiLink href="mailto:support@hirecavalry.com" target="_blank" rel="noreferrer">support@hirecavalry.com</MuiLink>
      </Typography>
    </>
  );
}

export default Support;