import React from 'react';
import CustomSelect from './CustomSelect';
import { data } from "./IndustriesSelect";

const IndustrySelect = ({ ...props }) => {
  return (
    <CustomSelect items={data} {...props} valueField="name" otherOption />
  )
}

export default IndustrySelect;