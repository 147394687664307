import React from 'react';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import { formatCommissions, formatTimeRange, parseGraphQLError } from '../../utils';
import SuggestedTalents from '../admin/clients/SuggestedTalents';
import { Link } from 'react-router-dom';
import { useFeedback } from '../feedback/Service';
import { useMutation, gql } from '@apollo/client';
import { CORE_JOB_FIELDS } from '../../gql/fragments/job.fragment';
import FieldValue from '../FieldValue';

const DELETE_JOB = gql`
  ${CORE_JOB_FIELDS}
  mutation deleteJob($_id: String!) {
    deleteJob(_id: $_id) {
      _id
      jobs {
        ...CoreJobFields
      }
    }
  }
`;

const JobDetails = ({ job, showSuggestions, showEdit }) => {
  const feedback = useFeedback();

  const [remove, { loading: deleting }] = useMutation(DELETE_JOB, {
    update(cache, { data: { deleteJob: client } }) {
      console.log("deleteJob result", client);
      cache.modify({
        fields: {
          jobs(current, { readField }) {
            console.log("current", current)
            return current.filter(x => job._id !== readField('_id', x));
          },
        },
      });
    }
  });

  const handleDeleteClick = async e => {
    e.stopPropagation();
    const confirm = await feedback.confirm({ title: "Remove talent request?" });
    if (!confirm)
      return;
    try {
      await remove(({ variables: { _id: job._id } }));
      feedback.snackbar({ text: "Request removed!", type: "success" });
    } catch (error) {
      console.error("Delete request error", error);
      feedback.snackbar({ text: parseGraphQLError(error), type: "error" });
    }
  }

  if (!job)
    return null;
  return (
    <>
      <FieldValue label="Link to the job description" url={"//" + job.jobDescription} value={job.jobDescription || "Not provided"} />
      <FieldValue label="Sales to hire" value={job.salesCount} />
      {/* <FieldValue label="Looking for position(s)" value={job.position.name} /> */}
      <FieldValue label="Desired candidate experience" value={job.experience + " years"} />
      <FieldValue label="Average deal size" value={job.dealSize} range />
      <FieldValue label="Average contract cycle" value={job.contractCycle} />
      <FieldValue label="Customer segments" value={job.businesses.join(", ")} />
      <FieldValue label="Products" value={job.products.join(", ")} />
      <FieldValue label="Target customer level" value={job.level.join(", ")} />
      <FieldValue label="Skills" value={job.skills.join(", ")} />
      <FieldValue label="Budget per engagement (per month)" value={job.budget} range />
      <FieldValue label="Performance-based commissions" value={formatCommissions(job.commissions)} />
      <FieldValue label="Preferred engagement" value={(job.engagement || []).join(", ")} />
      <FieldValue label="Working hours" value={formatTimeRange(job.startTime, job.endTime)} />
      <FieldValue label="Comment" value={job.comment} />
      {showEdit && <Button variant="outlined" sx={{ mt: 1 }} component={Link} to={"/account/client/request/" + job._id}>Edit</Button>}
      {showEdit && <LoadingButton variant="outlined" sx={{ mt: 1, ml: 1 }} loading={deleting} onClick={handleDeleteClick}>Remove</LoadingButton>}
      {showSuggestions &&
        <>
          <br />
          <Typography variant="h6">Suggested Talent</Typography>
          <SuggestedTalents job={job} />
        </>
      }
    </>
  );
}

export default JobDetails;