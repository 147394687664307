import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from './components/Home';
import MainLayout from './components/MainLayout';
// import { Provider } from "mobx-react";
import { createTheme, ThemeProvider } from '@mui/material/styles';
// import configureStore from "./store/appStore";
import ErrorBoundary from './components/ErrorBoundary';
import { FeedbackProvider } from './components/feedback/Service';
import { ApolloClient, InMemoryCache, /* createHttpLink */ } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { ApolloProvider } from '@apollo/client/react';
import config from './config';
import SignIn from './components/auth/SignIn';
import SignUpPage from './components/auth/SignUpPage';
import Users from './components/admin/users/Users';
import Talents from './components/admin/talents/Talents';
import Clients from './components/admin/clients/Clients';
import { AUTH_TOKEN_NAME } from './constants';
import TalentSignUp from './components/talents/TalentSignUp';
import EditTalentProfile from './components/talents/EditTalentProfile';
import EditTalentPosition from './components/talents/EditTalentPosition';
import { createUploadLink } from 'apollo-upload-client';
import ResetPassword from './components/auth/ResetPassword';
import ClientSignUp from './components/clients/ClientSignUp';
import Account from './components/Account';
import Rerecord from './components/talents/Rerecord';
import TalentPage from './components/talents/TalentPage';
import Positions from './components/admin/positions/Positions';
import EditClientProfile from './components/clients/EditClientProfile';
import EditJob from './components/clients/EditJob';
import CommissionTypes from './components/admin/commission-types/CommissionTypes';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import * as Sentry from "@sentry/react";
import { CaptureConsole } from '@sentry/integrations';
import Matching from './components/admin/clients/Matching';
import VideoInterviewPage from './components/talents/VideoInterviewPage';
import { GoogleOAuthProvider } from '@react-oauth/google';

// const store = configureStore();

console.log("Cavalry app, version", config.version);

if (config.sentryDsn) {
  Sentry.init({
    dsn: config.sentryDsn,
    release: "cavalry@" + config.version,
    integrations: [
      new CaptureConsole({
        levels: ['error'],
      }),
    ],
  });
}

const httpLink = createUploadLink({
  uri: config.apiUrl + "/graphql",
});

const authLink = setContext((_, { headers }) => {
  // console.warn("authLink", localStorage.getItem('AUTH_TOKEN_NAME'), _);
  const token = localStorage.getItem(AUTH_TOKEN_NAME);
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    }
  }
});

const client = new ApolloClient({
  // uri: config.apiUrl + "/graphql",
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
  // headers: {
  //   authorization: "Token " + localStorage.getItem("AUTH_TOKEN_NAME"),
  // }
});

const palette = {
  primary: {
    main: "#3b3bcd",
    // secondary: "",
  },
};
const theme = createTheme({ palette });

const App = () => {
  return (
    <ErrorBoundary>
      <GoogleOAuthProvider clientId={config.googleClientId}>
        <ApolloProvider client={client}>
          {/* <Provider store={store}> */}
          <ThemeProvider theme={theme}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <BrowserRouter>
                <FeedbackProvider>
                  <MainLayout>
                    <Routes>
                      <Route path="/" element={<Home />} />
                      <Route path="/sign-in" element={<SignIn />} />
                      <Route path="/sign-up" element={<SignUpPage />} />
                      <Route path="/reset-password" element={<ResetPassword />} />
                      <Route path="/admin/users" element={<Users />} />
                      <Route path="/admin/positions" element={<Positions />} />
                      <Route path="/admin/commission-types" element={<CommissionTypes />} />
                      <Route path="/admin/clients" element={<Clients />}>
                        <Route path="/admin/clients/:id" element={<Clients />} />
                      </Route>
                      <Route path="/admin/matching" element={<Matching />}>
                        <Route path="/admin/matching/:id" element={<Clients />} />
                      </Route>
                      <Route path="/admin/talents" element={<Talents />}>
                        <Route path="/admin/talents/:id" element={<Talents />} />
                      </Route>
                      <Route path="/talent/sign-up" element={<TalentSignUp />} />
                      <Route path="/talent-profile/:talentId" element={<TalentPage />} />
                      <Route path="/client/sign-up" element={<ClientSignUp />} />
                      <Route path="/account" element={<Account />} />
                      <Route path="/account/client/edit-profile" element={<EditClientProfile />} />
                      <Route path="/account/talent/edit-profile" element={<EditTalentProfile />} />
                      <Route path="/account/talent/position/:id" element={<EditTalentPosition />} />
                      <Route path="/account/talent/position/:id/interview" element={<VideoInterviewPage />} />
                      <Route path="/account/client/request/:id" element={<EditJob />} />
                      <Route path="/account/client/request/add" element={<EditJob />} />
                      <Route path="/account/rerecord/:questionId" element={<Rerecord />}>
                        <Route path="/account/rerecord/:questionId/:desiredPositionId" element={<Rerecord />} />
                      </Route>
                    </Routes>
                  </MainLayout>
                </FeedbackProvider>
              </BrowserRouter>
            </LocalizationProvider>
          </ThemeProvider>
          {/* </Provider> */}
        </ApolloProvider>
      </GoogleOAuthProvider>
    </ErrorBoundary>
  );
}

export default App;
