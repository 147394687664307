import React from 'react';
import CustomSelect from './CustomSelect';

const data = [
  { name: "Less than $100,000" },
  { name: "$100,000 - $300,000" },
  { name: "$300,000 - $500,000" },
  { name: "$500,000 - $1M" },
  { name: "Over $1M" },
]

const RevenueSelect = ({ ...props }) => {
  return (
    <CustomSelect items={data} {...props} valueField="name" />
  )
}

export default RevenueSelect;