import React, { useState, useEffect } from 'react';
// import MenuItem from '@mui/material/MenuItem';
// import FormControl from '@mui/material/FormControl';
// import Select from '@mui/material/Select';
import Box from '@mui/material/Box';
import DataGrid from '../../DataGrid';
// import { formatRange } from '../../../utils';
// import { useFeedback } from '../../feedback/Service';
// import { useMutation, gql } from '@apollo/client';
// import { CORE_USER_FIELDS } from '../../../gql/fragments/user.fragment';
// import { useFeedback } from '../../feedback/Service';

// const UPDATE_USER = gql`
//   ${CORE_USER_FIELDS}
//   mutation UpdateUser($user: UserInput!) {
//     updateUser(user: $user) {
//       ...CoreUserFields
//       talent {
//         _id
//       } 
//     }
//   }
// `;

const cols = [
  { accessorKey: 'position.name', header: 'Position', size: 150, pin: true },
  { accessorKey: '_id', header: 'ID', size: 20, hide: true, enableClickToCopy: true },
  { accessorFn: ({ interview }) => interview ? interview.answers.filter(x => x.recording).length : 0, header: 'Video Answers', size: 10},
  { accessorKey: 'experience', header: 'Exp.', size: 20, filterVariant: "range" },
  { accessorKey: 'baseCompensation', header: 'Base Compensation', size: 215, filterVariant: "range" },
  { accessorKey: 'ote', header: 'OTE', size: 150, filterVariant: "range" },
  // { accessorKey: 'totalIncome', header: 'Total Income', size: 180, type: "number", valueFormatter: ({ value }) => formatRange(value) },
  // { accessorKey: 'tools', header: 'Tools', size: 500, },
];

const TalentPositionsGrid = ({ data: initialData, handleShowDetails, height, ...rest }) => {
  const [data, setData] = useState([]);
  const [editedRows, setEditedRows] = useState([]);
  // const [updateUser, { loading: isPosting }] = useMutation(UPDATE_USER);
  // const feedback = useFeedback();

  const handleRowDoubleClick = ({ _id }) => handleShowDetails(_id);

  const handleCellChangeCommitted = ({ id, field, props: { value } }) => {
    console.log(id, value, field);
    const newData = [...data].map(x => ({ ...x }));
    const index = newData.findIndex(x => x._id === id);
    console.log(newData, index);
    newData[index][field] = value;
    setData(newData);
    if (!editedRows.includes(id))
      setEditedRows(x => [...x, id]);
  }

  useEffect(() => {
    if (initialData)
      setData(initialData);
  }, [initialData]);

  return (
    <Box sx={{ height: height || 'calc(100vh - 128px)', width: '100%' }}>
      <DataGrid
        data={data}
        columns={cols}
        onEditCellChangeCommitted={handleCellChangeCommitted}
        editedRows={editedRows}
        // onSave={handleSaveChanges}
        // isPosting={isPosting}
        onRowDoubleClick={handleRowDoubleClick}
        {...rest}
      />
    </Box>
  );
}

export default TalentPositionsGrid;