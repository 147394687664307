import React, { useMemo } from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';
import List from '@mui/material/List';
// import IconButton from '@mui/material/IconButton';
// import ApproveIcon from '@mui/icons-material/CheckCircle';
// import DeclineIcon from '@mui/icons-material/Cancel';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import JobDetails from '../clients/JobDetails';
import { useFeedback } from '../feedback/Service';
import { LoadingButton } from '@mui/lab';
import { Chip, Link } from '@mui/material';
import { gql, useMutation } from '@apollo/client';
import { Cancel, CheckCircle, Pending } from '@mui/icons-material';
import { parseGraphQLError } from '../../utils';
dayjs.extend(utc);
dayjs.extend(timezone);

const MAKE_DECISION = gql`
  mutation decideTalentMatch($matchId: String!, $decision: Boolean!) {
    decideTalentMatch(matchId: $matchId, decision: $decision)
  }
`;

const DesiredPositionItem = ({ desiredPosition: item, children }) => {
  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography sx={{ flexGrow: "1" }}>{item.position.name}</Typography>
        <Chip label={(item.matches || []).filter(x => x.acceptedByTalent === null).length} color="primary" />
      </AccordionSummary>
      <AccordionDetails>
        {children}
      </AccordionDetails>
    </Accordion >
  );
}

const MatchItem = ({ match }) => {
  const [decide, { loading: isPosting }] = useMutation(MAKE_DECISION, {
    refetchQueries: [
      'GetAccount'
    ],
  });
  const { job } = match;
  const { client } = job;
  const feedback = useFeedback();

  const makeDecision = async decision => {
    if (!decision && !await feedback.confirm({ title: "Are you sure you want to decline?" }))
      return;
    try {
      await decide(({ variables: { matchId: match._id, decision: decision } }));
      feedback.snackbar({ text: "Match " + (decision ? "accepted" : "declined"), type: "success" });
    } catch (error) {
      console.error("Error making decision", error);
      feedback.snackbar({ text: parseGraphQLError(error), type: "error" });
    }
  }

  const handleApprove = () => makeDecision(true);
  const handleDecline = () => makeDecision(false);

  const handleShowJob = () => {
    feedback.alert({
      title: job.position.name + " at " + client.company, component:
        <>
          {client.website && <Typography gutterBottom>Website: <Link href={client.website} target="_blank" rel="noreferrer noopener">{client.website}</Link> </Typography>}
          <JobDetails job={job} showSuggestions={false} />
          {!match.acceptedByTalent &&
            <LoadingButton loading={isPosting} edge="end" aria-label="approve" color="success" onClick={handleApprove} variant='contained' sx={{ mt: 1.5 }}>
              Accept
            </LoadingButton>
          }
          {(match.acceptedByTalent || match.acceptedByTalent === null) &&
            <LoadingButton loading={isPosting} edge="end" aria-label="decline" color="error" onClick={handleDecline} variant='outlined' sx={{ mt: 1.5, ml: 1 }}>
              Decline
            </LoadingButton>
          }
        </>,
      scroll: "body", maxWidth: "lg", fullWidth: true, clickAwayClose: true, confirmButtonText: "Close"
    });
  }

  const label = match.acceptedByTalent === null ? "Pending" : match.acceptedByTalent ? "Accepted" : "Declined";
  const labelColor = match.acceptedByTalent === null ? "info" : match.acceptedByTalent ? "success" : "error";
  const icon = match.acceptedByTalent === null ? <Pending /> : match.acceptedByTalent ? <CheckCircle /> : <Cancel />;

  return (
    <ListItem
      key={match._id}
      disablePadding
      secondaryAction={<Chip label={label} color={labelColor} icon={icon} />}
    >
      <ListItemButton onClick={handleShowJob}>
        <ListItemText
          primary={`${client.company}`}
          secondary={`Created: ${dayjs(match.createdAt * 1).tz(dayjs.tz.guess()).format("LLL")}`}
        />
      </ListItemButton>
    </ListItem>
  );
}

const TalentMatches = ({ talent }) => {
  const desiredPositions = useMemo(() => talent.desiredPositions.filter(x => x.matches && x.matches.length > 0), [talent]);

  // console.log({ desiredPositions });

  return (
    <>
      {
        desiredPositions.map((item, index) => (
          <DesiredPositionItem key={item._id} desiredPosition={item}>
            <List sx={{ width: "100%" }}>
              {item.matches.toReversed().map(match => <MatchItem key={match._id} match={match} />)}
            </List>
          </DesiredPositionItem >
        ))
      }
    </>
  );
}

export default TalentMatches;