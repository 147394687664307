import React, { useMemo } from 'react';
import { Navigate, useParams } from 'react-router-dom';
import { useQuery, gql } from '@apollo/client';
import InterviewRecorder from './InterviewRecorder';
import Container from '@mui/material/Container';

const ME_TALENT = gql`
  query getMeTalent {
    me {
      _id
      talent {
        _id
        desiredPositions {
          _id
          position {
            _id
            name
            questions {
              _id
              title
              preparationTime
              recordingTime
              canRerecord
            }
          }
        }
      }
    }
  }
`;

const QUESTIONS = gql`
  query GetQuestions {
    questions {
      _id
      title
      # text
      preparationTime
      recordingTime
      canRerecord
    }
  }
`;

const POSITIONS = gql`
  query GetPositions {
    positions {
      _id
      name
      questions {
        _id
        title
        preparationTime
        recordingTime
        canRerecord
      }
    }
  }
`;

const Rerecord = () => {
  const { questionId, desiredPositionId } = useParams();
  const { data: questionsData } = useQuery(desiredPositionId ? POSITIONS : QUESTIONS);
  const { data: meData } = useQuery(ME_TALENT);

  const question = useMemo(() => {
    if (!questionId || !questionsData || !meData)
      return;
    if (desiredPositionId) {
      const { me: { talent: { desiredPositions } } } = meData;
      const desiredPosition = desiredPositions.find(x => x._id === desiredPositionId);
      return desiredPosition.position.questions.find(x => x._id === questionId);
    }
    const { questions } = questionsData;
    return questions.find(x => x._id === questionId);
  }, [questionsData, questionId, meData]);

  if (!question)
    return null;
  if (!meData)
    return null;
  if (!meData.me?.talent)
    return <Navigate replace to="/talent/sign-up" />
    
  return (
    <Container maxWidth="md" sx={{ mt: 4, mb: 8, display: 'flex', flexDirection: 'column', alignItems: 'center', }} >
      <InterviewRecorder question={question} redirectOnStop="/account" desiredPositionId={desiredPositionId} />
    </Container>
  );
}

export default Rerecord;