import { gql } from '@apollo/client';
import { CORE_USER_FIELDS } from '../fragments/user.fragment';

const ME = gql`
  ${CORE_USER_FIELDS}
  query getMe {
    me {
      ...CoreUserFields
    }
  }
`;

export default ME;