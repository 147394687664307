import React, { useReducer, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import { useMutation, gql } from '@apollo/client';
import { useFeedback } from '../../feedback/Service';
import { parseGraphQLError } from '../../../utils';

const POST_COMMISSION_TYPE = gql`
  mutation postCommissionType($type: CommissionTypeInput!) {
    postCommissionType(type: $type) {
      _id
      name
      hasQuantity
    }
  }
`;

const initialState = {
  name: "",
  hasQuantity: false,
};

const reducer = (state, newState) => ({
  ...state,
  ...newState
});

const numericFields = [];

const EditCommissionTypeDialog = ({ data, open, onClose }) => {
  const [state, setState] = useReducer(reducer, initialState);
  const [submit, { loading }] = useMutation(POST_COMMISSION_TYPE, {
    update(cache, { data: { postCommissionType } }) {
      if (data._id)
        return;
      cache.modify({
        fields: {
          commissionTypes: (x) => [...x, postCommissionType],
        }
      });
    }
  });
  
  const feedback = useFeedback();

  const handleChange = ({ target: { name, value, type, checked } }) => setState({ [name]: type === "checkbox" ? checked : value });

  const handleClose = () => {
    setState(initialState);
    onClose();
  }

  const handleSubmit = async e => {
    e.preventDefault();
    try {
      const formData = { ...state };
      for (let field of numericFields) {
        if (typeof formData[field] === "object") {
          formData[field] = { from: parseFloat(formData[field].from), to: parseFloat(formData[field].to) };
          if (formData[field].from > formData[field].to)
            return;
        } else
          formData[field] = parseFloat(formData[field]);
      }
      if (data._id)
        formData._id = data._id;
      console.log(formData);
      await submit(({ variables: { type: formData } }));
      feedback.snackbar({ text: "Saved!", type: "success" });
      handleClose();
    } catch (error) {
      console.error("Edit type error", error);
      feedback.snackbar({ text: parseGraphQLError(error), type: "error" });
    }
  }

  useEffect(() => {
    if (!data?._id) return;
    setState({
      name: data.name || "",
    });
  }, [data]);

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>{data?._id ? "Edit" : "Add"} Commission Type</DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit} id='edit-comm-type-form' autoComplete="off">
          <TextField
            autoFocus
            margin="dense"
            label="Name"
            fullWidth
            name="name"
            // variant="standard"
            value={state.name}
            onChange={handleChange}
            required
          />
        </form>
      </DialogContent>
      <DialogActions>
        <LoadingButton type="submit" form='edit-comm-type-form' loading={loading}>Save</LoadingButton>
        <Button onClick={handleClose}>Cancel</Button>
      </DialogActions>
    </Dialog>
  );
}

export default EditCommissionTypeDialog;