import React, { useState, useMemo, useEffect } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { Link, useNavigate } from 'react-router-dom';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { RECORDING_STATES } from '../../constants';
import { getRecordingUrl } from '../../utils';
import VideoJS from '../VideoJs';
import TalentProfile from './TalentProfile';
import TalentPositionDetails from './TalentPositionDetails';
// import ListSubheader from '@mui/material/ListSubheader';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import AddIcon from '@mui/icons-material/Add';
import SimpleDialog from '../SimpleDialog';
import TalentPayments from './TalentPayments';
import TalentMatches from './TalentMatches';
import { Badge } from '@mui/material';
import Stepper from '../Stepper';
import VideoInterview from './VideoInterview';
import Support from '../Support';
import { useFeedback } from '../feedback/Service';

const getVideoJsOptions = id => ({
  autoplay: false,
  controls: true,
  responsive: true,
  fluid: true,
  sources: [{
    src: getRecordingUrl(id),
    type: 'video/mp4'
  }]
});

const initialSteps = [
  { id: 1, label: "Application form", clickable: true },
  { id: 2, label: "Video introduction", clickable: true },
  { id: 3, label: "Add desired position", clickable: true },
  { id: 4, label: "Video interview", clickable: true },
  { id: 5, label: "Waiting for approval", clickable: true },
  { id: 6, label: "Connect Stripe account", clickable: true },
];

export const needsInterview = desiredPosition => {
  // console.log("checkInterview 1", desiredPosition);
  if (!desiredPosition.interview)
    return true;
  // console.log("checkInterview 2", desiredPosition.interview.answers.length, desiredPosition.position.questions.length, desiredPosition.interview.answers.length < desiredPosition.position.questions.length);
  return desiredPosition.interview.answers.length < desiredPosition.position.questions.length;
}

const TalentDetails = ({ talent, showPositions = true }) => {
  const answers = useMemo(() => talent.interview?.answers || [], [talent]);
  // const [tab, setTab] = useState(answers.length > 0 ? answers[0].question._id : null);
  const [tab, setTab] = useState("info");
  const navigate = useNavigate();
  const feedback = useFeedback();

  const step = useMemo(() => {
    let result = 1;
    if (answers.length > 0)
      result = 2;
    if (talent.desiredPositions.length > 0)
      result = 3;
    if (talent.desiredPositions.length > 0 && !talent.desiredPositions.some(needsInterview))
      result = 4;
    if (talent.approved)
      result = 5;
    if (talent.stripeConnected)
      result = 6;
    return result;
  }, [talent]);

  const steps = useMemo(() => {
    return initialSteps.map((x, i) => ({ ...x, completed: i < step, disabled: i > step }))
  }, [step]);

  const handleStepClick = index => {
    switch (index) {
      case 0:
        return setTab("info");
      case 1:
        return setTab("intro");
      case 2:
        return step > 2 ? setTab("positions") : navigate("/account/talent/position/new");
      case 3:
        console.log("desiredPositions", talent.desiredPositions);
        return step > 3 ? setTab("positions") : setSelectedPositionIndex(talent.desiredPositions.findIndex(needsInterview));
      case 5:
        return setTab("payments");
      default:
        return setTab("info");
    }
  }

  const [selectedPositionIndex, setSelectedPositionIndex] = useState(null);
  const selectedPosition = useMemo(() => {
    if (!talent || selectedPositionIndex === null)
      return null;
    return talent.desiredPositions[selectedPositionIndex];
  }, [talent, selectedPositionIndex]);

  const handleShowPosition = index => () => setSelectedPositionIndex(index)
  //   const position = talent.desiredPositions[index];
  //   console.log(position);
  //   feedback.alert({ title: position.position.name, component: <TalentPositionDetails position={position} showEdit />, scroll: "body", maxWidth: "lg", fullWidth: true, clickAwayClose: true });
  // }

  const handleIntroCompleted = () => {
    setTab("positions");
    window.gtag('event', 'talent_intro', {});
    feedback.snackbar({ text: "Video introduction completed!", type: "success" });
  }

  const handleChange = (event, newValue) => setTab(newValue);

  // useEffect(() => {
  //   setTab(answers.length > 0 ? answers[0].question._id : null);
  // }, [answers]);

  useEffect(() => {
    if (window.location.hash === "#payments")
      setTab("payments");
    if (window.location.hash === "#record-intro") {
      navigate("/account");
      setTab("intro");
    }
  }, []);

  useEffect(() => {
    if (window.location.hash === "#interview-completed") {
      navigate("/account");
      feedback.alert({
        title: "Success!",
        component: (
          <Typography variant="body1" gutterBottom>Congratulations! Your videos have been successfully captured. Your profile is now in the verification process. Once verified, {"we'll"} begin presenting it to relevant companies. {"You'll"} receive an email update on the next steps shortly.</Typography>
        ),
        scroll: "paper", maxWidth: "md", fullWidth: true, clickAwayClose: false, confirmButtonText: "Back to account"
      });
    }
    if (window.location.hash === "#position-added") {
      navigate("/account");
      const position = talent.desiredPositions.find(needsInterview);
      console.log("position", position);
      feedback.alert({
        title: "Success!",
        component: (
          <>
            <Typography variant="body1" gutterBottom>Your position has been successfully added. To initiate matching, {"you're"} required to undergo a video interview, which should take approximately 30 minutes. {"You'll"} be asked to respond to a series of questions in video format.</Typography>
            <Button variant="contained" size='large' sx={{ mt: 2 }} color="primary" component={Link} to={"/account/talent/position/" + position._id + "/interview"}>Start interview</Button>
          </>
        ),
        scroll: "paper", maxWidth: "md", fullWidth: true, clickAwayClose: false, confirmButtonText: "Back to account"
      });
    }
  }, [talent]);

  // useEffect(() => {
  //   if (tab === "intro" && answers.length > 0)
  //     setTab(answers[0].question._id);
  // }, [tab, answers]);

  if (!talent || !tab)
    return null;

  const matchesCount = talent.desiredPositions.reduce((acc, item) => acc + (item.matches || []).filter(x => x.acceptedByTalent === null).length, 0);

  return (
    <Box sx={{ width: '100%', typography: 'body1' }}>
      <Stepper steps={steps} activeStep={step} setStep={handleStepClick} />
      <SimpleDialog title={selectedPosition?.position.name} open={!!selectedPosition} onClose={() => setSelectedPositionIndex(null)} maxWidth="lg" fullWidth scroll="body">
        <TalentPositionDetails position={selectedPosition} showEdit />
      </SimpleDialog>
      <TabContext value={tab}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider', mt: 2 }}>
          <TabList onChange={handleChange} aria-label="lab API tabs example" variant="scrollable" scrollButtons="auto">
            <Tab label="Profile" value={"info"} />
            {answers.length > 0 ? answers.map(({ question }) => <Tab key={question._id} label={"Video: " + question.title} value={question._id} />) :
              <Tab label={<Badge badgeContent="!" color="error" sx={{ "& > span": { right: -6 } }}>Video Introduction</Badge>} value="intro" />
            }
            {showPositions && <Tab label={step > 3 ? "Desired Positions" : <Badge badgeContent="!" color="error" sx={{ "& > span": { right: -6 } }}>Desired Positions</Badge>} value="positions" />}
            {talent.approved && <Tab label={<Badge badgeContent={matchesCount} color="primary" sx={{ "& > span": { right: -6 } }}>Job Matches</Badge>} value={"matches"} />}
            {talent.approved && <Tab label="Payments" value={"payments"} />}
            <Tab label="Support" value={"support"} />
          </TabList>
        </Box>
        <TabPanel value="info">
          <TalentProfile talent={talent} />
          <Button variant="outlined" sx={{ mt: 1 }} component={Link} to="/account/talent/edit-profile">Edit</Button>
        </TabPanel>
        <TabPanel value="intro">
          <Container maxWidth="md">
            <Box
              sx={{
                mt: 0,
                mb: 0,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <VideoInterview onComplete={handleIntroCompleted} />
            </Box>
          </Container>
        </TabPanel>
        {answers.map(({ question, recording }) => (
          <TabPanel key={question._id} value={question._id}>
            {recording.state === RECORDING_STATES.READY ?
              <Container maxWidth="md">
                <VideoJS options={getVideoJsOptions(recording._id)} />
                <Typography sx={{ whiteSpace: 'pre-line' }}>{question.text}</Typography>
                {question.canRerecord && <Button component={Link} to={"/account/rerecord/" + question._id} variant="outlined" sx={{ mt: 1 }}>Re-record</Button>}
              </Container> :
              <Typography variant="h6">Recording state: {recording.state}</Typography>
            }
            {recording.state === RECORDING_STATES.ERROR &&
              <Button component={Link} to={"/account/rerecord/" + question._id} variant="outlined" sx={{ mt: 1 }}>Re-record</Button>
            }
          </TabPanel>
        ))}
        {showPositions && <TabPanel value="positions">
          <Button variant='contained' startIcon={<AddIcon />} color="primary" aria-label="add" sx={{}} component={Link} to="/account/talent/position/new">
            Add
          </Button>
          <List component="nav">
            {talent.desiredPositions.map((position, index) => (
              <ListItemButton key={position._id} onClick={handleShowPosition(index)}>
                <ListItemText primary={position.position.name} />
              </ListItemButton>
            ))}
          </List>
        </TabPanel>
        }
        <TabPanel value="matches">
          <TalentMatches talent={talent} />
        </TabPanel>
        <TabPanel value="payments">
          <TalentPayments talent={talent} />
        </TabPanel>
        <TabPanel value="support">
          <Support />
        </TabPanel>
      </TabContext>
    </Box>
  );
}

export default TalentDetails;