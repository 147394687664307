import React from 'react';
import MultipleSelectChip from './MultipleSelectChip';

export const data = [
  { name: "Account Mapping" },
  { name: "Account Strategy" },
  { name: "Active Listening" },
  { name: "Adaptability" },
  { name: "Business Development" },
  { name: "Coachability" },
  { name: "Cold Calling" },
  { name: "Consultative Selling" },
  { name: "Contract Negotiation" },
  { name: "Customer Success" },
  { name: "Data Analysis" },
  { name: "Discovery" },
  { name: "Effective Communication" },
  { name: "Enablement" },
  { name: "Full-Cycle Sales" },
  { name: "Mentoring" },
  { name: "Negotiation Skills" },
  { name: "Organization" },
  { name: "Overcoming Objections" },
  { name: "Presentation Skills" },
  { name: "Product Demos" },
  { name: "Prospecting" },
  { name: "Qualifying" },
  { name: "Relationship Building" },
  { name: "Sales Operations" },
  { name: "Social Selling" },
  { name: "Solution-Based Selling" },
  { name: "Team Management" },
  { name: "Up Selling" },
  { name: "Value-Based Selling" },
  { name: "Business to Business (B2B)" },
  { name: "Business to Consumer (B2C)" },
  { name: "Business to Government (B2G)" },
];

const SkillsSelect = ({ onChange, ...props }) => {
  const handleChange = (e) => {
    if (e.target.value.length > 10)
      return;
    onChange(e);
  }
  return (<MultipleSelectChip onChange={handleChange} items={data} {...props} valueField="name" otherOption />);
}

export default SkillsSelect;