import React from 'react';
import MultipleSelectChip from './MultipleSelectChip';

export const data = [
  { name: "Retail" },
  { name: "Advertising & Marketing" },
  { name: "Real estate" },
  { name: "Insurance" },
  { name: "Finance" },
  { name: "Beauty & Healthcare" },
  { name: "Heavy industries" },
  { name: "Food & FMCG" },
  { name: "IT" },
  { name: "Hotels, Restaurants, Catering" },
  { name: "HR" },
  { name: "Logistics" },
  // { name: "Other" },
];

const IndustriesSelect = ({ ...props }) => {
  return (<MultipleSelectChip items={data} {...props} valueField="name" otherOption />);
}

export default IndustriesSelect;