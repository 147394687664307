import React, { useState, useEffect, useMemo } from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { useQuery, gql } from '@apollo/client';
// import ME from '../../gql/common/me.query';
import Stepper from '../Stepper';
import SignUpForm from '../auth/SignUpForm';
import ApplicationForm from './TalentForm';
import VideoInterview from './VideoInterview';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { CORE_USER_FIELDS } from '../../gql/fragments/user.fragment';
import TalentPositionForm from './TalentPositionForm';
import ConfirmEmail from '../auth/ConfirmEmail';
import { CORE_TALENT_FIELDS } from '../../gql/fragments/talent.fragment';
import { Link } from 'react-router-dom';

const ME = gql`
  ${CORE_USER_FIELDS}
  ${CORE_TALENT_FIELDS}
  query GetMe {
    me {
      ...CoreUserFields
      talent {
        _id
        ...CoreTalentFields
        desiredPositions { 
          _id
          position {
            _id
            questions { 
              _id
            }
          }
          interview {
            answers {
              recording {
                _id
                state
              }
              question {
                _id
                title
              }
            }
          }
        }
        interview {
          answers {
            recording {
              _id
              state
            }
            question {
              _id
              title
            }
          }
        }
      }
    }
  }
`;

const initialSteps = [
  { id: 0, label: "Registration" },
  { id: 1, label: "Confirm email" },
  { id: 2, label: "Application form", clickable: true },
  // { id: 3, label: "Video introduction", clickable: false },
  // { id: 4, label: "Add desired position", clickable: true },
  // { id: 5, label: "Video interview", clickable: false },
];

const getDesiredPositionForInterview = user => user.talent?.desiredPositions?.find(x => x.position.questions.length > (x.interview?.answers?.length || 0));

const TalentSignUp = () => {
  const { loading: meLoading, data: meData } = useQuery(ME);
  const [step, setStep] = useState(0);

  const lastCompletedStep = useMemo(() => {
    console.log("meData", meData);
    if (!meData) return 0;
    const { me: user } = meData;
    let result = 0;
    if (user)
      result = 1;
    if (!user.emailConfirmed)
      return 1;
    result = 2;
    if (user.talent)
    //   result = 3;
    // if (user.talent?.interview?.answers?.length > 0)
    //   result = 4;
    // const needInterview = !!getDesiredPositionForInterview(user);
    // if (needInterview)
    //   result = 5;
    // if (user.talent?.desiredPositions?.length > 0 && !needInterview)
      result = 6;
    console.log(result);
    return result;
  }, [meData]);

  const steps = useMemo(() => {
    return initialSteps.map((x, i) => ({ ...x, completed: i < lastCompletedStep, disabled: i > lastCompletedStep || lastCompletedStep === 5 /* || (i === 5 && lastCompletedStep > 5) */ }))
  }, [lastCompletedStep]);

  useEffect(() => {
    setStep(lastCompletedStep);
  }, [lastCompletedStep]);

  useEffect(() => {
    window.scroll(0, 0);
  }, [step]);

  if (meLoading) return null;
  // console.log("me", meData);
  return (
    <Box
      sx={{
        marginTop: 3,
        // display: 'flex',
        // flexDirection: 'column',
        // alignItems: 'center',
      }}
    >
      <Container maxWidth="md">
        <Stepper steps={steps} activeStep={step} setStep={setStep} />
      </Container>
      {step === 0 &&
        <Container maxWidth="xs">
          <Box
            sx={{
              marginTop: 4,
              marginBottom: 8,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <SignUpForm isTalent />
          </Box>
        </Container>}
      {step === 1 &&
        <Container maxWidth="xs">
          <Box
            sx={{
              marginTop: 4,
              marginBottom: 8,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <ConfirmEmail userId={meData?.me?._id} />
          </Box>
        </Container>}
      {step === 2 &&
        <Container maxWidth="md">
          <Box
            sx={{
              marginTop: 4,
              marginBottom: 8,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <ApplicationForm userId={meData?.me?._id} data={meData?.me?.talent} />
          </Box>
        </Container>
      }
      {step === 3 &&
        <Container maxWidth="md">
          <Box
            sx={{
              marginTop: 4,
              marginBottom: 8,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <VideoInterview />
          </Box>
        </Container>
      }
      {step === 4 &&
        <Container maxWidth="md">
          <Box
            sx={{
              marginTop: 4,
              marginBottom: 8,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <TalentPositionForm talentId={meData?.me?.talent?._id} />
          </Box>
        </Container>
      }
      {step === 5 &&
        <Container maxWidth="md">
          <Box
            sx={{
              marginTop: 4,
              marginBottom: 8,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <VideoInterview desiredPositionId={getDesiredPositionForInterview(meData.me)?._id} />
          </Box>
        </Container>
      }
      {step === 6 &&
        <Container maxWidth="sm">
          <Box
            sx={{
              marginTop: 8,
              marginBottom: 8,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            {/* <Typography variant="h6" align='center'>Thank you for completing the registration process! You{"'"}ll receive further instructions from our team by email.</Typography> */}
            <Typography variant="h6" align='center'>Thank you for submitting the application form! Finish the registration process by adding desired positions and completing a video interview in your account.</Typography>
            <div>
              <Button component={Link} to="/account#record-intro" variant="contained" sx={{ m: 2 }} >Record Video Introduction</Button>
              <Button component={Link} to="/account" variant="contained" sx={{ m: 2 }} >Visit your Account Page</Button>
            </div>
          </Box>
        </Container>
      }
    </Box>
  );
}

export default TalentSignUp;