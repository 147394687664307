import React, { useEffect, useMemo, useState } from 'react';
// import LoadingButton from '@mui/lab/LoadingButton';
import { makeStyles } from '@mui/styles';
import { /* lighten,  */useTheme } from '@mui/material/styles';
// import Box from '@mui/material/Box';
import { MaterialReactTable, useMaterialReactTable, } from 'material-react-table';
import { Box, IconButton, Button } from '@mui/material';
import OpenIcon from '@mui/icons-material/OpenInNew';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { mkConfig, generateCsv, download } from 'export-to-csv';
import { useFeedback } from './feedback/Service';

const getHash = string => {
  var hash = 0,
    i, chr;
  if (string.length === 0) return hash;
  for (i = 0; i < string.length; i++) {
    chr = string.charCodeAt(i);
    hash = ((hash << 5) - hash) + chr;
    hash |= 0; // Convert to 32bit integer
  }
  return hash;
}

const useStyles = makeStyles(_ => ({
  root: ({ theme }) => ({
    // display: 'flex',
    // height: '100%',
    // "& > div": {
    //   flexGrow: 1,
    // },
  }),
}));

const csvConfig = mkConfig({
  fieldSeparator: ',',
  decimalSeparator: '.',
  useKeysAsHeaders: true,
});

const DataGrid = ({ data, columns: cols, isPosting, onSave, onDelete, onRowDoubleClick, enableEditing = false, subRow, renderDetailPanel, ...rest }) => {
  const theme = useTheme();
  const classes = useStyles({ theme });
  const feedback = useFeedback();
  const [columnFilters, setColumnFilters] = useState([]);

  const handleExportRows = (rows) => {
    const rowData = rows.map((row) => row.original);
    // console.log("rowData", rowData);
    const transformedData = rowData.map((row) => {
      const newRow = {};
      for (let col of cols) {
        // console.log("col", col.header, !!col.accessorFn, col.accessorKey, row[col.accessorKey]);
        newRow[col.header] = col.accessorFn ? col.accessorFn(row) : row[col.accessorKey];
      }
      return newRow;
    });
    // console.log("transformedData", transformedData);
    const csv = generateCsv(csvConfig)(transformedData);
    download(csvConfig)(csv);
  };

  const handleDelete = (row) => async () => {
    if (!await feedback.confirm({ title: "Are you sure you want to delete this row?" }))
      return;
    const id = row.original._id;
    onDelete(id);
  }

  const columns = useMemo(() => {
    return cols.map(col => {
      if (col.filterVariant === "multi-select" && col.filterField && data) {
        col.filterSelectOptions = [...new Set(...data.map(x => x[col.filterField || col.accessorKey]))];
      }
      if (!col.enableEditing)
        col.enableEditing = false;
      return col;
    });
  }, [cols, data]);

  const table = useMaterialReactTable({
    columns,
    data, //must be memoized or stable (useState, useMemo, defined outside of this component, etc.)
    // enableRowSelection: true, //enable some features
    enableColumnOrdering: true, //enable a feature for all columns
    // enableGlobalFilter: false, //turn off a feature
    enableColumnPinning: true, //enable a feature for all columns,
    enableStickyHeader: true,
    enableStickyFooter: true,
    enableFacetedValues: true,
    enableEditing: enableEditing,
    enableGrouping: true,
    renderDetailPanel,
    enableExpanding: !!subRow,
    onColumnFiltersChange: setColumnFilters,
    getSubRows: x => x[subRow],
    onEditingRowSave: ({ table, values }) => {
      onSave(values);
      table.setEditingRow(null); //exit editing mode
    },
    muiTableBodyRowProps: ({ row }) => ({
      onDoubleClick: () => {
        if (onRowDoubleClick)
          onRowDoubleClick(row.original)
      },
    }),
    enableRowActions: !!onRowDoubleClick,
    renderRowActions: ({ row, table }) => (
      <Box>
        {enableEditing &&
          <IconButton onClick={() => table.setEditingRow(row)}>
            <EditIcon />
          </IconButton>
        }
        <IconButton onClick={() => onRowDoubleClick ? onRowDoubleClick(row.original) : null}>
          <OpenIcon />
        </IconButton>
        {onDelete &&
          <IconButton color="error" onClick={handleDelete(row)}>
            <DeleteIcon />
          </IconButton>
        }
      </Box>
    ),
    renderTopToolbarCustomActions: ({ table }) => (
      <Box
        sx={{
          display: 'flex',
          gap: '16px',
          padding: '8px',
          flexWrap: 'wrap',
        }}
      >
        <Button
          disabled={table.getPrePaginationRowModel().rows.length === 0}
          //export all rows, including from the next page, (still respects filtering and sorting)
          onClick={() =>
            handleExportRows(table.getPrePaginationRowModel().rows)
          }
          startIcon={<FileDownloadIcon />}
        >
          Download CSV
        </Button>
      </Box>
    ),
    muiTablePaperProps: ({ table }) => ({
      elevation: 0,
      sx: {
        // width: '100%',
        // borderRadius: '0',
        // border: '1px dashed #e0e0e0',
      },
      style: {
        zIndex: table.getState().isFullScreen ? 10000 : undefined,
      },
    }),
    initialState: {
      expanded: !!renderDetailPanel,
      columnPinning: { left: columns.filter(x => x.pin).map(x => x.id) }
    },
    state: {
      isSaving: isPosting,
      columnFilters,
    },
    ...rest
  });

  useEffect(() => {
    if (columnFilters.length === 0)
      return;
    // console.log("set columnFilters", columnFilters, cols);
    const colsString = cols.map(x => x.id).join(";");
    // console.log("set colsString", colsString);
    const hash = getHash(colsString);
    // console.log("set hash", hash, `columnFilters-${hash}`);
    localStorage.setItem(`columnFilters-${hash}`, JSON.stringify(columnFilters));
  }, [columnFilters, cols]);

  useEffect(() => {
    const colsString = cols.map(x => x.id).join(";");
    // console.log("get colsString", colsString);
    const hash = getHash(colsString);
    // console.log("get hash", hash);
    const storedFilters = localStorage.getItem(`columnFilters-${hash}`);
    // console.log("get storedFilters", storedFilters);
    if (storedFilters)
      setColumnFilters(JSON.parse(storedFilters));
  }, [cols]);

  return (
    <div className={classes.root}>
      {/* <div> */}
      <MaterialReactTable table={table} />
      {/* </div> */}
    </div>
  );
}

export default DataGrid;