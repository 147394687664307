import React from 'react';
import MultipleSelectChip from './MultipleSelectChip';

const data = [
  { name: "Not applicable" },
  { name: "Startups and SMB" },
  { name: "Mid Market" },
  { name: "Enterprise" },
];

const BusinessesSelect = ({ ...props }) => {
  return (<MultipleSelectChip items={data} {...props} valueField="name" />);
}

export default BusinessesSelect;