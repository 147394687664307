import React, { useMemo, useEffect, useState, useRef } from 'react';
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import { useFeedback } from '../feedback/Service';
import StreamingMic from './Mic';
import Recorder from './Recorder';
import ChunkUploader from './ChunkUploader';
import { useMutation, gql } from '@apollo/client';
import StartButton from './StartInterviewButton';
import Typography from '@mui/material/Typography';
import Alert, { alertClasses } from '@mui/material/Alert';
// import Button from '@mui/material/Button';
import StopIcon from '@mui/icons-material/Stop';
import StartIcon from '@mui/icons-material/FiberManualRecord';
import { useNavigate } from 'react-router-dom';
import { parseGraphQLError } from '../../utils';
import { useMediaQuery, useTheme } from '@mui/material';

const STATES = {
  STOPPED: "Stopped",
  STARTING: "Starting",
  STARTED: "Recording",
  SAVING: "Saving",
}

const UPLOAD_CHUNK = gql`
  mutation UploadChunk($_id: String!, $blob: Upload!) {
    chunkUpload(_id: $_id, blob: $blob)
  }
`;

const SHOW_QUESTION = gql`
  mutation ShowInterviewQuestion($questionId: String!, $desiredPositionId: String) {
    showInterviewQuestion(questionId: $questionId, desiredPositionId: $desiredPositionId)
  }
`;

const START_INTERVIEW = gql`
  mutation StartInterview($questionId: String!, $desiredPositionId: String) {
    startInterview(questionId: $questionId, desiredPositionId: $desiredPositionId) {
      _id
      state
    }
  }
`;

const STOP_INTERVIEW = gql`
  mutation StopInterview($recordingId: String!) {
    stopInterview(recordingId: $recordingId) {
      _id
      desiredPositions {
        _id
          interview {
          _id
          answers {
            question {
              _id
            },
            recording { 
              _id
              state
            }
          }
        }
      }
      interview {
        _id
        answers {
          question {
            _id
          },
          recording { 
            _id
            state
          }
        }
      }
    }
  }
`;

const getWarningText = question => {
  if (!question)
    return "";
  const { canRerecord, preparationTime, recordingTime } = question;
  let result = "";
  if (!preparationTime) {
    if (!recordingTime)
      return "";
    result = `You’ll have ${recordingTime} seconds to record your answer. `;
  } else {
    result = `You’ll have ${preparationTime} seconds to prepare your ${recordingTime}-second answer. `;
  }
  result += canRerecord ? "You'll be able to re-record your answer later." : "You WILL NOT be able to re-record your answer.";
  return result;
}

const InterviewRecorder = ({ question, desiredPositionId, redirectOnStop }) => {
  const [state, setState] = useState(STATES.STOPPED);
  const [savingProgress, setSavingProgress] = useState(null);
  const [recordingId, setRecordingId] = useState(null);
  const [showQuestion, setShowQuestion] = useState(false);
  const [targetTime, setTargetTime] = useState(null);
  const [uploadChunk] = useMutation(UPLOAD_CHUNK);
  const [start] = useMutation(START_INTERVIEW);
  const [stop] = useMutation(STOP_INTERVIEW);
  const [showQuestionMutation, { data: questionTextData, loading: questionTextLoading }] = useMutation(SHOW_QUESTION);
  const feedback = useFeedback();
  const recorder = useMemo(() => new Recorder(true), []);
  const videoElRef = useRef(null);
  const chunkUploader = useRef(null);
  const timeout = useRef(null);
  const navigate = useNavigate();

  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down('sm'));

  const handleMicConnected = () => {
    console.log("mic connected", recorder.stream);
    videoElRef.current.srcObject = recorder.stream;
    // videoElRef.current.play();
    // recorder.current.on("micStatusChange", x => handleMicStatusChange(x, null));
    // recorder.current.on("cameraStatusChange", x => handleCameraStatusChange(x, null));
    // setMicConnected(true);
  }

  const handleShowQuestion = async () => {
    try {
      await showQuestionMutation({ variables: { questionId: question._id, desiredPositionId } });
    } catch (error) {
      console.error("handleShowQuestion", error);
      feedback.snackbar({ text: parseGraphQLError(error), type: "error" });
      return;
    }
    setShowQuestion(true);
    timeout.current = setTimeout(handleStart, question.preparationTime * 1000);
    setTargetTime(Date.now() + question.preparationTime * 1000);
  }

  const handleStart = async () => {
    clearTimeout(timeout.current);
    setState(STATES.STARTING);
    try {
      const { data: { startInterview: { _id: recordingId } } } = await start({ variables: { questionId: question._id, desiredPositionId } });
      console.log("recordingId", recordingId);
      setRecordingId(recordingId);
      await recorder.start();
      setTargetTime(Date.now() + question.recordingTime * 1000);
      setState(STATES.STARTED);
      if (question.recordingTime)
        timeout.current = setTimeout(() => handleStop(recordingId), question.recordingTime * 1000);
    }
    catch (ex) {
      console.error("handleStart", ex);
      feedback.snackbar({ text: ex?.message || parseGraphQLError(ex), type: "error" });
      setState(STATES.STOPPED);
    }
  };

  const handleStop = async recordingId => {

    const waitForChunksFromUploader = () => {
      return new Promise(resolve => {
        if (chunkUploader.current.isQueueEmpty) {
          resolve();
          return;
        }
        chunkUploader.current.on("ready", resolve);
      });
    }

    clearTimeout(timeout.current);
    setState(STATES.SAVING);
    try {
      console.log("stopping recorder", chunkUploader.current.queue.length);
      await recorder.stop();
      console.log("stopped recorder", chunkUploader.current.queue.length);
      setTargetTime(null)
      setSavingProgress({ done: 0, total: chunkUploader.current.queue.length });
      await waitForChunksFromUploader();
      setSavingProgress({ done: 1, total: 1 });
      console.log("calling stop", chunkUploader.current.queue.length);
      await stop({ variables: { recordingId } });
      setState(STATES.STOPPED);
      feedback.snackbar({ text: "Recording saved", type: "success" });
      if (redirectOnStop)
        navigate(redirectOnStop);
    } catch (error) {
      feedback.snackbar({ text: error?.message || "Error saving recording", type: "error" });
      setState(STATES.STARTED);
    }
    setSavingProgress(null);
  }

  useEffect(() => {
    const upload = async (id, data) => {
      // console.log("upload", data);
      await uploadChunk(({ variables: { _id: id, blob: data } }));
    }
    chunkUploader.current = new ChunkUploader(upload);
    chunkUploader.current.recordingId = recordingId;
    chunkUploader.current.on('update', left => setSavingProgress(x => x && ({ ...x, done: x.total - left })));
    const handleVideoChunk = blob => {
      console.log("new chunk", blob);
      chunkUploader.current.add(blob);
    }
    recorder.on("videochunk", handleVideoChunk);
    return () => {
      recorder.off("videochunk", handleVideoChunk);
    }
  }, [recorder, recordingId, uploadChunk]);

  useEffect(() => {
    return () => { 
      if (recorder)
        recorder.stopTracks();
    }
  }, [recorder]);

  useEffect(() => {
    if (!question)
      return;
    try {
      if (question.preparationTime)
        setShowQuestion(false)
      else
        showQuestionMutation({ variables: { questionId: question._id, desiredPositionId } }).then(() => setShowQuestion(true));
    } catch (error) {
      console.warn(error);
      feedback.snackbar({ text: error.message || "Error getting question text!", type: "error" });
    }
  }, [question, showQuestionMutation, feedback, desiredPositionId]);

  let startLabel = "Start Recording";
  if (state === STATES.STARTING)
    startLabel = "Starting, please wait...";
  if (state === STATES.STARTED)
    startLabel = "Recording...";

  const uploadPercent = Math.round((savingProgress?.done || 0) / (savingProgress?.total || 1) * 100);
  return (
    <>
      <StreamingMic recorder={recorder} onMicConnected={handleMicConnected} />
      <video style={{ width: "100%", transform: "scaleX(-1)", WebkitTransform: "scaleX(-1)" }} ref={videoElRef} autoPlay muted />
      <Alert sx={{ [`& .${alertClasses.message}`]: { display: "flex", flexWrap: isXs ? "wrap" : "nowrap" } }} severity="warning">
        <strong style={{ marginBottom: isXs ? 8 : 0 }} >Please DO NOT refresh the page or navigate away while recording. </strong>
        <span style={{ marginBottom: isXs ? 8 : 0 }}>{getWarningText(question)}</span>
        {!showQuestion && <LoadingButton variant="outlined" onClick={handleShowQuestion} sx={{ minWidth: 150 }} loading={questionTextLoading}>Show Question</LoadingButton>}
      </Alert>
      {showQuestion && <Typography variant="body2" sx={{ mt: 1, whiteSpace: "pre-line" }}>{questionTextData?.showInterviewQuestion}</Typography>}
      <Box sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        m: 2,
        flexWrap: "wrap",
        "& > button": {
          m: 1,
          minWidth: 200,
        }
      }}>
        {/* {state === STATES.STOPPED ? */}
        <StartButton
          targetTime={targetTime}
          variant="contained"
          color="primary"
          size="large"
          onClick={handleStart}
          loading={state === STATES.STARTING}
          disabled={state !== STATES.STOPPED || !showQuestion}
          loadingPosition="start"
          startIcon={<StartIcon />}
        >
          {startLabel}
        </StartButton>
        {/* : */}
        <LoadingButton
          variant="contained"
          color="primary"
          size="large"
          onClick={() => handleStop(recordingId)}
          disabled={state !== STATES.STARTED}
          loading={state === STATES.SAVING}
          loadingPosition="start"
          startIcon={<StopIcon />}
        >
          {state === STATES.SAVING ? (uploadPercent < 100 ? `Uploading... ${uploadPercent}%` : "Saving...") : "Stop & Save Recording"}
        </LoadingButton>
        {/* } */}
      </Box>
    </>
  );
}

export default InterviewRecorder;