import React, { useEffect, useMemo } from 'react';
import { gql, useQuery } from '@apollo/client';
import { loaderVar } from '../../../vars';
import { CORE_CLIENT_FIELDS } from '../../../gql/fragments/client.fragment';
import { CORE_JOB_FIELDS } from '../../../gql/fragments/job.fragment';
import MatchingGrid from './MatchingGrid';
// import JobsGrid from './JobsGrid';
import Paper from '@mui/material/Paper';
import { CORE_DESIRED_POSITION_FIELDS } from '../../../gql/fragments/desiredPosition.fragment';
import { CORE_TALENT_FIELDS } from '../../../gql/fragments/talent.fragment';

export const GET_MATCHING_CLIENTS = gql`
  ${CORE_CLIENT_FIELDS}
  ${CORE_JOB_FIELDS}
  ${CORE_DESIRED_POSITION_FIELDS}
  ${CORE_TALENT_FIELDS}
  query GetMatchingClients {
    clients {
      ...CoreClientFields
      user {
        _id
        email
        firstName
        lastName
      }
      jobs {
        ...CoreJobFields
        matches {
          _id
          desiredPosition {
            _id
            ...CoreDesiredPositionFields
          }
          talent {
            ...CoreTalentFields
            _id
            user {
              _id
              firstName
              lastName
              email
            }
          }
          acceptedByClient
          acceptedByTalent
          interviewScheduled
          createdAt
        }
      }
    }
  }
`;

const Matching = () => {
  const { loading, error, data } = useQuery(GET_MATCHING_CLIENTS, { pollInterval: 30000 });
  // const feedback = useFeedback();
  // const { id } = useParams();

  // const handleShowDetails = jobId => {
  //   const client = data.clients.find(x => x._id === id);
  //   const job = client.jobs.find(x => x._id === jobId);
  //   feedback.alert({ title: "Job Request: " + job.position.name, component: <JobDetails job={job} showSuggestions={false} />, scroll: "body", maxWidth: "lg", fullWidth: true, clickAwayClose: true });
  // }

  const filteredClients = useMemo(() => {
    if (!data)
      return [];
    return data.clients.filter(client => (client.jobs || []).filter(x => !x.closed).length > 0);
  }, [data]);


  useEffect(() => {
    loaderVar(loading ? "query" : null);
  }, [loading]);

  if (error)
    return <div>{error.message}</div>

  if (!data)
    return null;

  return (
    <Paper sx={{ p: 0, display: 'flex', flexDirection: 'column', mt: 4, mb: 4 }}>
      <MatchingGrid data={filteredClients} />
    </Paper>
  )
}

export default Matching;