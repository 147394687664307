import React, { useEffect } from 'react';
import { gql, useQuery } from '@apollo/client';
import { loaderVar } from '../../../vars';
import JobsGrid from './JobsGrid';
import { CORE_CLIENT_FIELDS } from '../../../gql/fragments/client.fragment';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import { useFeedback } from '../../feedback/Service';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { useParams, Link } from 'react-router-dom';
import { CORE_JOB_FIELDS } from '../../../gql/fragments/job.fragment';
import JobDetails from '../../clients/JobDetails';
import ClientProfile from '../../clients/ClientProfile';

const CLIENTS = gql`
  ${CORE_CLIENT_FIELDS}
  ${CORE_JOB_FIELDS}
  query GetClients {
    clients {
      ...CoreClientFields
      user {
        _id
        email
        firstName
        lastName
      }
      jobs {
        ...CoreJobFields
      }
    }
  }
`;

const Clients = () => {
  const { loading, error, data } = useQuery(CLIENTS, { pollInterval: 30000 });
  const feedback = useFeedback();
  const { id } = useParams();

  const handleShowDetails = jobId => {
    const client = data.clients.find(x => x._id === id);
    const job = client.jobs.find(x => x._id === jobId);
    feedback.alert({ title: "Job Request: " + job.position.name, component: <JobDetails job={job} showSuggestions={false} />, scroll: "body", maxWidth: "lg", fullWidth: true, clickAwayClose: true });
  }

  useEffect(() => {
    loaderVar(loading ? "query" : null);
  }, [loading]);

  if (error)
    return <div>{error.message}</div>

  if (!data)
    return null;

  const { clients } = data;

  return (
    <Paper sx={{ p: 0, display: 'flex', flexDirection: 'column', mt: 4, mb: 4 }}>
      {/* <ClientsGrid data={data} handleShowDetails={handleShowDetails} /> */}
      {id ?
        <>
          <Box sx={{ p: 2 }}>
            <ClientProfile client={clients.find(x => x._id === id)} showUserFields />
          </Box>
          <JobsGrid data={clients.find(x => x._id === id)} handleShowDetails={handleShowDetails} />
        </> :
        <List
          component="nav"
          sx={{ padding: 0 }}
        >
          {clients.map((client, index) => (
            <ListItemButton key={client._id} /* onClick={handleShowJob(index)} */ component={Link} to={"/admin/clients/" + client._id} >
              <ListItemText primary={client.company} secondary={client.user.firstName + " " + client.user.lastName} />
            </ListItemButton>
          ))}
        </List>
      }
    </Paper>
  )
}

export default Clients;