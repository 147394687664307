import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import DataGrid from '../../DataGrid';
import { formatRange, formatTimeRange, formatTimestamp } from '../../../utils';

export const cols = [
  { accessorKey: 'position.name', header: 'Position', size: 250, filterVariant: 'multi-select', pin: true, id: "position" },
  { accessorKey: '_id', header: 'ID', size: 20, hide: true, enableClickToCopy: true },
  { accessorFn: ({ createdAt }) => formatTimestamp(createdAt), header: 'Created', size: 150 },
  { accessorKey: 'salesCount', header: 'Sales Count', size: 17, filterVariant: 'range' },
  { accessorFn: ({ matches }) => (matches || []).length, header: 'Matches', size: 20 },
  { accessorFn: ({ matches }) => (matches || []).filter(x => x.acceptedByTalent && x.acceptedByClient).length, header: 'Mutually accepted', size: 20 },
  { accessorFn: ({ matches }) => (matches || []).filter(x => x.acceptedByTalent).length, header: 'Accepted by Talent', size: 20 },
  { accessorFn: ({ matches }) => (matches || []).filter(x => x.acceptedByClient).length, header: 'Accepted by Client', size: 20 },
  { accessorFn: ({ matches }) => (matches || []).filter(x => x.acceptedByTalent === false).length, header: 'Rejected by Talent', size: 20 },
  { accessorFn: ({ matches }) => (matches || []).filter(x => x.acceptedByClient === false).length, header: 'Rejected by Client', size: 20 },
  { accessorFn: ({ jobDescription }) => jobDescription ? "true" : "false", header: 'Job Description', size: 19, filterVariant: 'checkbox' },
  { accessorKey: 'experience', header: 'Experience', size: 155, filterVariant: 'range' },
  { accessorFn: ({ dealSize }) => dealSize ? formatRange(dealSize) : "", header: 'Avg. Deal', size: 100, filterVariant: "multi-select" },
  { accessorKey: 'contractCycle', header: 'Avg. Contract Cycle', size: 10, filterVariant: "multi-select" },
  { accessorFn: ({ businesses }) => businesses?.join(", "), header: 'Segments', size: 250, filterVariant: 'multi-select', filterField: "businesses" },
  { accessorFn: ({ products }) => products?.join(", "), header: 'Products', size: 250, filterVariant: 'multi-select', filterField: "products"},
  { accessorFn: ({ level }) => level?.join(", "), header: 'Levels', size: 250, filterVariant: 'multi-select', filterField: "level" },
  { accessorFn: ({ skills }) => skills?.join(", "), header: 'Skills', size: 250, filterVariant: 'multi-select', filterField: "skills" },
  { accessorFn: ({ budget }) => budget ? formatRange(budget) : "", header: 'Budget', size: 140 },
  { accessorFn: ({ engagement = [] }) => engagement?.join(", "), header: 'Engagement', size: 250, filterVariant: 'multi-select', filterField: "engagement" },
  { accessorFn: ({ startTime, endTime }) => formatTimeRange(startTime, endTime), header: 'Working hours', size: 25, },
];

const JobsGrid = ({ data: initialData, handleShowDetails, sx, ...rest }) => {
  const [data, setData] = useState([]);

  const handleRowDoubleClick = ({ _id }) => handleShowDetails(_id);

  useEffect(() => {
    if (!initialData)
      return;
    setData(initialData?.jobs);
  }, [initialData]);

  return (
    <Box sx={{ height: 'calc(100vh - 128px)', width: '100%', ...sx }}>
      <DataGrid
        data={data}
        columns={cols}
        onRowDoubleClick={handleRowDoubleClick}
        {...rest}
      />
    </Box>
  );
}

export default JobsGrid;