import React from 'react';
import CustomSelect from './CustomSelect';

const data = [
  { name: "Not applicable" },
  { name: "1 week" },
  { name: "1-2 weeks" },
  { name: "2-4 weeks" },
  { name: "1-3 months" },
  { name: "3-6 months" },
  { name: "6-12 months" },
  { name: "1 year+" },
];

const ContractCycleSelect = ({ ...props }) => {
  return (
    <CustomSelect items={data} {...props} valueField="name" />
  )
}

export default ContractCycleSelect;