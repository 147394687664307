import React, { useEffect, useCallback, useState, useMemo } from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormHelperText from '@mui/material/FormHelperText';
import OutlinedInput from '@mui/material/OutlinedInput';
import CustomSelect from './CustomSelect';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Remove';
import CommissionUnitSelect from './CommissionUnitSelect';
import { usePrevious } from 'react-use';

const CommissionInputFixed = ({ data: commission, onChange, position, onAdd: handleAdd, onDelete: handleDelete }) => {
  const [positionCommission, setPositionCommission] = useState(null);

  const positionCommissions = useMemo(() => {
    if (!position)
      return [];
    return position.commissions.map((x, i) => ({ ...x, _id: i + 1, name: x.commissionType.name }));
  }, [position]);

  const handlePositionCommissionChange = ({ target: { value } }) => {
    const pc = positionCommissions.find(x => x._id === value);
    setPositionCommission(pc);
    onChange({ target: { name: "value", value: pc.minValue } });
    onChange({ target: { name: "unit", value: pc.unit } });
    onChange({ target: { name: "commissionType", value: pc.commissionType.name } });
  }

  useEffect(() => {
    if (positionCommissions?.length > 0)
      setPositionCommission(positionCommissions[0]);
  }, [positionCommissions]);

  return (
    <Grid container sx={{ mt: 1 }}>
      <Grid item xs={3} md={2}>
        <OutlinedInput
          margin="dense"
          value={commission.value}
          onChange={onChange}
          name="value"
          placeholder={"Value"}
          type="number"
          inputProps={{ min: positionCommission?.minValue || 0 }}
          required
          startAdornment={positionCommission?.unit === "DOLLAR" ? <InputAdornment position="start">$</InputAdornment> : undefined}
          endAdornment={positionCommission?.unit === "PERCENT" ? <InputAdornment position="end">%</InputAdornment> : undefined}
          fullWidth
        // inputRef={fromRef}
        />
      </Grid>
      <Grid item xs={1}>
        <Typography sx={{ textAlign: 'center', fontSize: 20, mt: 1.3 }}>{positionCommission?.unit === "DOLLAR" ? "for" : "of"}</Typography>
      </Grid>
      <Grid item xs={4} md={4}>
        <CustomSelect
          items={positionCommissions}
          fullWidth
          // required
          value={positionCommission?._id || ""}
          onChange={handlePositionCommissionChange}
          readOnly={positionCommissions.length < 2}
        />
      </Grid>
      <Grid item xs={3}>
        {positionCommissions.length > 1 && <IconButton sx={{ mt: 1 }} onClick={handleAdd}><AddIcon /></IconButton>}
        {handleDelete && <IconButton sx={{ mt: 1 }} onClick={handleDelete}><DeleteIcon color='error' /></IconButton>}
      </Grid>
      {(positionCommission?.helperText/*  && !error */) &&
        <FormHelperText>
          {positionCommission?.helperText}
        </FormHelperText>
      }
    </Grid>
  );
}

const CommissionInputFree = ({ data: commission, onChange, onAdd: handleAdd, onDelete: handleDelete }) => {
  return (
    <Grid container sx={{ mt: 1 }}>
      <Grid item xs={3} md={2}>
        <OutlinedInput
          margin="dense"
          value={commission.value}
          onChange={onChange}
          name="value"
          placeholder={"Value"}
          type="number"
          inputProps={{ min: 0 }}
          required
          // startAdornment={commission.unit === "DOLLAR" ? <InputAdornment position="start">$</InputAdornment> : undefined}
          // endAdornment={commission.unit === "PERCENT" ? <InputAdornment position="end">%</InputAdornment> : undefined}
          fullWidth
        />
      </Grid>
      <Grid item xs={1}>
        <CommissionUnitSelect fullWidth name="unit" value={commission.unit} onChange={onChange} />
      </Grid>
      <Grid item xs={1}>
        <Typography sx={{ textAlign: 'center', fontSize: 20, mt: 1.3 }}>{commission.unit === "DOLLAR" ? "for" : "of"}</Typography>
      </Grid>
      {/* <Grid item xs={2} md={2}>
        <OutlinedInput
          margin="dense"
          value={commission.quantity}
          onChange={onChange}
          name="quantity"
          placeholder="Quantity"
          required
          fullWidth
        />
      </Grid> */}
      <Grid item xs={4} md={4}>
        <OutlinedInput
          margin="dense"
          value={commission.commissionType}
          onChange={onChange}
          name="commissionType"
          placeholder={"Subject (e.g. Lead, Contract Value)"}
          required
          fullWidth
        />
      </Grid>
      <Grid item xs={3}>
        <IconButton sx={{ mt: 1 }} onClick={handleAdd}><AddIcon /></IconButton>
        {handleDelete && <IconButton sx={{ mt: 1 }} onClick={handleDelete}><DeleteIcon color='error' /></IconButton>}
      </Grid>
    </Grid>
  );
}


const CommissionsInput = ({ position, name, label, value: commissions, onChange, ...rest }) => {

  const setValue = useCallback(value => onChange({ target: { name, value } }), [name, onChange]);

  const handleChange = index => ({ target: { name: fieldName, value: fieldValue } }) => {
    const newCommissions = [...commissions];
    newCommissions[index][fieldName] = fieldValue;
    setValue(newCommissions);
  }

  const getEmptyCommission = useCallback(() => {
    const posCommission = position?.commissions?.length > 0 ? position.commissions[0] : { unit: "PERCENT", minValue: 0, commissionType: "" };
    const { unit, minValue, commissionType } = posCommission;
    return ({ value: minValue, unit, commissionType: commissionType.name || "", quantity: 1, uid: 1000 * Math.random() });
  }, [position]);

  const handleAdd = () => setValue([...commissions, getEmptyCommission()]);

  const handleDelete = index => () => {
    const newCommissions = [...commissions];
    newCommissions.splice(index, 1);
    setValue(newCommissions);
  }

  const prevPosition = usePrevious(position);
  useEffect(() => {
    if ((!position || !prevPosition || position === prevPosition) && commissions.length > 0)
      return;
    // console.log("effect", position, commissions);
    setValue([getEmptyCommission()]);
  }, [getEmptyCommission, setValue, position, prevPosition, commissions]);

  // console.log(commissions[0]);

  return (
    <>
      <InputLabel htmlFor={name}>{label} {rest.required ? "*" : ""}</InputLabel>
      {commissions.map((commission, i) =>
        position?.commissions?.length > 0 ?
          <CommissionInputFixed
            key={commission.uid}
            onChange={handleChange(i)}
            data={commission}
            position={position}
            onAdd={handleAdd}
            onDelete={commissions.length > 1 ? handleDelete(i) : undefined}
          /> :
          <CommissionInputFree
            key={commission.uid}
            onChange={handleChange(i)}
            data={commission}
            onAdd={handleAdd}
            onDelete={commissions.length > 1 ? handleDelete(i) : undefined}
          />
      )}
    </>
  );
}

export default CommissionsInput;