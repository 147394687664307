import React from 'react';
import CustomSelect from './CustomSelect';

const data = [
  { name: "1 - 5" },
  { name: "5 - 10" },
  { name: "10 - 30" },
  { name: "30 - 50" },
  { name: "50+" },
]

const EmployeesNumberSelect = ({ ...props }) => {
  return (
    <CustomSelect items={data} {...props} valueField="name" />
  )
}

export default EmployeesNumberSelect;