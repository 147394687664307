import React from 'react';
import { gql, useQuery } from '@apollo/client';
import { useEffect } from 'react';
import { loaderVar } from '../../../vars';
import UsersGrid from './UsersGrid';
import { CORE_USER_FIELDS } from '../../../gql/fragments/user.fragment';
import Paper from '@mui/material/Paper';

const USERS = gql`
  ${CORE_USER_FIELDS}
  query GetUsers {
    users {
      ...CoreUserFields
      talent {
        _id
      }
      leadType
    }
  }
`;

const Users = () => {
  const { loading, error, data } = useQuery(USERS);

  useEffect(() => {
    loaderVar(loading ? "query" : null);
  }, [loading]);

  if (error)
    return <div>{error.message}</div>

  if (!data)
    return null;

  return (
    <Paper sx={{ p: 0, display: 'flex', flexDirection: 'column', mt: 4, mb: 4 }}>
      <UsersGrid data={data} />
    </Paper>
  )
}

export default Users;