import React, { useReducer, useMemo } from 'react';
import { gql, useQuery } from '@apollo/client';
import { CORE_TALENT_FIELDS } from '../../../gql/fragments/talent.fragment';
import Paper from '@mui/material/Paper';
// import { useFeedback } from '../../feedback/Service';
// import TalentDetails from '../../talents/TalentDetails';
import TalentsGrid from '../talents/TalentsGrid';
import { LinearProgress } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Switch from '@mui/material/Switch';
// import { useHistory } from 'react-router-dom';

const TALENTS = gql`
  ${CORE_TALENT_FIELDS}
  query GetTalents {
    talents {
      ...CoreTalentFields
      user {
        _id
        email
        firstName
        lastName
      }
      interview {
        answers {
          recording {
            _id
            state
          }
          question {
            _id
            text
            title
          }
        }
      }
    }
  }
`;

const reducer = (state, newState) => ({
  ...state,
  ...newState
});

const filterControls = [
  { name: "positions", label: "Match Positions", type: "includes", field: "_id" },
  { name: "businesses", label: "Match Businesses", type: "includes" },
  { name: "industries", label: "Match Industries", type: "includes", targetFields: ["industriesIn", "industriesTo"] },
  { name: "products", label: "Match Products", type: "includes" },
  { name: "budget", label: "Match Budget", type: "compare", operator: "intersect", targetFields: ["baseCompensation"] },
  { name: "experience", label: "Match Experience", type: "compare", operator: "<", targetFields: ["totalExperience", "experience"] },
];

const initialFilters = {
  positions: true,
  businesses: true,
  industries: true,
  products: true,
  budget: true,
  experience: true,
};

const mapByField = (array, field) => field ? array.map(x => x[field]) : array;
const getTargetArray = (object, filter) => filter.targetFields ? [].concat(...filter.targetFields.map(x => object[x])) : object[filter.name];
const findRangeIntersection = (a, b) => {
  const { from: el11, to: el12 } = a;
  const { from: el21, to : el22 } = b;
  const leftLimit = Math.max(el11, el21);
  const rightLimit = Math.min(el12, el22);
  return rightLimit >= leftLimit ? [leftLimit, rightLimit] : null;
};
const applyCompare = (a, b, operator) => {
  if (operator === ">")
    return a >= b;
  if (operator === "<")
    return a <= b;
  if (operator === "intersect") {
    return !!findRangeIntersection(a, b);
  }
}
const compareFields = (object, filter, value) => {
  for (let targetField of filter.targetFields) {
    if (applyCompare(value, object[targetField], filter.operator))
      return true;
  }
  return false;
}

const SuggestedTalents = ({ job }) => {
  const [filters, setFilters] = useReducer(reducer, initialFilters);
  const { loading, error, data } = useQuery(TALENTS, { pollInterval: 30000 });
  // const feedback = useFeedback();
  // const history = useHistory();

  const handleFilterChange = ({ target: { name, value, checked, type } }) => setFilters({ [name]: type === "checkbox" ? checked : value });

  const handleShowDetails = id => {
    // history.push("/talents/" + id);
    const win = window.open("/talent-profile/" + id, "_blank");
    win.focus();
    // console.log(feedback);
    // const talent = data.talents.find(x => x._id === id);
    // console.log(talent);
    // feedback.alert({ title: talent.user.firstName + " " + talent.user.lastName, component: <TalentDetails talent={talent} />, maxWidth: "md", fullWidth: true, clickAwayClose: true });
  }

  const filteredData = useMemo(() => {
    if (!data)
      return null;
    let result = data.talents;
    for (let filter of filterControls) {
      if (!filters[filter.name])
        continue;
      if (filter.type === "includes") {
        result = result.filter(t => mapByField(job[filter.name], filter.field).some(c => mapByField(getTargetArray(t, filter), filter.field).includes(c)));
      }
      if (filter.type === "compare") {
        result = result.filter(t => compareFields(t, filter, job[filter.name]));
      }
    }
    return { talents: result };
  }, [data, filters, job]);

  if (error)
    return <div>{error.message}</div>

  if (loading)
    return (<LinearProgress variant="query" />)

  if (!filteredData)
    return null;

  return (
    <Paper sx={{ p: 0, display: 'flex', flexDirection: 'column', mt: 1, mb: 0 }}>
      <FormGroup row>
        {filterControls.map(({ name, label }) => (
          <FormControlLabel key={name} control={<Switch name={name} checked={filters[name]} onChange={handleFilterChange} />} label={label} />
        ))}
      </FormGroup>
      <TalentsGrid data={filteredData} handleShowDetails={handleShowDetails} height="450px" />
    </Paper>
  )
}

export default SuggestedTalents;