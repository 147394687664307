import React from 'react';
import { GoogleLogin } from '@react-oauth/google';
import { useFeedback } from '../feedback/Service';

const GoogleLoginButton = ({ onSuccess }) => {
  const feedback = useFeedback();
  const handleError = (error) => {
    console.error("Google login error", error);
    feedback.snackbar({ text: "Google login error", type: "error" });
  }

  return (
    <GoogleLogin onSuccess={onSuccess} onError={handleError} useOneTap width="1000px" text="continue_with" size="large" logo_alignment="center" />
  );
}

export default GoogleLoginButton;