import React, { useState } from 'react';
import Avatar from '@mui/material/Avatar';
import LoadingButton from '@mui/lab/LoadingButton';
import CssBaseline from '@mui/material/CssBaseline';
// import TextField from '@mui/material/TextField';
// import FormControlLabel from '@mui/material/FormControlLabel';
// import Checkbox from '@mui/material/Checkbox';
import MuiLink from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { useMutation, gql, /* useQuery */ } from '@apollo/client';
import { useFeedback } from '../feedback/Service';
// import ME from '../../gql/common/me.query';
import ReactCodeInput from 'react-verification-code-input';
import { makeStyles } from '@mui/styles';
import { useTheme } from '@mui/material/styles';
import { parseGraphQLError } from '../../utils';

const useStyles = makeStyles(_ => ({
  root: ({ theme }) => ({
    "& input": {
      fontFamily: "Roboto",
    },
    "& input:focus": {
      borderColor: theme.palette.primary.main,
    },
  }),
}));

const CONFIRM_EMAIL = gql`
  mutation confirmEmail($code: String!) {
    confirmEmail(code: $code)
  }
`;

const RESTORE_EMAIL_CONFIRMATION = gql`
  mutation resetEmailConfirmation {
    resetEmailConfirmation
  }
`;

export default function ConfirmEmail({ userId }) {
  const [confirmEmail, { loading }] = useMutation(CONFIRM_EMAIL, {
    update(cache, { data: { confirmEmail } }) {
      console.log("confirmEmail result", confirmEmail, userId);
      cache.modify({
        id: cache.identify({ _id: userId, __typename: "User" }),
        fields: {
          emailConfirmed: () => confirmEmail,
        }
      });
    }
  });
  const [resetEmailConfirmationCode] = useMutation(RESTORE_EMAIL_CONFIRMATION);
  const [code, setCode] = useState("");
  // const { data: user, refetch: getUser } = useQuery(ME);
  const feedback = useFeedback();
  const theme = useTheme();
  const classes = useStyles({ theme });

  const handleSubmit = async event => {
    event.preventDefault();
    try {
      await confirmEmail(({ variables: { code } }));
      const tagPrefix = window.location.pathname.startsWith("/talent") ? "talent_" : "client_";
      window.gtag('event', tagPrefix + 'confirm_email', {});
      feedback.snackbar({ text: "Email confirmed", type: "success" });
    } catch (error) {
      console.warn("Email confirmation error", error);
      feedback.snackbar({ text: parseGraphQLError(error), type: "error" });
    }
  };

  const handleResend = async e => {
    e.preventDefault();
    try {
      await resetEmailConfirmationCode();
      feedback.snackbar({ text: "New code was sent to your email", type: "info" });
    } catch (error) {
      console.error("Code resend error", error);
      feedback.snackbar({ text: parseGraphQLError(error), type: "error" });
    }
  }

  return (
    <Container maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          // mt: 8,
          // mb: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5" gutterBottom>
          Confirm Email
        </Typography>
        <Typography>
          Confirmation code was sent to your email
        </Typography>
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <ReactCodeInput value={code} onChange={setCode} type="number" fields={4} autoFocus className={classes.root} />
          <LoadingButton type="submit" fullWidth variant="contained" sx={{ mt: 1.5, mb: 2 }} loading={loading}>
            Submit
          </LoadingButton>
          <Grid container>
            <Grid item xs>
              <MuiLink href="#" variant="body2" onClick={handleResend}>
                Send new code
              </MuiLink>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Container>
  );
}