import React from 'react';
import { formatEducation, formatTimeRange, formatWorkExperience } from '../../utils';
import { socialMediaDictionary } from '../../dictionaries/social';
import FieldValue from '../FieldValue';

const TalentProfile = ({ talent }) => {
  if (!talent)
    return null;
  return (
    <>
      {/* <FieldValue label="Desired Position(s)" value={talent.positions.map(x => x.name).join(", ")} /> */}
      <FieldValue label="Intro" value={talent.achievements} />
      <FieldValue label="Proven working experience in sales" value={talent.totalExperience + " years"} />
      <FieldValue label="Average deal size" value={talent.dealSize} range />
      <FieldValue label="Average contract cycle" value={talent.contractCycle} />
      <FieldValue label="Customer segment" value={talent.businesses.join(", ")} />
      <FieldValue label="Products sold" value={talent.products.join(", ")} />
      <FieldValue label="Level sold to" value={talent.level.join(", ")} />
      <FieldValue label="Work Experience" value={talent.workExperience.map(formatWorkExperience).join("; ")} />
      <FieldValue label="Education" value={talent.education.map(formatEducation).join("; ")} />
      <FieldValue label="Top skills" value={talent.skills.join(", ")} />
      <FieldValue label="Preferred engagement" value={(talent.engagement || []).join(", ")} />
      <FieldValue label="Can work remotely" value={talent.remote ? "Yes" : "No"} />
      <FieldValue label="Working hours" value={formatTimeRange(talent.startTime, talent.endTime)} />
      {talent.linkedin && <FieldValue label="LinkedIn" url={socialMediaDictionary.linkedin.baseUrl + talent.linkedin} />}
      {/* {talent.twitter && <FieldValue label="Twitter" url={socialMediaDictionary.twitter.baseUrl + talent.twitter} />}
      {talent.facebook && <FieldValue label="Facebook" url={socialMediaDictionary.facebook.baseUrl + talent.facebook} />}
      {talent.instagram && <FieldValue label="Instagram" url={socialMediaDictionary.instagram.baseUrl + talent.instagram} />} */}
      {/* {talent.customLink && <FieldValue label="Custom Link" url={"https://" + talent.customLink} />} */}
    </>
  );
}

export default TalentProfile;