import React, { useReducer, useMemo, useEffect, useCallback } from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import PositionSelect from '../inputs/PositionSelect';
import LoadingButton from '@mui/lab/LoadingButton';
import { useMutation, useQuery, gql } from '@apollo/client';
import { useFeedback } from '../feedback/Service';
import ProductSelect from '../inputs/ProductSelect';
import BusinessesSelect from '../inputs/BusinessesSelect';
// import IndustriesSelect from '../inputs/IndustriesSelect';
import RangeInput from '../inputs/RangeInput';
import POSITIONS from '../../gql/common/positions.query';
import UrlInput from '../inputs/UrlInput';
import { useNavigate } from 'react-router-dom';
import CommissionsInput from '../inputs/CommissionsInput';
import { parseGraphQLError, transformNumericFields } from '../../utils';
import config from '../../config';
import DealSizeSelect, { data as dealSizeData } from '../inputs/DealSizeSelect';
import ContractCycleSelect from '../inputs/ContractCycleSelect';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import LevelSelect from '../inputs/LevelSelect';
import SkillsSelect from '../inputs/SkillsSelect';
import WorkingHours from '../inputs/WorkingHoursInput';
import EngagementSelect from '../inputs/EngagementSelect';

dayjs.extend(utc);
dayjs.extend(timezone);

// const commissionsPlaceholder = `We believe in transparent and uncapped commissions. 
// What would be a comfortable motivation for our SDRs?
// Examples:
// a) $X per demo call
// b) $X per demo call and $Y per sale
// c) $X per MQL/SQL
// d) $X per MQL/SQL and $Y per sale
// e) $X for Y MQL/SQL leads per month`;

const POST_JOB = gql`
  mutation PostJob($job: JobInput!) {
    postJob(job: $job) {
      _id
      # positions {
      #   _id
      #   name
      # }
    }
  }
`;

const defaultStartDate = dayjs().startOf('day').hour(10);
const defaultEndDate = dayjs().startOf('day').hour(19);

const dateFields = ["startTime", "endTime"];

let initialState = {
  jobDescription: "",
  commissions: [],
  salesCount: "",
  position: "",
  experience: "",
  products: [],
  dealSize: "",
  contractCycle: "",
  businesses: [],
  industries: [],
  budget: { from: "", to: "" },
  level: [],
  skills: [],
  engagement: [],
  startTime: dayjs(defaultStartDate),
  endTime: dayjs(defaultEndDate),
}
if (config.useTestFormData) {
  initialState = {
    ...initialState,
    jobDescription: "none",
    commissions: [],
    // commissions: [{ value: "1", unit: "PERCENT", type: "", quantity: 1 }],
    salesCount: "3",
    position: "",
    experience: "1",
    products: [],
    dealSize: "",
    contractCycle: "",
    businesses: ["Enterprise"],
    industries: ["Insurance"],
    budget: { from: "1000", to: "10000" },
  }
}

const numericFields = ["salesCount", "experience", "budget", "commissions.value", "commissions.quantity"];

const reducer = (state, newState) => ({
  ...state,
  ...newState
})


const JobForm = ({ clientId, data: initialData, redirect }) => {
  const [state, setState] = useReducer(reducer, initialState);
  const { data: positionsData } = useQuery(POSITIONS);
  const feedback = useFeedback();
  const navigate = useNavigate();
  const [submit, { loading }] = useMutation(POST_JOB, {
    update(cache, { data: { postJob } }) {
      console.log("postJob", postJob);
      cache.modify({
        id: cache.identify({ _id: clientId, __typename: "Client" }),
        fields: {
          jobs: () => postJob,
        }
      });
    }
  });

  const currentPosition = useMemo(() => {
    if (!positionsData)
      return null;
    const { positions } = positionsData;
    return positions.find(x => x._id === state.position);
  }, [state.position, positionsData]);

  const handleChange = useCallback(({ target: { name, value, checked, type } }) => {
    setState({ [name]: type === "checkbox" ? checked : value /* (type === "number" ? parseFloat(value) : value) */ });
  }, []);

  const setDefaultPosition = id => {
    if (state.position === "")
      setState({ position: id });
  }

  const handleSubmit = async e => {
    e.preventDefault();
    if ((currentPosition?.minBudget || 0) > state.budget.from)
      return feedback.snackbar({ text: "Minimal budget for selected position is $" + currentPosition?.minBudget, type: "error" });
    try {
      let data = { ...state, commissions: state.commissions.map(x => ({ ...x, type: x.commissionType, commissionType: undefined, uid: undefined })) };
      transformNumericFields(data, numericFields);
      for (let field of dateFields) {
        data[field] = dayjs(data[field]).tz(dayjs.tz.guess()).toISOString();
      }
      await submit(({ variables: { job: data } }));
      if (!initialData?._id) {
        window.gtag('event', 'client_request', {});
      }
      feedback.snackbar({ text: initialData ? "Changes Saved!" : "Request added!", type: "success" });
      if (redirect)
        navigate(redirect);
    } catch (error) {
      console.error("JobForm error", error);
      feedback.snackbar({ text: parseGraphQLError(error), type: "error" });
    }
    // console.log(talent);
  }

  useEffect(() => {
    if (!initialData)
      return;
    // const dealSize = dealSizeData.find(({ value }) => value.from === initialData.dealSize.from && value.to === initialData.dealSize.to).value;
    const position = initialData.position._id;
    const commissions = initialData.commissions.map(x => ({ ...x, __typename: undefined, uid: Math.random() * 1000, commissionType: x.type }));
    const dealSize = dealSizeData.find(({ value }) => value.from === initialData.dealSize.from && value.to === initialData.dealSize.to).value;
    const dates = {};
    for (let field of dateFields) {
      dates[field] = dayjs(initialData[field] || defaultStartDate).tz(dayjs.tz.guess());
    }
    setState({ 
      ...initialData, 
      ...dates, 
      dealSize, 
      position, 
      commissions, 
      __typename: undefined, 
      comment: initialData.comment || "", 
      engagement: initialData.engagement || [], 
      startTime: dates.startTime || dayjs(defaultStartDate), 
      endTime: dates.endTime || dayjs(defaultEndDate),
      matches: undefined,
      closed: undefined,
      createdAt: undefined,
    });
  }, [initialData]);

  console.log({ state });

  return (
    <Box component="form" onSubmit={handleSubmit} sx={{ width: '100%' }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <PositionSelect fullWidth required value={state.position} onChange={handleChange} name="position" label="Position" setDefaultPosition={setDefaultPosition} />
        </Grid>
        <Grid item xs={12}>
          <UrlInput fullWidth value={state.jobDescription} onChange={handleChange} name="jobDescription" label="Link to the job description" baseUrl="https://" />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField fullWidth required value={state.salesCount} type="number" onChange={handleChange} name="salesCount" label="How many personnel do you need?" />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField fullWidth value={state.experience} type="number" onChange={handleChange} name="experience" label="Years of experience" />
        </Grid>
        <Grid item xs={12} md={6}>
          <DealSizeSelect fullWidth required value={state.dealSize} onChange={handleChange} name="dealSize" label="Your average deal size" />
        </Grid>
        <Grid item xs={12} md={6}>
          <ContractCycleSelect fullWidth required value={state.contractCycle} onChange={handleChange} name="contractCycle" label="Your average sales cycle" />
        </Grid>
        {/* <Grid item xs={12}>
          <IndustriesSelect fullWidth required value={state.industries} onChange={handleChange} name="industries" label="Your ideal candidate has knowledge and market experience in the following industries:" />
        </Grid> */}
        <Grid item xs={12}>
          <BusinessesSelect fullWidth required value={state.businesses} onChange={handleChange} name="businesses" label="Customer segments" />
        </Grid>
        <Grid item xs={12}>
          <ProductSelect fullWidth required value={state.products} onChange={handleChange} name="products" label="What type of products do you sell?" />
        </Grid>
        <Grid item xs={12}>
          <LevelSelect fullWidth required value={state.level} onChange={handleChange} name="level" label="Target customer level" />
        </Grid>
        <Grid item xs={12}>
          <SkillsSelect fullWidth required value={state.skills} onChange={handleChange} name="skills" label="What are the key skills you're looking for?" />
        </Grid>
        <Grid item xs={12} md={12}>
          <RangeInput fullWidth required value={state.budget} type="number" onChange={handleChange} name="budget" label="What's the base pay per engagement (per month)?" helperText={currentPosition?.helperText || undefined} startAdornment={<InputAdornment position="start">$</InputAdornment>} />
        </Grid>
        <Grid item xs={12}>
          <CommissionsInput fullWidth required value={state.commissions} position={currentPosition} onChange={handleChange} name="commissions" label="Performance-based commissions" />
        </Grid>
        <Grid item xs={12}>
          <EngagementSelect fullWidth required value={state.engagement} onChange={handleChange} name="engagement" label="Preferred engagement" />
        </Grid>
        <WorkingHours startTime={state.startTime} endTime={state.endTime} onChange={handleChange} label={"Expected working hours (assuming an 8 or 4 hour work day)"}/>
        <Grid item xs={12}>
          <TextField fullWidth multiline rows={5} value={state.comment} onChange={handleChange} name="comment" label="Job description" placeholder='About the role, responsibilities, requirements, KPIs' />
        </Grid>
      </Grid>
      <LoadingButton type="submit" size="large" variant="contained" sx={{ mt: 2, mb: 2 }} loading={loading}>
        Submit
      </LoadingButton>
    </Box>
  );
}

export default JobForm;