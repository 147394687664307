import React, { useState } from 'react';
import Avatar from '@mui/material/Avatar';
import LoadingButton from '@mui/lab/LoadingButton';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
// import FormControlLabel from '@mui/material/FormControlLabel';
// import Checkbox from '@mui/material/Checkbox';
import MuiLink from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { useMutation, gql, useQuery } from '@apollo/client';
import { Link, /* useHistory */ } from 'react-router-dom';
import { useFeedback } from '../feedback/Service';
import ME from '../../gql/common/me.query';
import { AUTH_TOKEN_NAME } from '../../constants';
import useLoginRedirect from '../../hooks/useLoginRedirect';
import Password from './Password';
import { parseGraphQLError } from '../../utils';
import GoogleLoginButton from './GoogleLoginButton';
import config from '../../config';

const LOGIN = gql`
  mutation Login($user: Login!) {
    login(user: $user)
  }
`;

const GOOGLE_LOGIN = gql`
  mutation GoogleLogin($credentials: GoogleCredentials!) {
    googleLogin(credentials: $credentials)
  }
`;

const RESTORE_PASSWORD = gql`
  mutation RestorePassword($email: String!) {
    restorePassword(email: $email)
  }
`;

export default function SignIn() {
  const [login, { simpleLoading }] = useMutation(LOGIN);
  const [googleLogin, { googleLoading }] = useMutation(GOOGLE_LOGIN);
  const [restorePassword] = useMutation(RESTORE_PASSWORD);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { data: user, refetch: getUser } = useQuery(ME);
  useLoginRedirect(user?.me);
  const feedback = useFeedback();
  // const history = useHistory();
  const loading = simpleLoading || googleLoading;

  const handleGoogleLogin = async (credentials) => {
    console.log("handleGoogleLogin", credentials);
    try {
      const { data: { googleLogin: token } } = await googleLogin(({ variables: { credentials } }));
      localStorage.setItem(AUTH_TOKEN_NAME, token);
      console.log("Google login token", token);
      // await client.clearStore();
      getUser({ fetchPolicy: 'network-only' });
    } catch (error) {
      console.warn("Google login error", error);
      feedback.snackbar({ text: parseGraphQLError(error), type: "error" });
    }
  }

  const handleSubmit = async event => {
    event.preventDefault();
    // const formData = new FormData(event.currentTarget);
    const user = { email, password };
    try {
      const { data: { login: token } } = await login(({ variables: { user } }));
      localStorage.setItem(AUTH_TOKEN_NAME, token);
      // await client.clearStore();
      getUser({ fetchPolicy: 'network-only' });
    } catch (error) {
      console.warn("Login error", error);
      feedback.snackbar({ text: parseGraphQLError(error), type: "error" });
    }
  };

  const handleForgotPassword = async e => {
    e.preventDefault();
    const restorationEmail = await feedback.form({ title: "Restore Password", input: { title: "Email Address", initialValue: email, name: "email", type: "email" } });
    if (!restorationEmail)
      return;
    try {
      /* const { data: {restorePassword: token } } =  */await restorePassword(({ variables: { email: restorationEmail } }));
      feedback.snackbar({ text: "Instructions sent to your email", type: "info" });
      // history.push("/reset-password#" + token);
    } catch (error) {
      console.error("Restore password error", error);
      feedback.snackbar({ text: parseGraphQLError(error), type: "error" });
    }
  }

  return (
    <Container maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          mt: 8,
          mb: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
            value={email}
            onChange={({ target: { value } }) => setEmail(value)}
          />
          <Password
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            id="password"
            autoComplete="current-password"
            value={password}
            onChange={({ target: { value } }) => setPassword(value)}
          />
          {/* <FormControlLabel
            control={<Checkbox value="remember" color="primary" />}
            label="Remember me"
          /> */}
          {config.enableGoogleLogin &&
            <Box sx={{ mt: 1, mb: 1 }}>
              <GoogleLoginButton onSuccess={handleGoogleLogin} />
            </Box>
          }
          <LoadingButton type="submit" fullWidth variant="contained" sx={{ mt: 1, mb: 2 }} loading={loading}>
            Sign In
          </LoadingButton>
          <Grid container>
            <Grid item xs>
              <MuiLink href="#" variant="body2" onClick={handleForgotPassword}>
                Forgot password?
              </MuiLink>
            </Grid>
            <Grid item>
              <MuiLink component={Link} to="/sign-up" variant="body2">
                {"Don't have an account? Sign Up"}
              </MuiLink>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Container>
  );
}