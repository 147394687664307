import React, { useReducer, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import { useMutation, gql, /* useQuery */ } from '@apollo/client';
import { useFeedback } from '../../feedback/Service';
import { parseGraphQLError, transformNumericFields } from '../../../utils';
import PositionCommissions from './PositionCommissions';
import PositionQuestions from './PositionQuestions';

const POST_POSITION = gql`
  mutation postPosition($position: PositionInput!) {
    postPosition(position: $position) {
      _id
      name
      helperText
      minBudget
      questions {
        _id
        title
        text
        preparationTime
        recordingTime
        canRerecord
      }
      commissions {
        minValue
        helperText
        commissionType {
          _id
          name
        }
        unit
      }
    }
  }
`;

const emptyCommission = {
  commissionType: "",
  unit: "PERCENT",
  helperText: "",
  minValue: 0,
}

const initialState = {
  name: "",
  helperText: "",
  minBudget: "0",
  commissions: [],
  allowCustomCommissions: false,
  questions: [],
};

const reducer = (state, newState) => ({
  ...state,
  ...newState
});

const numericFields = ["minBudget", "commissions.minValue"];

const EditPositionDialog = ({ data, open, onClose }) => {
  const [state, setState] = useReducer(reducer, initialState);
  const [submit, { loading }] = useMutation(POST_POSITION, {
    update(cache, { data: { postPosition } }) {
      console.log("postPosition", postPosition, data);
      if (data._id)
        return;
      cache.modify({
        fields: {
          positions: (x) => [...x, postPosition],
        }
      });
    }
  });

  const feedback = useFeedback();

  const handleChange = ({ target: { name, value, type, checked } }) => setState({ [name]: type === "checkbox" ? checked : value });

  const handleCommissionChange = index => ({ target: { name, value, type, checked } }) => {
    const newCommissions = [...state.commissions];
    newCommissions[index][name] = type === "checkbox" ? checked : value;
    setState({ commissions: newCommissions });
  }

  const handleDeleteCommission = index => () => setState({ commissions: state.commissions.filter((_, i) => i !== index) });

  const handleClose = () => {
    setState(initialState);
    onClose();
  }

  const handleSubmit = async e => {
    e.preventDefault();
    try {
      const formData = { ...state };
      transformNumericFields(formData, numericFields);
      if (data._id)
        formData._id = data._id;
      formData.questions = formData.questions.map((x, i) => ({ ...x, order: i + 1 }));
      await submit(({ variables: { position: formData } }));
      feedback.snackbar({ text: "Saved!", type: "success" });
      handleClose();
    } catch (error) {
      console.error("Edit position error", error);
      feedback.snackbar({ text: parseGraphQLError(error), type: "error" });
    }
  }

  const handleAddCommission = () => setState({ commissions: [...state.commissions, { ...emptyCommission }] })

  useEffect(() => {
    if (!data?._id) return;
    setState({
      name: data.name || "",
      helperText: data.helperText || "",
      minBudget: data.minBudget || "0",
      commissions: data.commissions.map(x => ({ ...x, commissionType: x.commissionType._id, __typename: undefined })),
      allowCustomCommissions: data.allowCustomCommissions || false,
      questions: data.questions.map(x => ({ ...x, __typename: undefined })) || [],
    });
  }, [data]);

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="lg" scroll="body">
      <DialogTitle>{data?._id ? "Edit" : "Add"} Position</DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit} id='edit-position-form' autoComplete="off">
          <Grid container spacing={1}>
            <Grid item xs={12} md={9} lg={3}>
              <TextField
                autoFocus
                margin="dense"
                label="Name"
                fullWidth
                name="name"
                // variant="standard"
                value={state.name}
                onChange={handleChange}
                required
              />
            </Grid>
            <Grid item xs={12} md={3} lg={2}>
              <TextField
                margin="dense"
                label="Minimal Budget, $"
                type="number"
                fullWidth
                // variant="standard"
                value={state.minBudget}
                onChange={handleChange}
                name="minBudget"
                required
              />
            </Grid>
            <Grid item xs={12} md={12} lg={7}>
              <TextField
                margin="dense"
                label="Helper Text"
                fullWidth
                // variant="standard"
                value={state.helperText}
                onChange={handleChange}
                name="helperText"
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <PositionQuestions onChange={questions => setState({ questions })} data={state.questions} />
            </Grid>
            <Grid item xs={12} md={12}>
              <PositionCommissions onChange={handleCommissionChange} onDelete={handleDeleteCommission} onAdd={handleAddCommission} data={state.commissions} />
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        <LoadingButton type="submit" form='edit-position-form' loading={loading}>Save</LoadingButton>
        <Button onClick={handleClose}>Cancel</Button>
      </DialogActions>
    </Dialog>
  );
}

export default EditPositionDialog;