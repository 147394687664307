import React, { useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { gql, useQuery } from '@apollo/client';
import { CORE_TALENT_FIELDS } from '../../gql/fragments/talent.fragment';
import { loaderVar } from '../../vars';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { CORE_DESIRED_POSITION_FIELDS } from '../../gql/fragments/desiredPosition.fragment';
import { Grid, List, ListItemButton, ListItemText, ListSubheader } from '@mui/material';
import TalentProfile from './TalentProfile';
import VideoJS from '../VideoJs';
import { RECORDING_STATES } from '../../constants';
import { getRecordingUrl } from '../../utils';
import { useFeedback } from '../feedback/Service';
import TalentPositionDetails from './TalentPositionDetails';

const getVideoJsOptions = id => ({
  autoplay: false,
  controls: true,
  responsive: true,
  fluid: true,
  sources: [{
    src: getRecordingUrl(id),
    type: 'video/mp4'
  }]
});

const TALENT = gql`
  ${CORE_TALENT_FIELDS}
  ${CORE_DESIRED_POSITION_FIELDS}
  query GetTalent($id: String!) {
    talent(id: $id) {
      ...CoreTalentFields
      desiredPositions {
        ...CoreDesiredPositionFields
      }
      user {
        _id
        # email
        firstName
        lastName
      }
      interview {
        answers {
          recording {
            _id
            state
          }
          question {
            _id
            text
            title
          }
        }
      }
    }
  }
`;

const TalentPage = () => {
  const { talentId } = useParams();
  const { loading, error, data } = useQuery(TALENT, { variables: { id: talentId } });
  const feedback = useFeedback();

  const answers = useMemo(() => {
    if (!data?.talent)
      return [];
    return data.talent.interview?.answers || [];
  }, [data]);

  const handleShowPosition = index => () => {
    console.log("Show position", index);
    const selectedPosition = data.talent.desiredPositions[index];
    feedback.alert({
      title: selectedPosition.position.name,
      component: <TalentPositionDetails position={selectedPosition} />,
      scroll: "body", maxWidth: "lg", fullWidth: true, clickAwayClose: true, confirmButtonText: "Close"
    });
  }

  useEffect(() => {
    loaderVar(loading ? "query" : null);
  }, [loading]);

  if (error)
    return <div>{error.message}</div>

  if (!data)
    return null;

  const { talent } = data;
  return (
    <Box sx={{ mt: 4, mb: 4 }}>
      <Box sx={{ p: 2, pb: 1 }}>
        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <Typography variant="h4" gutterBottom>{talent.user.firstName} {talent.user.lastName}</Typography>
            <TalentProfile talent={talent} />
          </Grid>
          <Grid item xs={12} md={6}>
            {answers.map(({ question, recording }) => (
              <React.Fragment key={recording._id}>
                {recording.state === RECORDING_STATES.READY ?
                  <VideoJS options={getVideoJsOptions(recording._id)} /> :
                  <Typography variant="h6">Recording state: {recording.state}</Typography>
                }
                <Typography variant='h6' sx={{ whiteSpace: 'pre-line', mt: 1, textAlign: "center" }}>Video introduction</Typography>
              </React.Fragment>
            ))}
          </Grid>
        </Grid>
      </Box>
      <List component="nav" subheader={
        <ListSubheader component="div" id="nested-list-subheader">
          Desired positions
        </ListSubheader>
      } >
        {talent.desiredPositions.map((position, index) => (
          <ListItemButton key={position._id} onClick={handleShowPosition(index)}>
            <ListItemText primary={position.position.name} />
          </ListItemButton>
        ))}
      </List>
    </Box>
  );
}

export default TalentPage;