import React, { useEffect } from 'react';
import { useQuery, gql } from '@apollo/client';
import { CORE_USER_FIELDS } from '../gql/fragments/user.fragment';
import { CORE_TALENT_FIELDS } from '../gql/fragments/talent.fragment';
import { CORE_CLIENT_FIELDS } from '../gql/fragments/client.fragment';
import { CORE_JOB_FIELDS } from '../gql/fragments/job.fragment';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import TalentDetails from './talents/TalentDetails';
import ClientProfile from './clients/ClientProfile';
import { CORE_DESIRED_POSITION_FIELDS } from '../gql/fragments/desiredPosition.fragment';
import { Navigate } from 'react-router-dom';
import { Button, Grid } from '@mui/material';
import { OpenInNew } from '@mui/icons-material';

const ME = gql`
  ${CORE_USER_FIELDS}
  ${CORE_TALENT_FIELDS}
  ${CORE_CLIENT_FIELDS}
  ${CORE_JOB_FIELDS}
  ${CORE_DESIRED_POSITION_FIELDS}
  query GetAccount {
    me {
      ...CoreUserFields
      client {
        ...CoreClientFields
        jobs {
          ...CoreJobFields
          matches {
            _id
            desiredPosition {
              _id
              ...CoreDesiredPositionFields
            }
            talent {
              ...CoreTalentFields
              _id
              interview {
                answers {
                  recording {
                    _id
                    state
                  }
                  question {
                    _id
                    title
                  }
                }
              }
              user {
                _id
                firstName
              }
            }
            acceptedByClient
            acceptedByTalent
            interviewScheduled
            createdAt
          }
        }
      }
      talent {
        ...CoreTalentFields
        stripeConnected
        desiredPositions {
          ...CoreDesiredPositionFields
          matches {
            _id
            createdAt
            acceptedByTalent
            acceptedByClient
            job {
              ...CoreJobFields
              position {
                _id
                name
              }
              client {
                _id
                company
                website
              }
            }
          }
        }
        interview {
          answers {
            recording {
              _id
              state
            }
            question {
              _id
              title
              canRerecord
            }
          }
        }
      }
    }
  }
`;

const Account = () => {
  const { data: userData, error } = useQuery(ME);

  // useEffect(() => {
  //   if (!userData) return;
  //   const { me: user } = userData;
  //   console.log("Intercom booting", user);
  //   window.Intercom("boot", {
  //     api_base: "https://api-iam.intercom.io",
  //     app_id: "b2ktw3p9",
  //     name: user.firstName + " " + user.lastName, // Full name
  //     email: user.email, // the email for your user
  //     created_at: user.createdAt, // Signup date as a Unix timestamp
  //   });
  //   window.Intercom("update");
  //   return () => {
  //     console.log("Intercom shutting down");
  //     window.Intercom("shutdown");
  //   }
  // }, [userData]);

  useEffect(() => {
    if (error) {
      console.warn(error);
    }
  }, [error]);

  if (error?.message === "Unauthenticated")
    return <Navigate to="/sign-in" />;

  if (error)
    return <Typography variant="h5" sx={{ mt: 2 }}>Error loading user data</Typography>;

  if (!userData) return null;
  const { me: user } = userData;

  if (!user.client && !user.talent)
    return <Navigate to="/" />;

  return (
    <Box sx={{ mt: 4, mb: 4 }}>
      <Box sx={{ m: 1, mb: 3 }}>
        <Grid container spacing={0}>
          <Grid item xs={12} md={9}>
            <Typography variant="h2">{user.firstName} {user.lastName}</Typography>
            <Typography variant="h5">{user.email}</Typography>
          </Grid>
          <Grid item xs={12} md={3} alignContent="center" justifyContent="end">
            {user.talent &&
              <Button variant="contained" color="primary" href={`/talent-profile/${user.talent._id}`} target="_blank" rel="noopener noreferrer" endIcon={<OpenInNew />} sx={{ float: "right" }}>
                View profile
              </Button>}
          </Grid>
        </Grid>
      </Box>
      {user.talent && <TalentDetails talent={user.talent} />}
      {user.client && <ClientProfile client={user.client} showJobs showEdit />}
    </Box >
  );
}

export default Account;