import React from 'react';

import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { useQuery, gql } from '@apollo/client';
import { useParams } from 'react-router';
import { CORE_JOB_FIELDS } from '../../gql/fragments/job.fragment';
import JobForm from './JobForm';

const ME = gql`
  ${CORE_JOB_FIELDS}
  query GetMe {
    me {
      _id
      client {
        _id
        jobs {
          ...CoreJobFields
        }
      }
    }
  }
`;

const EditJob = () => {
  const { error, loading, data } = useQuery(ME);
  const { id } = useParams();
  if (loading)
    return 'Loading...';
  if (error)
    return 'Error ' + error;

  const job = data?.me?.client?.jobs?.find(x => x._id === id);
  if (!job && id !== 'new')
    return 'Not found';
  return (
    <Container maxWidth="md">
      <Box
        sx={{
          marginTop: 4,
          marginBottom: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        {/* <ApplicationForm userId={data?.me?._id} data={position} /> */}
        <JobForm clientId={data?.me?.client?._id} data={job} redirect={"/account"} />
      </Box>
    </Container>
  );
}

export default EditJob;