import { gql } from '@apollo/client';

export const CORE_TALENT_FIELDS = gql`
  fragment CoreTalentFields on Talent {
    _id
    totalExperience
    products
    dealSize { from to }
    contractCycle
    businesses
    skills
    engagement
    startTime
    endTime
    level
    achievements
    linkedin
    facebook
    twitter
    instagram
    remote
    education {
      school
      degree
      endYear
    }
    workExperience {
      company
      position
      description
      startMonth
      startYear
      endMonth
      endYear
      isCurrent
    }
    approved
    createdAt
    updatedAt
  }
`;