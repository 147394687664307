import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useFeedback } from '../feedback/Service';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
// import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
// import { makeStyles } from '@mui/styles';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import SnackbarMessage from '../feedback/FeedbackSnackbar';

// const useStyles = makeStyles(theme => ({
//   level: {
//     padding: 0,
//     position: 'relative',
//     // background: theme.palette.grey[theme.palette.type === "dark" ? 600 : 300],
//     height: 10,
//     "& span": {
//       height: '100%',
//       position: 'relative',
//       display: 'block',
//       // background: theme.palette.type === "dark" ? theme.palette.grey[300] : theme.palette.primary.main,
//     }
//   },
//   label: {
//     paddingLeft: 5,
//     // color: theme.palette.text.primary,
//   },
//   showSettings: {
//     flexGrow: 1,
//     whiteSpace: 'nowrap',
//     // marginLeft: theme.spacing(2),
//     marginLeft: 4,
//     "& > a": {
//       cursor: 'pointer',
//     }
//   }
// }));

const StreamingMic = ({ recorder, onMicConnected = () => { }, showLabel = false, micConnected, showConnectModes = false, showOutputs = false, isMuted }) => {
  const feedback = useFeedback();
  // const classes = useStyles();
  const [level, setLevel] = useState(0);
  const [label, setLabel] = useState("");
  const [connected, setConnected] = useState(false);
  const [showDialog, setShowDialog] = useState(true);
  const [isConnecting, setIsConnecting] = useState(false);
  const [cameras, setCameras] = useState([]);
  const [mics, setMics] = useState([]);
  const [outputs, setOutputs] = useState([]);
  const [selectedCamera, setSelectedCamera] = useState("");
  const [selectedMic, setSelectedMic] = useState("");
  const [selectedOutput, setSelectedOutput] = useState("");
  const [inited, setInited] = useState(false);
  const [settings, setSettings] = useState(null);
  const [showSettings, setShowSettings] = useState(false);
  const [levelWarning, setLevelWarning] = useState(false);
  const [trackMuted, setTrackMuted] = useState(false);
  const timeout = useRef(null);

  const handleMicVolumeChange = useCallback((volume) => setLevel(Math.round(volume)), [setLevel]);

  const handleAllow = async (audioOnly = false) => {
    setIsConnecting(true);
    recorder.disableVideoOnStart = recorder.disableVideoOnStart || audioOnly;
    if (!connected) {
      try {
        if (!micConnected)
          await recorder.configureUserInput(selectedMic, selectedCamera, settings);
        setConnected(true);
        setTrackMuted(recorder.activeTrack.muted);
        recorder.activeTrack.onmute = () => setTrackMuted(true);
        recorder.activeTrack.onunmute = () => setTrackMuted(false);
        setLabel(recorder.getMicLabel());
        setShowDialog(false);
        onMicConnected(audioOnly, showOutputs ? selectedOutput : null);
      }
      catch (ex) {
        console.warn("[MIC]", "Error connection microphone", ex);
        feedback.snackbar({ text: ex?.message, type: "error" });
      }
    }
    recorder.on("micvolume", handleMicVolumeChange);
    setIsConnecting(false);
  }

  useEffect(() => () => recorder.off("micvolume", handleMicVolumeChange), [recorder, handleMicVolumeChange]);
  useEffect(() => {
    if (micConnected)
      handleAllow();
    // eslint-disable-next-line
  }, [micConnected]);

  useEffect(() => {
    // console.log("level", micConnected, level);
    if (connected) {
      if ((level === 0 || trackMuted) && !isMuted && recorder.stream.getAudioTracks()[0].active)
        timeout.current = setTimeout(() => setLevelWarning(trackMuted ? "Microphone is muted by the operating system" : (level === 0 ? "Low voice level" : "")), 1000);
      else {
        clearTimeout(timeout.current);
        setLevelWarning(false);
      }
    }
    return () => {
      clearTimeout(timeout.current);
    }
  }, [connected, level, isMuted, trackMuted, recorder]);

  useEffect(() => {
    const getDevices = async () => {
      if (!navigator.mediaDevices || !navigator.mediaDevices.enumerateDevices) {
        console.warn("[MIC]", "enumerateDevices() not supported.");
        return;
      }
      let devices = await navigator.mediaDevices.enumerateDevices();
      try {
        const stream = await navigator.mediaDevices.getUserMedia({ video: (recorder.withVideo && devices.some(x => x.kind === "videoinput")) ? { width: { ideal: 1280 }, height: { ideal: 720 } } : false, audio: true });
        const settings = stream.getAudioTracks()[0]?.getSettings();
        stream.getTracks().forEach(x => x.stop());
        console.log("[MIC]", "got audio settings", settings);
        setSettings(settings);
      }
      catch (err) {
        console.warn(err.name);
        return feedback.alert({ title: err.message || "Can't get media devices", text: err.name === "NotReadableError" ? "Check if other apps are using camera or microphone" : "" });
      }
      devices = await navigator.mediaDevices.enumerateDevices();
      const cameras = devices.filter(x => x.kind === "videoinput");
      console.log("[MIC]", 'cameras:', cameras);
      const mics = devices.filter(x => x.kind === "audioinput");
      console.log("[MIC]", 'mics:', mics);
      const outputs = devices.filter(x => x.kind === "audiooutput");
      console.log("[MIC]", 'audio-outputs:', outputs);
      setCameras(cameras);
      setMics(mics);
      setOutputs(outputs);
      if (mics.length > 0)
        setSelectedMic(mics[0]?.deviceId);
      if (cameras.length > 0)
        setSelectedCamera(cameras[0]?.deviceId);
      if (showOutputs && outputs.length > 0)
        setSelectedOutput(outputs[0]?.deviceId);
      setInited(true);
    }
    getDevices();
  }, [recorder, showOutputs]);

  const hasMic = mics.length > 0 || !inited;
  return (
    <>
      <Box sx={{
        padding: 0,
        position: 'relative',
        background: theme => theme.palette.grey[theme.palette.type === "dark" ? 600 : 300],
        height: theme => theme.spacing(1),
        width: '100%',
        "& span": {
          height: '100%',
          position: 'relative',
          display: 'block',
          background: theme => theme.palette.type === "dark" ? theme.palette.grey[300] : theme.palette.primary.main,
        }
      }}
      >
        <span style={{ width: level + '%' }}></span>
      </Box>
      <SnackbarMessage
        open={!!levelWarning}
        handleClose={() => setLevelWarning(false)}
        text={levelWarning}
        autoHide={false}
        type="warning"
      />
      {showLabel &&
        <Typography sx={{ color: (theme) => theme.palette.text.primary, pl: 2 }}>{connected ? (label || "Unknown mic") : "Microphone is disconnected"}</Typography>
      }
      <Dialog
        open={showDialog && !micConnected}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Camera and microphone access request"}</DialogTitle>
        <DialogContent>
          {hasMic ?
            <>
              <DialogContentText id="alert-dialog-description">
                Please allow access to your camera and microphone in order to perform a video interview. <br />
                {/* Problems connecting microphone? Support: <Link href="mailto:info@slidespiel.com" color="initial">info@slidespiel.com</Link> */}
                <br />
              </DialogContentText>
              {mics.length > 1 &&
                <FormControl fullWidth required>
                  <InputLabel id="select-mic">Microphone</InputLabel>
                  <Select
                    labelId="select-mic"
                    value={selectedMic}
                    onChange={({ target }) => setSelectedMic(target.value)}
                    name="mic"
                    label="Microphone"
                  >
                    {mics.map(mic => (<MenuItem value={mic.deviceId} key={mic.deviceId}>{mic.label}</MenuItem>))}
                  </Select>
                  <br />
                </FormControl>
              }
              {(recorder.withVideo && cameras.length > 1) &&
                <FormControl fullWidth required>
                  <InputLabel id="select-camera">Camera</InputLabel>
                  <Select
                    labelId="select-camera"
                    value={selectedCamera}
                    onChange={({ target }) => setSelectedCamera(target.value)}
                    name="camera"
                    label="Camera"
                  >
                    {cameras.map(camera => (<MenuItem value={camera.deviceId} key={camera.deviceId}>{camera.label}</MenuItem>))}
                  </Select>
                  {(showOutputs && outputs.length > 1) && <br />}
                </FormControl>
              }
              {(showOutputs && outputs.length > 1) &&
                <FormControl fullWidth required>
                  <InputLabel id="select-camera">Output</InputLabel>
                  <Select
                    labelId="select-camera"
                    value={selectedOutput}
                    onChange={({ target }) => setSelectedOutput(target.value)}
                    name="output"
                  >
                    {outputs.map(output => (<MenuItem value={output.deviceId} key={output.deviceId}>{output.label}</MenuItem>))}
                  </Select>
                </FormControl>
              }
              {(settings && showSettings) &&
                <>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={settings.autoGainControl}
                        onChange={({ target }) => setSettings(x => ({ ...x, autoGainControl: target.checked }))}
                        color="primary"
                      />
                    }
                    label="Auto Gain Control"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={settings.echoCancellation}
                        onChange={({ target }) => setSettings(x => ({ ...x, echoCancellation: target.checked }))}
                        color="primary"
                      />
                    }
                    label="Echo Cancellation"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={settings.noiseSuppression}
                        onChange={({ target }) => setSettings(x => ({ ...x, noiseSuppression: target.checked }))}
                        color="primary"
                      />
                    }
                    label="Noise Suppression"
                  />
                </>
              }
            </> :
            <DialogContentText>Error: No microphones found.</DialogContentText>
          }
        </DialogContent>
        <DialogActions>
          {settings &&
            <Box sx={{
              flexGrow: 1,
              whiteSpace: 'nowrap',
              // marginLeft: theme.spacing(2),
              marginLeft: 1,
              "& > a": {
                cursor: 'pointer',
              }
            }}>
              <Typography component={Button} variant="caption" onClick={() => setShowSettings(x => !x)} color="primary">{showSettings ? "Hide" : "Show"} advanced settings</Typography>
            </Box>
          }
          {showConnectModes ?
            <>
              <Button onClick={() => handleAllow()} autoFocus disabled={isConnecting || !hasMic || !inited} >
                Join with Video
              </Button>
              <Button onClick={() => handleAllow(true)} autoFocus disabled={isConnecting || !hasMic || !inited} >
                Join with Audio Only
              </Button>
            </> :
            <Button onClick={() => handleAllow()} autoFocus disabled={isConnecting || !hasMic || !inited} >
              Connect
            </Button>
          }
        </DialogActions>
      </Dialog>
    </>
  );
}

export default StreamingMic;