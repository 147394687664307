import { gql } from '@apollo/client';

const COMMISSION_TYPES = gql`
  query GetCommissionTypes {
    commissionTypes {
      _id
      name
      hasQuantity
    }
  }
`;

export default COMMISSION_TYPES;