import React from 'react';
import MultipleSelectChip from './MultipleSelectChip';

const data = [
  { name: "Full-time employment" },
  { name: "Part-time employment" },
  { name: "Contract work" },
];

const EngagementSelect = ({ ...props }) => {
  return (<MultipleSelectChip items={data} {...props} valueField="name" />);
}

export default EngagementSelect;