import React from 'react';

import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { useQuery, gql } from '@apollo/client';
import { CORE_DESIRED_POSITION_FIELDS } from '../../gql/fragments/desiredPosition.fragment';
import { useParams } from 'react-router';
import TalentPositionForm from './TalentPositionForm';

const ME = gql`
  ${CORE_DESIRED_POSITION_FIELDS}
  query GetMe {
    me {
      _id
      talent {
        _id
        desiredPositions {
          ...CoreDesiredPositionFields
        }
      }
    }
  }
`;

const EditTalentPosition = () => {
  const { error, loading, data } = useQuery(ME);
  const { id } = useParams();
  if (loading)
    return 'Loading...';
  if (error)
    return 'Error ' + error;

  const position = data?.me?.talent?.desiredPositions?.find(x => x._id === id);
  if (!position && id !== 'new')
    return 'Not found';
  return (
    <Container maxWidth="md">
      <Box
        sx={{
          marginTop: 4,
          marginBottom: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        {/* <ApplicationForm userId={data?.me?._id} data={position} /> */}
        <TalentPositionForm talentId={data?.me?.talent?._id} data={position} redirect={id === "new" ? "/account#position-added" : "/account"} />
      </Box>
    </Container>
  );
}

export default EditTalentPosition;