import { gql } from '@apollo/client';

export const CORE_DESIRED_POSITION_FIELDS = gql`
  fragment CoreDesiredPositionFields on DesiredPosition {
    _id
    position {
      _id
      name
      questions {
        _id
      }
    }
    interview {
      answers {
        recording {
          _id
          state
        }
        question {
          _id
          text
          title
          canRerecord
        }
      }
    }
    experience
    baseCompensation
    ote
    tools
    preferences
  }
`;