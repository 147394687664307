import React from 'react';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import * as Sentry from '@sentry/react';

const ErrorBoundary = ({ children }) => {
  return (
    <Sentry.ErrorBoundary
      fallback={
        <Container>
          <br />
          <br />
          <Typography variant="h4">Oops! something went wrong :(</Typography>
          <br />
        </Container>}
      showDialog>
      {children || (<></>)}
    </Sentry.ErrorBoundary>
  );
}

export default ErrorBoundary;