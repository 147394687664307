import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import { ROLES } from '../../../constants';
import DataGrid from '../../DataGrid';
import { useMutation, gql } from '@apollo/client';
import { CORE_USER_FIELDS } from '../../../gql/fragments/user.fragment';
import { useFeedback } from '../../feedback/Service';
import { formatTimestamp, parseGraphQLError } from '../../../utils';

const UPDATE_USER = gql`
  ${CORE_USER_FIELDS}
  mutation UpdateUser($user: UserInput!) {
    updateUser(user: $user) {
      ...CoreUserFields
      talent {
        _id
      } 
    }
  }
`;

const cols = [
  { accessorKey: '_id', header: 'ID', size: 220, hide: true, enableClickToCopy: true },
  { accessorKey: 'email', header: 'Email', size: 250, enableEditing: true, },
  { accessorKey: 'firstName', header: 'First Name', size: 150, enableEditing: true },
  { accessorKey: 'lastName', header: 'Last Name', size: 150, enableEditing: true },
  { accessorKey: 'leadType', header: 'Lead Type', size: 150, enableEditing: false },
  { accessorKey: 'role', header: 'Role', size: 120, enableEditing: true, editVariant: 'select', editSelectOptions: ROLES, },
  { accessorFn: ({ createdAt }) => formatTimestamp(createdAt), header: 'Created', size: 150 },
  // { accessorKey: 'isClient', header: 'Is Client', size: 150 },
  // { accessorFn: ({ talent }) => talent ? "true" : "false", header: 'Is Talent', size: 150 },
];

const UsersGrid = ({ data: initialData }) => {
  const [data, setData] = useState([]);
  const [updateUser, { loading: isPosting }] = useMutation(UPDATE_USER);
  const feedback = useFeedback();

  const handleSaveChanges = async row => {
    try {
      const { _id, email, firstName, lastName, role } = row;
      const res = await updateUser(({ variables: { user: { _id, email, firstName, lastName, role } } }));
      console.log("user updated", res);
      feedback.snackbar({ text: "User updated", type: "success" });
    } catch (error) {
      console.error("Update user error", error);
      feedback.snackbar({ text: parseGraphQLError(error), type: "error" });
    }
  }

  useEffect(() => {
    if (initialData?.users)
      setData(initialData?.users);
  }, [initialData]);

  return (
    <Box sx={{ height: 'calc(100vh - 128px)', width: '100%' }}>
      <DataGrid
        data={data}
        columns={cols}
        onSave={handleSaveChanges}
        isPosting={isPosting}
        enableEditing
      />
    </Box>
  );
}

export default UsersGrid;