import React, { useReducer, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import PositionSelect from '../inputs/PositionSelect';
import LoadingButton from '@mui/lab/LoadingButton';
import { useMutation, gql } from '@apollo/client';
import { useFeedback } from '../feedback/Service';
// import ProductSelect from '../inputs/ProductSelect';
// import BusinessesSelect from '../inputs/BusinessesSelect';
// import IndustriesSelect from '../inputs/IndustriesSelect';
// import RangeInput from '../inputs/RangeInput';
import { useNavigate } from 'react-router';
import config from '../../config';
import { parseGraphQLError } from '../../utils';

const POST_DESIRED_POSITION = gql`
  mutation PostDesiredPosition($position: DesiredPositionInput!) {
    postDesiredPosition(position: $position) {
      _id
      position {
        _id
        name
      }
    }
  }
`;

let initialState = {
  position: "",
  experience: "",
  baseCompensation: "",
  ote: "",
  // totalIncome: { from: "", to: "" },
  tools: "",
  preferences: "",
}
if (config.useTestFormData) {
  initialState = {
    ...initialState,
    position: "",
    experience: "10",
    baseCompensation: "5000",
    ote: "10000",
    // totalIncome: { from: "10000", to: "90000" },
    tools: "",
  }
}

const numericFields = ["experience", "baseCompensation", "ote"];

const reducer = (state, newState) => ({
  ...state,
  ...newState
})


const TalentPositionForm = ({ talentId, data: initialData, redirect }) => {
  const [state, setState] = useReducer(reducer, initialState);
  const feedback = useFeedback();
  const navigate = useNavigate();
  const [submit, { loading }] = useMutation(POST_DESIRED_POSITION, {
    update(cache, { data: { postDesiredPosition } }) {
      cache.modify({
        id: cache.identify({ _id: talentId, __typename: "Talent" }),
        fields: {
          desiredPositions: () => postDesiredPosition,
        }
      });
    }
  });

  const handleChange = ({ target: { name, value, checked, type } }) => {
    setState({ [name]: type === "checkbox" ? checked : value /* (type === "number" ? parseFloat(value) : value) */ });
  }

  const handleSubmit = async e => {
    e.preventDefault();
    try {
      const data = { ...state };
      for (let field of numericFields) {
        if (typeof data[field] === "object") {
          data[field] = { from: parseFloat(data[field].from), to: parseFloat(data[field].to) };
          if (data[field].from > data[field].to)
            return;
        } else
          data[field] = parseFloat(data[field]);
      }
      console.log(data);
      // if (data) return;
      await submit(({ variables: { position: data } }));
      if (!initialData?._id) {
        window.gtag('event', 'talent_position', {});
      }
      feedback.snackbar({ text: "Form submitted!", type: "success" });
      if (redirect)
        navigate(redirect);
    } catch (error) {
      console.error("TalentPositionForm error", error);
      feedback.snackbar({ text: parseGraphQLError(error), type: "error" });
    }
    // console.log(talent);
  }

  const setDefaultPosition = id => {
    if (state.position === "")
      setState({ position: id });
  }

  useEffect(() => {
    if (!initialData)
      return;
    // const dealSize = dealSizeData.find(({ value }) => value.from === initialData.dealSize.from && value.to === initialData.dealSize.to).value;
    const position = initialData.position._id;
    setState({ ...initialData, position, __typename: undefined, interview: undefined });
  }, [initialData]);

  return (
    <Box component="form" onSubmit={handleSubmit} sx={{ width: '100%' }}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <PositionSelect fullWidth required value={state.position} onChange={handleChange} name="position" label="Position" setDefaultPosition={setDefaultPosition} />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField fullWidth required value={state.experience} type="number" onChange={handleChange} name="experience" label="Proven working experience on position, years" />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField fullWidth required value={state.baseCompensation} type="number" onChange={handleChange} name="baseCompensation" label="Minimum base salary, monthly" InputProps={{ startAdornment: <InputAdornment position="start">$</InputAdornment> }} />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField fullWidth required value={state.ote} type="number" onChange={handleChange} name="ote" label="Desired on-target earnings, monthly" InputProps={{ startAdornment: <InputAdornment position="start">$</InputAdornment> }} />
        </Grid>
        {/* <Grid item xs={12} md={12}>
          <RangeInput fullWidth required value={state.totalIncome} type="number" onChange={handleChange} name="totalIncome" label="Desired total yearly gross income" startAdornment={<InputAdornment position="start">$</InputAdornment>} />
        </Grid> */}
        <Grid item xs={12}>
          <TextField
            fullWidth
            multiline
            rows={5}
            required
            value={state.tools}
            onChange={handleChange}
            name="tools"
            label="Sales instruments and business tools"
            // sx={{ "& .MuiInputLabel-root": { whiteSpace: "normal !important" } }}
            helperText="Sales instruments and business tools you need to perform your daily work-related tasks (for cold calling, prospecting, lead generation, CRM, etc.)"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField fullWidth multiline rows={5} value={state.preferences} onChange={handleChange} name="preferences" label="Other preferences (industry, type of company, etc)" />
        </Grid>
      </Grid>
      <LoadingButton type="submit" size="large" variant="contained" sx={{ mt: 2, mb: 2 }} loading={loading}>
        Submit
      </LoadingButton>
    </Box >
  );
}

export default TalentPositionForm;