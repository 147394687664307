import React, { useState, useEffect, /* useRef */ } from 'react';
// import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';

// const resetValidity = el => {
//   if (!el.current) return;
//   el.current.setCustomValidity("");
// }

const RangeInput = ({ onChange, value, name, helperText, label, ...rest }) => {
  const [error, setError] = useState(null);
  // const fromRef = useRef(null);
  // const toRef = useRef(null);

  const handleChange = ({ target }) => onChange({ target: { name, value: { from: target.name === "from" ? target.value : value.from, to: target.name === "to" ? target.value : value.to, } } });

  useEffect(() => {
    if (!value.to || !value.from)
      setError(null);
    if (parseFloat(value.from) > parseFloat(value.to)) {
      setError("To value must be more or equal than from value");
    }
    else
      setError(null);
  }, [value]);

  // useEffect(() => {
  //   resetValidity(fromRef);
  // }, [value.trom]);
  
  // useEffect(() => {
  //   resetValidity(toRef);
  // }, [value.to]);

  return (
    <div>
      <InputLabel htmlFor={name}>{label} {rest.required ? "*" : ""}</InputLabel>
      <Grid container spacing={1}>
        <Grid item xs={6}>
          <OutlinedInput
            id={name}
            value={value.from}
            onChange={handleChange}
            name="from"
            placeholder={"From" + (rest.required ? " *" : "")}
            error={!!error}
            required
            // inputRef={fromRef}
            {...rest}
          />
        </Grid>
        <Grid item xs={6}>
          <OutlinedInput
            // id="component-helper1"
            value={value.to}
            onChange={handleChange}
            name="to"
            placeholder={"To" + (rest.required ? " *" : "")}
            error={!!error}
            // inputRef={toRef}
            {...rest}
          />
        </Grid>
      </Grid>
      {error &&
        <FormHelperText error>
          {error}
        </FormHelperText>
      }
      {(helperText && !error) &&
        <FormHelperText>
          {helperText}
        </FormHelperText>
      }
    </div>
  );
}

export default RangeInput;