import React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Grid from '@mui/material/Grid';
import { Box, Divider, IconButton, TextField, Button } from '@mui/material';
import { Add } from '@mui/icons-material';

const defaultItem = {
  school: '',
  degree: '',
  endYear: null,
}

const numericFields = ['endYear'];

const EducationItem = ({ item, onItemChange, index }) => {
  const handleChange = ({ target: { value, type, checked, name } }) => {
    console.log(name, value, index);
    if (numericFields.includes(name))
      value = value ? parseInt(value) : null;
    onItemChange(index)({ ...item, [name]: type === "checkbox" ? checked : value });
  }
  return (
    <Accordion defaultExpanded={!item.school}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
      // aria-controls="panel1a-content"
      // id="panel1a-header"
      >
        <Typography>{item.degree} at {item.school} {item.endYear}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              id="school"
              name="school"
              label="University/Bootcamp"
              fullWidth
              value={item.school}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              id="degree"
              name="degree"
              label="Degree and Major/Specialization"
              fullWidth
              value={item.degree}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              type='number'
              id="endYear"
              name="endYear"
              label="Graduation Year"
              fullWidth
              value={item.endYear ?? ''}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <Button variant="contained" color="error" onClick={() => onItemChange(index)(null)}>Remove</Button>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion >
  );
}

const Education = ({ items, onChange }) => {
  console.log(items);

  const handleItemChange = index => item => {
    console.log(index, item);
    const newItems = [...items];
    newItems[index] = item;
    onChange(newItems.filter(Boolean));
  }

  return (
    <div>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <Typography variant="h6" gutterBottom>Education</Typography>
        <IconButton aria-label="add" size="small" onClick={() => onChange([...items, { ...defaultItem }])}>
          <Add />
        </IconButton>
      </Box>
      <br />
      {items.map((item, index) => <EducationItem key={index} item={item} onItemChange={handleItemChange} index={index} />)}
      {items.length === 0 && <Typography variant="body2" gutterBottom>No information added yet</Typography>}
      <br />
      <Divider />
      <br />
    </div>

  );
}

export default Education;