import React from 'react';
import MultipleSelectChip from './MultipleSelectChip';

const data = [
  { name: "Accounting" },
  { name: "AdTech" },
  { name: "Benefits Software" },
  { name: "CRM Software" },
  { name: "Computer Software" },
  { name: "Consumer Goods" },
  { name: "Consumer Services" },
  { name: "Consumer Software" },
  { name: "Data Products" },
  { name: "Digital Storage" },
  { name: "E-Commerce" },
  { name: "Events" },
  { name: "Financial Services" },
  { name: "Food/Beverage" },
  { name: "HR Tech" },
  { name: "Insurance" },
  { name: "Marketing Automation" },
  { name: "Media" },
  { name: "Medical Devices" },
  { name: "On-Premise Software" },
  { name: "Pharmaceuticals" },
  { name: "Platform-as-a-Service (PaaS)" },
  { name: "Professional Services" },
  { name: "Project Management" },
  { name: "Real Estate" },
  { name: "Retail Sales" },
  { name: "Security Software" },
  { name: "Software-as-a-Service (SaaS)" },
];

const ProductSelect = ({ ...props }) => {
  return (<MultipleSelectChip items={data} {...props} valueField="name" otherOption />);
}

export default ProductSelect;