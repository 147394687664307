import React from 'react';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import Typography from '@mui/material/Typography';
import CommissionTypeSelect from '../../inputs/CommissionTypeSelect';
import CommissionUnitSelect from '../../inputs/CommissionUnitSelect';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import COMMISSION_TYPES from '../../../gql/common/commissionTypes.query';
import { useQuery } from '@apollo/client';
import { Divider } from '@mui/material';

const PositionCommission = ({ data: commission, onChange, onDelete: handleDeleteCommission }) => {
  const { data: commissionTypes } = useQuery(COMMISSION_TYPES);
  const valueText = commission.unit === "DOLLAR" ? `$${commission.minValue}` : `${commission.minValue}%`;
  const commissionTypeText = commissionTypes?.commissionTypes.find(x => x._id === commission.commissionType)?.name || "";
  return (
    <Accordion defaultExpanded={!commission.commissionType}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
      // aria-controls="panel1a-content"
      // id="panel1a-header"
      >
        <Typography>{commissionTypeText ? commissionTypeText + ":" : ""} {valueText}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={1}>
          <Grid item xs={6} md={2}>
            <CommissionUnitSelect
              required
              onChange={onChange}
              value={commission.unit}
              name="unit" margin="dense"
              label="Unit" fullWidth />
          </Grid>
          <Grid item xs={6} md={5}>
            <CommissionTypeSelect
              required
              onChange={onChange}
              value={commission.commissionType}
              name="commissionType" margin="dense"
              label="Type" fullWidth />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              margin="dense"
              label="Min value"
              // type="number"
              fullWidth
              value={commission.minValue}
              onChange={onChange}
              name="minValue"
              sx={{ m: 0 }}
              required
            />
          </Grid>
          <Grid item xs={12} md={1}>
            <IconButton onClick={handleDeleteCommission} color="error"><RemoveIcon /></IconButton>
          </Grid>
          <Grid item xs={12} md={12}>
            <TextField
              margin="dense"
              label="Helper Text"
              fullWidth
              value={commission.helperText}
              onChange={onChange}
              name="helperText"
            />
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
}

const PositionCommissions = ({ data: commissions, onAdd: handleAddCommission, onChange: handleCommissionChange, onDelete: handleDeleteCommission }) => {
  return (
    <div>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <Typography variant='h6' gutterBottom>Available Commissions</Typography>
        <IconButton size='small' onClick={handleAddCommission}><AddIcon /></IconButton>
      </Box>
      <br />
      {commissions.map((commission, i) => <PositionCommission key={i} data={commission} onChange={handleCommissionChange(i)} onDelete={handleDeleteCommission(i)} />)}
      {commissions.length === 0 && <Typography variant="body2" gutterBottom>No commissions added yet</Typography>}
      <br />
      <Divider />
      <br />
    </div>
  );
}

export default PositionCommissions;